@charset "UTF-8";
/* ============================================================ *\

  #MAIN

  Author:          Timotheus Triebl
  Website:         timotheus-triebl.at
  Scss Creator by: Andrew Brandwood www.Brandwood.com

/* ============================================================ */
/**
* CONTENTS
*
*/
@import url("//hello.myfonts.net/count/3ad33e");
@font-face {
  font-family: "Futura";
  src: url("../Fonts/3AD33E_0_0.eot?#iefix") format("embedded-opentype"), url("../Fonts/3AD33E_0_0.woff2") format("woff2"), url("../Fonts/3AD33E_0_0.woff") format("woff"), url("../Fonts/3AD33E_0_0.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Futura";
  src: url("../Fonts/3AD33E_1_0.eot");
  src: url("../Fonts/3AD33E_1_0.eot?#iefix") format("embedded-opentype"), url("../Fonts/3AD33E_1_0.woff2") format("woff2"), url("../Fonts/3AD33E_1_0.woff") format("woff"), url("../Fonts/3AD33E_1_0.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Kontora";
  font-weight: 800;
  src: url("../Fonts/Kontora_bold.woff") format("woff"); /* Pretty Modern Browsers */
}
@font-face {
  font-family: "Kontora";
  font-weight: 400;
  src: url("../Fonts/Kontora.woff") format("woff"); /* Pretty Modern Browsers */
}
@font-face {
  font-family: "Kontora";
  font-weight: 100;
  src: url("../Fonts/Kontora_thin.woff") format("woff"); /* Pretty Modern Browsers */
}
/*====================================
=            Kontora Bold            =
====================================*/
.h4 {
  font-family: "Kontora", sans-serif;
  font-size: 2.5em;
  line-height: 1.2em;
  font-weight: 800;
  letter-spacing: 0;
}

h4 {
  font-family: "Kontora", sans-serif;
  font-size: 2.5em;
  line-height: 1.2em;
  font-weight: 800;
  letter-spacing: 0;
  font-size: 25px;
}

.h3 {
  font-family: "Kontora", sans-serif;
  font-size: 4em;
  line-height: 1.125em;
  font-weight: 800;
  letter-spacing: 0;
}

h3 {
  font-family: "Kontora", sans-serif;
  font-size: 4em;
  line-height: 1.125em;
  font-weight: 800;
  letter-spacing: 0;
  font-size: 40px;
}

.h2 {
  font-family: "Kontora", sans-serif;
  font-size: 6em;
  line-height: 1.25em;
  font-weight: 800;
  letter-spacing: 0;
}
@media all and (max-width: 900px) {
  .h2 {
    font-size: 5em;
  }
}

h2 {
  font-family: "Kontora", sans-serif;
  font-size: 6em;
  line-height: 1.25em;
  font-weight: 800;
  letter-spacing: 0;
  font-size: 60px;
}
@media all and (max-width: 900px) {
  h2 {
    font-size: 5em;
  }
}
@media all and (max-width: 900px) {
  h2 {
    font-size: 50px;
  }
}

.h1 {
  font-family: "Kontora", sans-serif;
  font-size: 7em;
  line-height: 1.14em;
  font-weight: 800;
  letter-spacing: 0;
}
@media all and (max-width: 900px) {
  .h1 {
    font-size: 5em;
  }
}

h1 {
  font-family: "Kontora", sans-serif;
  font-size: 7em;
  line-height: 1.14em;
  font-weight: 800;
  letter-spacing: 0;
  font-size: 70px;
}
@media all and (max-width: 900px) {
  h1 {
    font-size: 5em;
  }
}
@media all and (max-width: 900px) {
  h1 {
    font-size: 50px;
  }
}

/*=======================================
=            Kontora Regular            =
=======================================*/
/*=====================================
=            Futura Medium            =
=====================================*/
/*====================================
=            futurua bold            =
====================================*/
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}
*:focus {
  outline: none;
}

h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
dl, dd, ol, ul,
form, fieldset, legend,
table, th, td, caption,
hr {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

abbr[title], dfn[title] {
  cursor: help;
}

u, ins {
  text-decoration: none;
}

ins {
  border-bottom: 1px solid;
}

img {
  font-style: italic;
}

label,
input,
textarea,
button,
select,
option {
  cursor: pointer;
}

.input--text:active,
.input--text:focus,
textarea:active,
textarea:focus {
  cursor: text;
  outline: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

html {
  font-family: "Arial, sans-serif";
  font-size: 10px;
  min-height: 100%;
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

body {
  margin: 0;
  font-size: 10px;
}

a {
  color: inherit;
  text-decoration: none;
}

a:focus {
  outline: none;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

hr {
  box-sizing: content-box;
  height: 0;
}

mark {
  background: #ff0;
  color: #000;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre-wrap;
}

q {
  quotes: "“" "”" "‘" "’";
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0; /* 1 */
  padding: 0; /* 2 */
}

button,
input,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 2 */
  margin: 0; /* 3 */
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

input[type=search] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: normal;
}

.image-wrapper {
  position: relative;
}
.image-wrapper .image {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  font-family: "object-fit: cover;";
}
.image-wrapper .image-contain {
  object-fit: contain;
  font-family: "object-fit: contain;";
}
.image-wrapper .image.image-relative, .image-wrapper .image.is-relative {
  position: relative;
  width: 100%;
  height: auto;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
}

button {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.site {
  height: auto;
  width: 100%;
  position: relative;
  margin-top: 9em;
  background-color: #ffffff;
  z-index: 1;
}
.site.has_animation {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
.site.has_animation.is-hidden {
  transform: translate3d(105%, 0, 0);
}
.site.is-inverted {
  color: #ffffff;
  background-color: #222222;
}
.single.single-tools .site, .single.single-special-offers .site, .single.single-services .site {
  margin-top: 0;
}
@media all and (max-width: 900px) {
  .site {
    padding-top: 0 !important;
    margin-top: 0;
  }
}

.section {
  display: block;
  width: 100%;
  position: relative;
}

.site .section:first-child {
  margin-top: 13em;
}
@media all and (max-width: 900px) {
  .site .section:first-child {
    margin-top: 0;
  }
}

.single.single-services .nav {
  pointer-events: none;
}
.single.single-services .nav .burger {
  pointer-events: none;
}

.single.single-tools .nav {
  pointer-events: none;
}
.single.single-tools .nav .burger {
  pointer-events: none;
}

.single.single-special-offers .nav {
  pointer-events: none;
}
.single.single-special-offers .nav .burger {
  pointer-events: none;
}

.page-id-547 .site .header-reveal .section:first-child,
.page-id-561 .site .header-reveal .section:first-child {
  margin-top: 15em;
}
@media all and (max-width: 900px) {
  .page-id-547 .site .header-reveal .section:first-child,
  .page-id-561 .site .header-reveal .section:first-child {
    margin-top: 1em;
  }
}

/*==============================
=            common            =
==============================*/
.width {
  margin: 0 auto;
}
.width-standard {
  max-width: 1920px;
  width: 90%;
}
.width-full {
  width: 100%;
  max-width: 1920px;
}
.width-full-padding {
  width: 90%;
  max-width: 1920px;
}

br.mobile {
  display: none;
}
@media all and (max-width: 900px) {
  br.mobile {
    display: block;
  }
}

ul {
  margin-top: 0.5em;
}
ul li {
  font-family: "Futura", sans-serif;
  font-size: 2.5em;
  line-height: 1.4em;
  font-weight: 500;
  letter-spacing: 0;
  padding-left: 1em;
  position: relative;
  font-size: 25px;
}
ul li:before {
  content: "";
  width: 6px;
  height: 6px;
  top: 0.6em;
  border-radius: 100%;
  background-color: #222222;
  position: absolute;
  left: 0;
}

p {
  margin-top: 1em;
}
p:first-of-type {
  margin-top: 0;
}

.header-reveal {
  z-index: -1;
  width: 100%;
}
@media all and (max-width: 900px) {
  .header-reveal {
    position: relative !important;
    padding-top: 8em;
  }
}

.content-reveal {
  width: 100%;
  padding-bottom: 6em;
  padding-top: 4em;
  margin-top: 0;
}
.content-reveal .section:first-child {
  margin-top: 0;
}
.single-services .content-reveal .section:first-child, .single-special-offers .content-reveal .section:first-child, .single-tools .content-reveal .section:first-child {
  margin-top: 0;
  padding-top: 0;
}
.page-id-599 .content-reveal .section:first-child, .page-id-3 .content-reveal .section:first-child {
  margin-top: inherit;
}
@media all and (max-width: 900px) {
  .content-reveal .section:first-child {
    padding-top: 0;
  }
}
.content-reveal .section.section-Marquee:first-child {
  padding-top: 0;
  margin-top: 15em;
}
@media all and (max-width: 900px) {
  .content-reveal {
    padding-top: 0;
    margin-top: 8em;
  }
  .single.single-tools .content-reveal, .single.single-special-offers .content-reveal, .single.single-services .content-reveal {
    margin-top: 0;
  }
}

.header-reveal + .content-reveal {
  padding-top: 8em;
  margin-top: 0;
}
@media all and (max-width: 900px) {
  .header-reveal + .content-reveal {
    padding-top: 4em;
    margin-top: 0;
  }
}
.header-reveal + .content-reveal.padding-top-none {
  padding-top: 0;
}
.header-reveal + .content-reveal.padding-top-small {
  padding-top: 3em;
}
.header-reveal + .content-reveal.padding-top-standard {
  padding-top: 6.5em;
}
@media all and (max-width: 900px) {
  .header-reveal + .content-reveal.padding-top-standard {
    padding-top: 6.5em;
  }
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-0 {
  padding-top: 0;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-5 {
  padding-top: 1em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-10 {
  padding-top: 2em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-15 {
  padding-top: 3em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-20 {
  padding-top: 4em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-25 {
  padding-top: 5em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-30 {
  padding-top: 6em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-35 {
  padding-top: 7em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-40 {
  padding-top: 8em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-45 {
  padding-top: 9em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-50 {
  padding-top: 10em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-55 {
  padding-top: 11em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-60 {
  padding-top: 12em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-65 {
  padding-top: 13em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-70 {
  padding-top: 14em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-75 {
  padding-top: 15em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-80 {
  padding-top: 16em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-85 {
  padding-top: 17em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-90 {
  padding-top: 18em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-95 {
  padding-top: 19em;
}
.header-reveal + .content-reveal.padding-top-own.padding-top-own-100 {
  padding-top: 20em;
}
@media all and (max-width: 900px) {
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-0 {
    padding-top: 0;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-5 {
    padding-top: 0.5em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-10 {
    padding-top: 1em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-15 {
    padding-top: 1.5em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-20 {
    padding-top: 2em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-25 {
    padding-top: 2.5em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-30 {
    padding-top: 3em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-35 {
    padding-top: 3.5em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-40 {
    padding-top: 4em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-45 {
    padding-top: 4.5em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-50 {
    padding-top: 5em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-55 {
    padding-top: 5.5em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-60 {
    padding-top: 6em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-65 {
    padding-top: 6.5em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-70 {
    padding-top: 7em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-75 {
    padding-top: 7.5em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-80 {
    padding-top: 8em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-85 {
    padding-top: 8.5em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-90 {
    padding-top: 9em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-95 {
    padding-top: 9.5em;
  }
  .header-reveal + .content-reveal.padding-top-own.padding-top-own-mob-100 {
    padding-top: 10em;
  }
}
.call-to-action {
  font-family: "Futura", sans-serif;
  font-size: 2em;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 0;
}
.burger {
  padding: 1em 0 1em 1em;
  pointer-events: all;
  cursor: pointer;
}
.burger .burger-inner {
  position: relative;
  width: 30px;
  height: 15px;
}
.burger .burger-inner:before, .burger .burger-inner:after {
  content: "";
  background-color: #222222;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  transition: all 0.25s ease-in-out;
}
.is-inverted .burger .burger-inner:before, .is-inverted .burger .burger-inner:after {
  background-color: #ffffff;
}
.burger .burger-inner:before {
  top: 0;
  transform-origin: 9px 0px;
}
.burger .burger-inner:after {
  bottom: 0;
  transform-origin: 3px 0px;
}
.burger.is-open .burger-inner:before {
  top: 0;
  transform: rotate(45deg);
}
.burger.is-open .burger-inner:after {
  bottom: 0;
  transform: rotate(-45deg);
}

/*=========================
=            x            =
=========================*/
.cross {
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.single .cross {
  width: 42px;
  height: 42px;
}
.single-tools .cross {
  width: 42px;
  height: 42px;
}
.cross.is-absolute {
  position: absolute;
}
.cross:before, .cross:after {
  content: "";
  background-color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  transition: all 0.25s ease-in-out;
}
.cross:before {
  width: 100%;
  height: 2px;
}
.cross:after {
  width: 2px;
  height: 100%;
}
.cross:hover:before, .cross:hover:after {
  transform: translate(-50%, -50%) rotate(225deg);
}
.cross.cross-black:before, .cross.cross-black:after {
  background-color: #222222;
}

/*==================================
=            margin top            =
==================================*/
.set-p {
  --link-color: #222222;
}
.set-p h1, .set-p h2, .set-p h3, .set-p h4, .set-p h5, .set-p p {
  margin-top: 1em;
}
.set-p h1:first-child, .set-p h2:first-child, .set-p h3:first-child, .set-p h4:first-child, .set-p h5:first-child, .set-p p:first-child {
  margin-top: 0;
}
@media all and (max-width: 900px) {
  .set-p h3:first-of-type {
    margin-top: 0.5em;
  }
}
.set-p a {
  text-decoration: underline;
  border-bottom: 0;
}
.set-p a:hover {
  text-decoration: none;
}
.set-p a:before {
  display: none;
}

.set-p-with-hover {
  --link-color: #222222;
}
.set-p-with-hover h1, .set-p-with-hover h2, .set-p-with-hover h3, .set-p-with-hover h4, .set-p-with-hover h5, .set-p-with-hover p {
  margin-top: 1em;
}
.set-p-with-hover h1:first-child, .set-p-with-hover h2:first-child, .set-p-with-hover h3:first-child, .set-p-with-hover h4:first-child, .set-p-with-hover h5:first-child, .set-p-with-hover p:first-child {
  margin-top: 0;
}
@media all and (max-width: 900px) {
  .set-p-with-hover h3:first-of-type {
    margin-top: 0.5em;
  }
}
.set-p-with-hover a {
  padding-bottom: 0 !important;
  display: inline-flex;
}

.margin-top-none {
  margin-top: 0;
}
.margin-top-small {
  margin-top: 3em;
}
.margin-top-standard {
  margin-top: 6.5em;
}
@media all and (max-width: 900px) {
  .margin-top-standard {
    margin-top: 6.5em;
  }
}
.margin-top-own.margin-top-own-0 {
  margin-top: 0;
}
.margin-top-own.margin-top-own-5 {
  margin-top: 1em;
}
.margin-top-own.margin-top-own-10 {
  margin-top: 2em;
}
.margin-top-own.margin-top-own-15 {
  margin-top: 3em;
}
.margin-top-own.margin-top-own-20 {
  margin-top: 4em;
}
.margin-top-own.margin-top-own-25 {
  margin-top: 5em;
}
.margin-top-own.margin-top-own-30 {
  margin-top: 6em;
}
.margin-top-own.margin-top-own-35 {
  margin-top: 7em;
}
.margin-top-own.margin-top-own-40 {
  margin-top: 8em;
}
.margin-top-own.margin-top-own-45 {
  margin-top: 9em;
}
.margin-top-own.margin-top-own-50 {
  margin-top: 10em;
}
.margin-top-own.margin-top-own-55 {
  margin-top: 11em;
}
.margin-top-own.margin-top-own-60 {
  margin-top: 12em;
}
.margin-top-own.margin-top-own-65 {
  margin-top: 13em;
}
.margin-top-own.margin-top-own-70 {
  margin-top: 14em;
}
.margin-top-own.margin-top-own-75 {
  margin-top: 15em;
}
.margin-top-own.margin-top-own-80 {
  margin-top: 16em;
}
.margin-top-own.margin-top-own-85 {
  margin-top: 17em;
}
.margin-top-own.margin-top-own-90 {
  margin-top: 18em;
}
.margin-top-own.margin-top-own-95 {
  margin-top: 19em;
}
.margin-top-own.margin-top-own-100 {
  margin-top: 20em;
}
@media all and (max-width: 900px) {
  .margin-top-own.margin-top-own-mob-0 {
    margin-top: 0;
  }
  .margin-top-own.margin-top-own-mob-5 {
    margin-top: 0.5em;
  }
  .margin-top-own.margin-top-own-mob-10 {
    margin-top: 1em;
  }
  .margin-top-own.margin-top-own-mob-15 {
    margin-top: 1.5em;
  }
  .margin-top-own.margin-top-own-mob-20 {
    margin-top: 2em;
  }
  .margin-top-own.margin-top-own-mob-25 {
    margin-top: 2.5em;
  }
  .margin-top-own.margin-top-own-mob-30 {
    margin-top: 3em;
  }
  .margin-top-own.margin-top-own-mob-35 {
    margin-top: 3.5em;
  }
  .margin-top-own.margin-top-own-mob-40 {
    margin-top: 4em;
  }
  .margin-top-own.margin-top-own-mob-45 {
    margin-top: 4.5em;
  }
  .margin-top-own.margin-top-own-mob-50 {
    margin-top: 5em;
  }
  .margin-top-own.margin-top-own-mob-55 {
    margin-top: 5.5em;
  }
  .margin-top-own.margin-top-own-mob-60 {
    margin-top: 6em;
  }
  .margin-top-own.margin-top-own-mob-65 {
    margin-top: 6.5em;
  }
  .margin-top-own.margin-top-own-mob-70 {
    margin-top: 7em;
  }
  .margin-top-own.margin-top-own-mob-75 {
    margin-top: 7.5em;
  }
  .margin-top-own.margin-top-own-mob-80 {
    margin-top: 8em;
  }
  .margin-top-own.margin-top-own-mob-85 {
    margin-top: 8.5em;
  }
  .margin-top-own.margin-top-own-mob-90 {
    margin-top: 9em;
  }
  .margin-top-own.margin-top-own-mob-95 {
    margin-top: 9.5em;
  }
  .margin-top-own.margin-top-own-mob-100 {
    margin-top: 10em;
  }
}
.page-id-599 .margin-top-own.margin-top-own-0, .page-id-3 .margin-top-own.margin-top-own-0 {
  padding-top: 0 !important;
}
.page-id-599 .margin-top-own.margin-top-own-5, .page-id-3 .margin-top-own.margin-top-own-5 {
  padding-top: 1em !important;
}
.page-id-599 .margin-top-own.margin-top-own-10, .page-id-3 .margin-top-own.margin-top-own-10 {
  padding-top: 2em !important;
}
.page-id-599 .margin-top-own.margin-top-own-15, .page-id-3 .margin-top-own.margin-top-own-15 {
  padding-top: 3em !important;
}
.page-id-599 .margin-top-own.margin-top-own-20, .page-id-3 .margin-top-own.margin-top-own-20 {
  padding-top: 4em !important;
}
.page-id-599 .margin-top-own.margin-top-own-25, .page-id-3 .margin-top-own.margin-top-own-25 {
  padding-top: 5em !important;
}
.page-id-599 .margin-top-own.margin-top-own-30, .page-id-3 .margin-top-own.margin-top-own-30 {
  padding-top: 6em !important;
}
.page-id-599 .margin-top-own.margin-top-own-35, .page-id-3 .margin-top-own.margin-top-own-35 {
  padding-top: 7em !important;
}
.page-id-599 .margin-top-own.margin-top-own-40, .page-id-3 .margin-top-own.margin-top-own-40 {
  padding-top: 8em !important;
}
.page-id-599 .margin-top-own.margin-top-own-45, .page-id-3 .margin-top-own.margin-top-own-45 {
  padding-top: 9em !important;
}
.page-id-599 .margin-top-own.margin-top-own-50, .page-id-3 .margin-top-own.margin-top-own-50 {
  padding-top: 10em !important;
}
.page-id-599 .margin-top-own.margin-top-own-55, .page-id-3 .margin-top-own.margin-top-own-55 {
  padding-top: 11em !important;
}
.page-id-599 .margin-top-own.margin-top-own-60, .page-id-3 .margin-top-own.margin-top-own-60 {
  padding-top: 12em !important;
}
.page-id-599 .margin-top-own.margin-top-own-65, .page-id-3 .margin-top-own.margin-top-own-65 {
  padding-top: 13em !important;
}
.page-id-599 .margin-top-own.margin-top-own-70, .page-id-3 .margin-top-own.margin-top-own-70 {
  padding-top: 14em !important;
}
.page-id-599 .margin-top-own.margin-top-own-75, .page-id-3 .margin-top-own.margin-top-own-75 {
  padding-top: 15em !important;
}
.page-id-599 .margin-top-own.margin-top-own-80, .page-id-3 .margin-top-own.margin-top-own-80 {
  padding-top: 16em !important;
}
.page-id-599 .margin-top-own.margin-top-own-85, .page-id-3 .margin-top-own.margin-top-own-85 {
  padding-top: 17em !important;
}
.page-id-599 .margin-top-own.margin-top-own-90, .page-id-3 .margin-top-own.margin-top-own-90 {
  padding-top: 18em !important;
}
.page-id-599 .margin-top-own.margin-top-own-95, .page-id-3 .margin-top-own.margin-top-own-95 {
  padding-top: 19em !important;
}
.page-id-599 .margin-top-own.margin-top-own-100, .page-id-3 .margin-top-own.margin-top-own-100 {
  padding-top: 20em !important;
}
@media all and (max-width: 900px) {
  .page-id-599 .margin-top-own.margin-top-own-mob-0, .page-id-3 .margin-top-own.margin-top-own-mob-0 {
    padding-top: 0 !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-5, .page-id-3 .margin-top-own.margin-top-own-mob-5 {
    padding-top: 0.5em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-10, .page-id-3 .margin-top-own.margin-top-own-mob-10 {
    padding-top: 1em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-15, .page-id-3 .margin-top-own.margin-top-own-mob-15 {
    padding-top: 1.5em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-20, .page-id-3 .margin-top-own.margin-top-own-mob-20 {
    padding-top: 2em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-25, .page-id-3 .margin-top-own.margin-top-own-mob-25 {
    padding-top: 2.5em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-30, .page-id-3 .margin-top-own.margin-top-own-mob-30 {
    padding-top: 3em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-35, .page-id-3 .margin-top-own.margin-top-own-mob-35 {
    padding-top: 3.5em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-40, .page-id-3 .margin-top-own.margin-top-own-mob-40 {
    padding-top: 4em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-45, .page-id-3 .margin-top-own.margin-top-own-mob-45 {
    padding-top: 4.5em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-50, .page-id-3 .margin-top-own.margin-top-own-mob-50 {
    padding-top: 5em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-55, .page-id-3 .margin-top-own.margin-top-own-mob-55 {
    padding-top: 5.5em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-60, .page-id-3 .margin-top-own.margin-top-own-mob-60 {
    padding-top: 6em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-65, .page-id-3 .margin-top-own.margin-top-own-mob-65 {
    padding-top: 6.5em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-70, .page-id-3 .margin-top-own.margin-top-own-mob-70 {
    padding-top: 7em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-75, .page-id-3 .margin-top-own.margin-top-own-mob-75 {
    padding-top: 7.5em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-80, .page-id-3 .margin-top-own.margin-top-own-mob-80 {
    padding-top: 8em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-85, .page-id-3 .margin-top-own.margin-top-own-mob-85 {
    padding-top: 8.5em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-90, .page-id-3 .margin-top-own.margin-top-own-mob-90 {
    padding-top: 9em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-95, .page-id-3 .margin-top-own.margin-top-own-mob-95 {
    padding-top: 9.5em !important;
  }
  .page-id-599 .margin-top-own.margin-top-own-mob-100, .page-id-3 .margin-top-own.margin-top-own-mob-100 {
    padding-top: 10em !important;
  }
}

/*=======================================
=            conversory logo            =
=======================================*/
/*============================
=            link            =
============================*/
.link-hover-parent:not(.link-hover-line-handleHoverClass), .set-p-with-hover a:not(.link-hover-line-handleHoverClass) {
  cursor: pointer;
}
.link-hover-parent:not(.link-hover-line-handleHoverClass):hover.link-hover-line:before, .set-p-with-hover a:not(.link-hover-line-handleHoverClass):hover:before,
.link-hover-parent:not(.link-hover-line-handleHoverClass):hover .link-hover-line:before,
.set-p-with-hover a:not(.link-hover-line-handleHoverClass):hover .link-hover-line:before,
.link-hover-parent:not(.link-hover-line-handleHoverClass):hover .set-p-with-hover a:before,
.set-p-with-hover .link-hover-parent:not(.link-hover-line-handleHoverClass):hover a:before,
.set-p-with-hover a:not(.link-hover-line-handleHoverClass):hover a:before {
  transform: translate3d(0, 0, 0);
}
.link-hover-parent:not(.link-hover-line-handleHoverClass):hover.link-hover-line.link-hover-line-inverted:before, .set-p-with-hover a:not(.link-hover-line-handleHoverClass):hover.link-hover-line-inverted:before, .set-p-with-hover a:not(.link-hover-line-handleHoverClass):hover.link-hover-line:before, .set-p-with-hover a.link-hover-parent:not(.link-hover-line-handleHoverClass):hover:before, .set-p-with-hover .set-p-with-hover a:not(.link-hover-line-handleHoverClass):hover:before,
.link-hover-parent:not(.link-hover-line-handleHoverClass):hover .link-hover-line.link-hover-line-inverted:before,
.set-p-with-hover a:not(.link-hover-line-handleHoverClass):hover .link-hover-line.link-hover-line-inverted:before,
.link-hover-parent:not(.link-hover-line-handleHoverClass):hover .set-p-with-hover a:before,
.set-p-with-hover .link-hover-parent:not(.link-hover-line-handleHoverClass):hover a:before,
.set-p-with-hover a:not(.link-hover-line-handleHoverClass):hover a:before {
  transform: translate3d(-105%, 0, 0);
}
.link-hover-parent:not(.link-hover-line-handleHoverClass):hover.link-hover-simple, .set-p-with-hover a:not(.link-hover-line-handleHoverClass):hover.link-hover-simple,
.link-hover-parent:not(.link-hover-line-handleHoverClass):hover .link-hover-simple,
.set-p-with-hover a:not(.link-hover-line-handleHoverClass):hover .link-hover-simple {
  border-bottom: 0.1em solid #222222;
}
.link-hover-parent:not(.link-hover-line-handleHoverClass):hover.link-hover-simple.link-hover-line-inverted, .set-p-with-hover a:not(.link-hover-line-handleHoverClass):hover.link-hover-simple,
.link-hover-parent:not(.link-hover-line-handleHoverClass):hover .link-hover-simple.link-hover-line-inverted,
.set-p-with-hover a:not(.link-hover-line-handleHoverClass):hover .link-hover-simple.link-hover-line-inverted,
.link-hover-parent:not(.link-hover-line-handleHoverClass):hover .set-p-with-hover a.link-hover-simple,
.set-p-with-hover .link-hover-parent:not(.link-hover-line-handleHoverClass):hover a.link-hover-simple,
.set-p-with-hover a:not(.link-hover-line-handleHoverClass):hover a.link-hover-simple {
  border-bottom: 0;
}
.link-hover-parent:not(.link-hover-line-handleHoverClass):hover.link-hover-simple-very, .set-p-with-hover a:not(.link-hover-line-handleHoverClass):hover.link-hover-simple-very, .link-hover-parent:not(.link-hover-line-handleHoverClass):hover.link-hover-simple-very {
  text-decoration: underline;
}
.link-hover-parent:not(.link-hover-line-handleHoverClass):hover.link-hover-simple-very.link-hover-line-inverted, .set-p-with-hover a:not(.link-hover-line-handleHoverClass):hover.link-hover-simple-very, .link-hover-parent:not(.link-hover-line-handleHoverClass):hover.link-hover-simple-very.link-hover-line-inverted {
  text-decoration: none;
}

.link-hover-line-handleHoverClass.is-active.link-hover-line:before, .set-p-with-hover a.link-hover-line-handleHoverClass.is-active:before,
.link-hover-line-handleHoverClass.is-active .link-hover-line:before,
.link-hover-line-handleHoverClass.is-active .set-p-with-hover a:before,
.set-p-with-hover .link-hover-line-handleHoverClass.is-active a:before {
  transform: translate3d(0, 0, 0);
}
.link-hover-line-handleHoverClass.is-active.link-hover-line.link-hover-line-inverted:before, .set-p-with-hover a.link-hover-line-handleHoverClass.is-active:before,
.link-hover-line-handleHoverClass.is-active .link-hover-line.link-hover-line-inverted:before,
.link-hover-line-handleHoverClass.is-active .set-p-with-hover a:before,
.set-p-with-hover .link-hover-line-handleHoverClass.is-active a:before {
  transform: translate3d(-105%, 0, 0);
}

.link.link-hover-line, .set-p-with-hover a {
  position: relative;
  overflow: hidden !important;
}
.link.link-hover-line:before, .set-p-with-hover a:before {
  content: "";
  width: 100%;
  height: 0.1em;
  bottom: 0;
  left: 0;
  position: absolute;
  transition: all 0.33s ease-in-out;
  transform: translate3d(-105%, 0, 0);
  background-color: #222222;
}
.is-inverted .link.link-hover-line:before, .is-inverted .set-p-with-hover a:before, .set-p-with-hover .is-inverted a:before {
  background-color: #ffffff;
}
.link.link-hover-line.link-hover-line-inverted:before, .set-p-with-hover a.link-hover-line-inverted:before, .set-p-with-hover a.link-hover-line:before, .set-p-with-hover a.link:before, .set-p-with-hover .set-p-with-hover a:before {
  transform: translate3d(0, 0, 0);
}
.link.link-hover-line.link-hover-line-bold:before, .set-p-with-hover a.link-hover-line-bold:before {
  height: 0.1em;
}
.link.link-hover-line.link-hover-line-medium-bold:before, .set-p-with-hover a.link-hover-line-medium-bold:before {
  height: 0.1em;
}
.link.link-hover-simple, .set-p-with-hover a.link-hover-simple {
  position: relative;
  overflow: hidden;
  padding-bottom: 0;
}
.link.link-hover-simple:before, .set-p-with-hover a.link-hover-simple:before {
  display: none;
}
.link.link-hover-simple.link-hover-line-inverted, .set-p-with-hover a.link-hover-simple {
  border-bottom: 0.1em solid #222222;
}
.link.link-hover-simple-very, .set-p-with-hover a.link-hover-simple-very {
  position: relative;
  overflow: hidden;
  padding-bottom: 0;
  text-decoration: none;
}
.link.link-hover-simple-very.link-hover-line-inverted, .set-p-with-hover a.link-hover-simple-very {
  text-decoration: underline;
}

/*=============================
=            quote            =
=============================*/
.quote-wrapper {
  padding: 7.5% 0;
}

.quote-text-wrapper {
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  color: #ffffff;
}
.quote-text-wrapper .quote-text {
  font-family: "Futura", sans-serif;
  font-size: 4em;
  line-height: 1.35em;
  font-weight: 500;
  letter-spacing: 0;
}
@media all and (max-width: 900px) {
  .quote-text-wrapper .quote-text {
    font-size: 3em;
  }
}
.quote-text-wrapper .quote-author-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1.5em;
}
.quote-text-wrapper .quote-author-wrapper .divider {
  margin: 1.5em 2em 0 0;
}
.quote-text-wrapper .quote-author-wrapper .quote-author {
  font-family: "Futura", sans-serif;
  font-size: 2.5em;
  line-height: 1.4em;
  font-weight: 500;
  letter-spacing: 0;
  flex: 1;
}
/*============================
=            work            =
============================*/
.work-wrapper {
  display: flex;
  flex-direction: column;
  /* enable Flickity by default */
}
.work-wrapper:after {
  content: "flickity";
  display: none; /* hide :after */
}
@media screen and (min-width: 901px) {
  .work-wrapper {
    /* disable Flickity for large devices */
  }
  .work-wrapper .carousel:after {
    content: "";
  }
}
.work-wrapper-row {
  flex-direction: row;
  width: 100%;
}
.work-wrapper-row .flickity-viewport {
  width: 100%;
}
.work-wrapper-row .work-item {
  position: relative;
  width: 31.1%;
}
.work-wrapper-row .work-item:nth-child(3n+1) {
  margin-right: 3.33%;
}
.single .work-wrapper-row .work-item:nth-child(3n+1) {
  margin-right: 3.33%;
}
.single-tools .work-wrapper-row .work-item:nth-child(3n+1), .single-services .work-wrapper-row .work-item:nth-child(3n+1) {
  margin-right: 3.33%;
}
.work-wrapper-row .work-item:nth-child(3n+2) {
  margin-right: 3.33%;
}
.single .work-wrapper-row .work-item:nth-child(3n+2) {
  margin-right: 3.33%;
}
.single-tools .work-wrapper-row .work-item:nth-child(3n+2), .single-services .work-wrapper-row .work-item:nth-child(3n+2) {
  margin-right: 3.33%;
}
@media all and (max-width: 900px) {
  .work-wrapper-row .work-item {
    width: 80%;
    height: auto;
    display: block;
    position: relative;
    transform-origin: left center;
    transition: all 0.25s ease-in-out;
    width: 95%;
  }
  .single .work-wrapper-row .work-item {
    width: 95%;
    margin-right: 0;
  }
  .single-tools .work-wrapper-row .work-item, .single-services .work-wrapper-row .work-item {
    width: 95%;
  }
}
.work-wrapper-row .work-item .image-wrapper {
  transition: all 0.25s ease-in-out;
}
@media all and (max-width: 900px) {
  .work-wrapper-row .work-item .image-wrapper {
    transform: scale(0.7);
    transform-origin: left center;
  }
}
@media all and (max-width: 900px) {
  .work-wrapper-row .work-item.is-selected .image-wrapper {
    transform: scale(1);
  }
}
.work-wrapper .work-item {
  overflow: hidden;
}
.work-wrapper .work-item .image-wrapper {
  width: 100%;
  padding-bottom: 57%;
  overflow: hidden;
}
.work-wrapper .work-item .title {
  font-family: "Kontora", sans-serif;
  font-size: 2.5em;
  line-height: 1.2em;
  font-weight: 800;
  letter-spacing: 0;
  margin-top: 0.55em;
  text-transform: uppercase;
}
.work-wrapper .work-item .subtitle {
  font-family: "Futura", sans-serif;
  font-size: 2em;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 0;
}
.work-wrapper .work-item.is_img_relative .image-wrapper {
  padding-bottom: 0;
}

.section-ThreeColumns .work-item video, .section-ThreeColumns .work-item .gif, .work-wrapper .work-item video, .work-wrapper .work-item .gif {
  position: absolute;
}

.section-Work .section-ThreeColumns .work-item video, .section-Work .section-ThreeColumns .work-item .gif, .section-Work .work-wrapper .work-item video, .section-Work .work-wrapper .work-item .gif {
  position: relative;
}

/*====================================
=            border hover            =
====================================*/
.border-hover-parent:hover .border-hover.all-after-another .line-top {
  transition: all 0.5s ease-in;
}
.border-hover-parent:hover .border-hover.all-after-another .line-right {
  transition: all 0.5s linear 0.5s;
}
.border-hover-parent:hover .border-hover.all-after-another .line-bottom {
  transition: all 0.5s linear 1s;
}
.border-hover-parent:hover .border-hover.all-after-another .line-left {
  transition: all 0.5s ease-out 1.5s;
}
.border-hover-parent:hover .border-hover.two-two .line-top, .border-hover-parent:hover .border-hover.two-two .line-left {
  transition: all 0.5s ease-in;
}
.border-hover-parent:hover .border-hover.two-two .line-right, .border-hover-parent:hover .border-hover.two-two .line-bottom {
  transition: all 0.5s ease-out 0.5s;
}
.border-hover-parent:hover .border-hover.two-two.border-hover-inverted .line-top, .border-hover-parent:hover .border-hover.two-two.border-hover-inverted .line-bottom {
  width: 0;
}
.border-hover-parent:hover .border-hover.two-two.border-hover-inverted .line-left, .border-hover-parent:hover .border-hover.two-two.border-hover-inverted .line-right {
  height: 0;
}
.border-hover-parent:hover .border-hover.two-two.border-hover-inverted .line-top, .border-hover-parent:hover .border-hover.two-two.border-hover-inverted .line-left {
  transition: all 0.5s ease-out 0.5s;
}
.border-hover-parent:hover .border-hover.two-two.border-hover-inverted .line-right, .border-hover-parent:hover .border-hover.two-two.border-hover-inverted .line-bottom {
  transition: all 0.5s ease-in;
}
.border-hover-parent:hover .border-hover .line-top, .border-hover-parent:hover .border-hover .line-bottom {
  width: 100%;
}
.border-hover-parent:hover .border-hover .line-left, .border-hover-parent:hover .border-hover .line-right {
  height: 100%;
}

.border-hover {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.border-hover.two-two .line-left {
  top: 0;
  bottom: auto;
}
.border-hover.two-two .line-bottom {
  left: 0;
  right: auto;
}
.border-hover.two-two .line-left, .border-hover.two-two .line-top {
  transition: all 0.5s ease-out 0.5s;
}
.border-hover.two-two .line-bottom, .border-hover.two-two .line-right {
  transition: all 0.5s ease-in;
}
.border-hover.two-two.border-hover-inverted .line-left, .border-hover.two-two.border-hover-inverted .line-top {
  transition: all 0.5s ease-in;
}
.border-hover.two-two.border-hover-inverted .line-bottom, .border-hover.two-two.border-hover-inverted .line-right {
  transition: all 0.5s ease-out 0.5s;
}
.border-hover.two-two.border-hover-inverted .line-top, .border-hover.two-two.border-hover-inverted .line-bottom {
  width: 100%;
}
.border-hover.two-two.border-hover-inverted .line-left, .border-hover.two-two.border-hover-inverted .line-right {
  height: 100%;
}
.border-hover .line {
  background-color: #222222;
  position: absolute;
  transition: all 1s ease-in-out;
  z-index: 1;
}
.is-inverted .border-hover .line {
  background-color: #ffffff;
}
.border-hover .line-top, .border-hover .line-bottom {
  width: 0;
  height: 4px;
}
.border-hover .line-left, .border-hover .line-right {
  width: 4px;
  height: 0;
}
.border-hover .line-top {
  top: 0;
  left: 0;
}
.border-hover .line-right {
  top: 0;
  right: 0;
}
.border-hover .line-bottom {
  right: 0;
  bottom: 0;
}
.border-hover .line-left {
  left: 0;
  bottom: 0;
}

/*===================================
=            image hover            =
===================================*/
/*===============================
=            divider            =
===============================*/
.divider {
  width: 3.5em;
  margin: 0 1.5em;
  height: 4px;
  position: relative;
  display: inline-block;
  box-sizing: content-box;
  background-color: var(--link-color);
}
.divider.divider-line-small {
  height: 2px;
  width: 4em;
}
.divider.divider-line-bold {
  height: 6px;
}
.divider.didiver-line-small-width {
  width: 1.4em;
}
@media all and (max-width: 900px) {
  .divider.didiver-line-small-width {
    height: 3px;
  }
}
.divider.didiver-small-margin {
  margin: 0 0.5em;
}
.divider.divider-no-margin-left {
  margin: 0 2em 0 0;
}

/*==========================
=            js            =
==========================*/
.js_showServices {
  cursor: pointer;
}

/*=============================
=            popup            =
=============================*/
.popup {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  transition: all 0.33s ease-in-out;
  pointer-events: none;
  z-index: 4;
  color: #ffffff;
  overflow: auto;
  padding-bottom: 10em;
}
.popup.is-open {
  opacity: 1;
  pointer-events: auto;
}
.popup.bg-no-fade.is-open {
  animation: unset;
}
.popup.tool .inner-wrapper {
  padding-bottom: 17em;
}
.isIos .popup {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  pointer-events: auto;
}
.popup .popup-inner-wrapper {
  position: absolute;
  width: 100%;
  height: auto;
}
.popup .bg-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  filter: grayscale(100%) brightness(50%) contrast(2);
}
.isIos .popup .bg-img {
  background-attachment: scroll !important;
}
.popup .nav-fake {
  height: auto;
  margin-top: 34px;
  position: relative;
  top: 0;
  padding-bottom: 1.5em;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  position: fixed;
  max-width: unset;
  z-index: 1;
}
@media all and (max-width: 900px) {
  .popup .nav-fake {
    margin-top: 34px;
  }
}
.popup .nav-fake.is-scrolled .nav-fake-inner {
  width: 100%;
  padding: 0 34px;
}
@media all and (max-width: 900px) {
  .popup .nav-fake.is-scrolled .nav-fake-inner {
    padding: 0 34px 0 4%;
  }
}
.popup .nav-fake.is-scrolled .cross {
  transform: scale(1);
}
.popup .nav-fake .nav-fake-inner {
  transition: all 0.5s ease;
  width: 90%;
  padding: 0 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media all and (max-width: 900px) {
  .popup .nav-fake .nav-fake-inner {
    width: 100%;
    padding: 0 34px 0 4%;
  }
}
.popup .nav-fake .cross {
  transform: scale(1.2);
  transition: all 0.5s ease;
}
@media all and (max-width: 900px) {
  .popup .nav-fake .cross {
    transform: scale(1);
  }
}
.popup .inner-wrapper {
  padding-bottom: 10em;
  margin-top: 12em;
  position: relative;
}
.popup .inner-wrapper .image-wrapper {
  margin-top: 5em;
  max-width: 1920px;
  width: 90%;
  margin: 5.5em auto 0 auto;
}
@media all and (max-width: 900px) {
  .popup .inner-wrapper .image-wrapper {
    margin: 2.7em auto 0 auto;
  }
}
.popup .inner-wrapper .work-wrapper {
  margin-top: 4em;
  max-width: 1920px;
  width: 90%;
  margin: 4em auto 0 auto;
}
@media all and (max-width: 900px) {
  .popup .inner-wrapper .work-wrapper {
    width: 95%;
    margin: 4em 0 0 auto;
  }
}
.popup .inner-wrapper .work-wrapper .work-item {
  color: #ffffff;
}
.popup .inner-wrapper .work-wrapper .work-item .image-wrapper {
  max-width: unset;
  width: 100%;
  margin: 0;
}
.popup.is-popup-color-white {
  background: #fff !important;
  animation: unset !important;
}
.popup.is-popup-color-white .cross:before, .popup.is-popup-color-white .cross:after {
  background-color: #222;
}
.popup.is-popup-color-white .inner-wrapper {
  color: #222;
}
.popup.is-popup-color-white .inner-wrapper .work-wrapper .work-item {
  color: #222;
}
.popup.is-popup-color-white .inner-wrapper .work-wrapper .work-item .border-hover .line {
  background-color: #222;
}
.popup.is-popup-color-white .popup-marquee .marquee-wrapper .marquee-simple .banner .banner-inner .banner-item {
  color: #222;
}
.popup.is-popup-color-white .popup-marquee .marquee-wrapper .marquee-simple .banner .banner-inner .banner-item:before {
  background-color: #222;
}

.popup-title {
  font-family: "Kontora", sans-serif;
  font-size: 7em;
  line-height: 1.14em;
  font-weight: 800;
  letter-spacing: 0;
  width: 90%;
  max-width: 1920px;
  margin: 0 auto;
}
@media all and (max-width: 900px) {
  .popup-title {
    font-size: 5em;
  }
}

.popup-subtext {
  font-family: "Futura", sans-serif;
  font-size: 4em;
  line-height: 1.35em;
  font-weight: 500;
  letter-spacing: 0;
  width: 90%;
  max-width: 1920px;
  margin: 0.5em auto 0 auto;
}
@media all and (max-width: 900px) {
  .popup-subtext {
    font-size: 3em;
  }
}

.popup-text {
  font-family: "Kontora", sans-serif;
  font-size: 6em;
  line-height: 1.25em;
  font-weight: 800;
  letter-spacing: 0;
  width: 90%;
  max-width: 1920px;
  margin: 1em auto 0 auto;
}
@media all and (max-width: 900px) {
  .popup-text {
    font-size: 5em;
  }
}
@media all and (max-width: 900px) {
  .popup-text {
    margin: 1.4em auto 0 auto;
  }
}

.popup-text_small {
  font-family: "Futura", sans-serif;
  font-size: 2.5em;
  line-height: 1.4em;
  font-weight: 500;
  letter-spacing: 0;
  width: 90%;
  max-width: 1920px;
  margin: 1.4em auto 0 auto;
}
.popup-two_columns {
  font-family: "Futura", sans-serif;
  font-size: 2.5em;
  line-height: 1.4em;
  font-weight: 500;
  letter-spacing: 0;
  width: 90%;
  max-width: 1920px;
  margin: 2.6em auto 0 auto;
  display: flex;
}
.popup-two_columns .twoColumns-text {
  flex: 1;
}
.popup-two_columns .twoColumns-text:nth-child(1) {
  margin-right: 5%;
}
@media all and (max-width: 900px) {
  .popup-two_columns .twoColumns-text:nth-child(1) {
    margin-right: 0;
  }
  .popup-two_columns .twoColumns-text:nth-child(2) {
    margin: 1.4em auto 0 auto;
    width: 100%;
  }
}
@media all and (max-width: 900px) {
  .popup-two_columns {
    flex-direction: column;
    margin: 1.3em auto 0 auto;
  }
}

.popup-marquee {
  margin-top: 7em;
  width: 100%;
}

/*====================================
=            font fade bg            =
====================================*/
.font-fade-bg {
  color: transparent !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  display: block;
  background: linear-gradient(145deg, #222222, #b5b5b5, #222222);
  background-size: 600% 600%;
  animation: fadeBgNewNew 7s ease infinite;
}

/*=============================
=            shame            =
=============================*/
@media all and (max-width: 900px) {
  .page-id-2 .content-reveal .section-ImgTwocolumnstextLink:first-child {
    padding-top: 4em;
  }
}

/*===============================
=            blendin            =
===============================*/
.blendin {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 4;
  height: 4.5em;
  left: 0;
  transform: translate3d(0, 0, 0);
  transition: 0.25s ease-in-out;
  pointer-events: none;
}
.blendin.is-hidden {
  transform: translate3d(0, 105%, 0);
}
@media all and (max-width: 645px) {
  .blendin {
    height: 10em;
  }
}
.blendin .blendin-inner {
  position: sticky;
  bottom: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
.blendin .blendin-inner a {
  padding-top: 5px;
}
.blendin .blendin-item {
  font-family: "Futura", sans-serif;
  font-size: 2em;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate3d(0, 0, 0);
  transition: all 0.25s ease-in-out 0.5s;
  pointer-events: all;
}
.blendin .blendin-item.is-hidden {
  transition: all 0.25s ease-in-out;
  transform: translate3d(0, 105%, 0);
  pointer-events: none;
}
@media all and (max-width: 645px) {
  .blendin .blendin-item {
    padding: 0 10%;
  }
}

/*========================================
=            black background            =
========================================*/
.fade-bg, .popup.is-open, .isIos .popup {
  animation: fadeBg 18s ease infinite;
  background: linear-gradient(139deg, #222222, #b7b7b7);
  background-size: 400% 400%;
}

.fade-bg-moreblack {
  animation: fadeBgMoreblack 20s ease infinite;
  background: linear-gradient(139deg, #222222, #b7b7b7);
  background-size: 400% 400%;
}

/*========================================
=            white background            =
========================================*/
.fade-bg-white {
  background: linear-gradient(120deg, #222222, #ffffff, #ffffff);
  background-size: 400% 400%;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  animation-direction: reverse;
  background-position: bottom right;
}
.fade-bg-white:hover {
  animation-name: fadeBgWhiteToBlackOnce;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: both;
}

/*==================================
=            animations            =
==================================*/
@keyframes fadeBg {
  0% {
    background-position: 0% 4%;
  }
  50% {
    background-position: 100% 97%;
  }
  100% {
    background-position: 0% 4%;
  }
}
@keyframes fadeBgNew {
  0% {
    background-position: 0% 26%;
  }
  50% {
    background-position: 100% 75%;
  }
  100% {
    background-position: 0% 26%;
  }
}
@keyframes fadeBgNewNew {
  0% {
    background-position: 0% 7%;
  }
  50% {
    background-position: 100% 94%;
  }
  100% {
    background-position: 0% 7%;
  }
}
@keyframes fadeBgMoreblack {
  0% {
    background-position: 0% 4%;
  }
  50% {
    background-position: 20% 17%;
  }
  100% {
    background-position: 0% 4%;
  }
}
@keyframes fadeBgWhiteToBlackOnce {
  from {
    background-position: 100% 94%;
  }
  to {
    background-position: 0% 7%;
  }
}
@keyframes fadeBgWhiteToBlackOnce {
  0% {
    background-position: 100% 94%;
  }
  6% {
    background-position: 0% 7%;
  }
  40% {
    background-position: 31% 19%;
  }
  60% {
    background-position: 12% 1%;
  }
  86% {
    background-position: 45% 35%;
  }
  96% {
    background-position: 12% 18%;
  }
  100% {
    background-position: 100% 94%;
  }
}
.js_siteHider {
  background-color: #ffffff;
}
.js_siteHider.is-inverted {
  background-color: #222222;
}

.section-ThreeColumns .singl-work-inner-wrapper {
  width: 89.8%;
  margin: 0 auto;
  max-width: 1920px;
}
@media all and (max-width: 900px) {
  .section-ThreeColumns .singl-work-inner-wrapper {
    width: 94.5%;
    margin: 0 0 0 auto;
  }
}

nav {
  width: 100%;
  height: 9em;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: flex-end;
  background-color: #ffffff;
  z-index: 3;
  padding-bottom: 2px;
  transition: all 0.5s ease;
  opacity: 1;
}
nav.is-locked {
  pointer-events: none !important;
}
.single-services nav, .single-tools nav {
  display: none;
}
nav.is-hidden {
  opacity: 0;
  pointer-events: none;
}
nav.is-inverted {
  background-color: #222222;
  color: #ffffff;
  height: 8em;
}
nav.is-inverted .nav-inner-wrapper {
  position: relative;
  width: 1920px;
  padding: 0;
}
@media all and (max-width: 2130px) {
  nav.is-inverted .nav-inner-wrapper {
    width: 90%;
    padding: 0;
  }
}
.isIE11 nav.is-inverted .nav-inner-wrapper path,
.isIE11 nav.is-inverted .nav-inner-wrapper polygon {
  fill: #ffffff;
}
nav.is-inverted .nav-inner-wrapper .active-line-wrapper .active-line {
  background-color: #ffffff;
}
nav.is-inverted .nav-inner-wrapper .nav-wrapper {
  background-color: #222222;
}
nav.is-scrolled {
  height: 8em;
}
nav.is-scrolled .nav-inner-wrapper {
  width: 100%;
  padding: 0 34px;
}
@media all and (max-width: 2130px) {
  nav.is-scrolled .nav-inner-wrapper {
    width: 100%;
    padding: 0 34px;
  }
}
@media all and (max-width: 900px) {
  nav.is-scrolled .nav-inner-wrapper {
    width: 90%;
    padding: 0;
  }
}
nav.is-scrolled .conversory-logo {
  transform: scale(1) !important;
}
nav.is-scrolled .nav-wrapper {
  transform: scale(1) !important;
}
nav.is-scrolled .burger {
  transform: scale(1) !important;
}
nav.is-open .conversory-logo {
  transform: scale(1) !important;
}
nav.is-open .nav-wrapper {
  transform: scale(1) !important;
}
nav.is-open .burger {
  transform: scale(1) !important;
}
@media all and (max-width: 900px) {
  nav {
    height: 8em;
    position: fixed;
    padding-bottom: 0;
  }
}
.isIE11 nav {
  justify-content: flex-start;
}
nav .nav-inner-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  height: 100%;
  transition: all 0.33s ease-in-out;
  opacity: 1;
  max-width: unset;
  width: 100%;
  padding: 0 35px;
  width: 1920px;
  padding: 0;
}
nav .nav-inner-wrapper.is-hidden {
  opacity: 0;
}
@media all and (max-width: 2130px) {
  nav .nav-inner-wrapper {
    width: 90%;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  nav .nav-inner-wrapper {
    align-items: center;
  }
}
nav .nav-inner-wrapper .conversory-logo {
  padding-bottom: 26px;
  z-index: 1;
  transform-origin: left center;
  transition: transform 0.5s ease;
  will-change: auto;
  transform: scale(1.15);
}
@media all and (max-width: 1920px) {
  nav .nav-inner-wrapper .conversory-logo {
    transform: scale(1.15);
  }
}
@media all and (max-width: 1024px) {
  nav .nav-inner-wrapper .conversory-logo {
    transform: scale(1.15);
  }
}
@media all and (max-width: 900px) {
  nav .nav-inner-wrapper .conversory-logo {
    padding-bottom: 0;
    transform: scale(1);
  }
}
nav .nav-inner-wrapper .nav-wrapper {
  position: relative;
  height: auto;
  display: flex;
  height: 100%;
  align-items: flex-end;
  transform-origin: right center;
  transition: transform 0.5s ease;
  will-change: auto;
  transform: scale(1.15);
}
@media all and (max-width: 1920px) {
  nav .nav-inner-wrapper .nav-wrapper {
    transform: scale(1.15);
  }
}
@media all and (max-width: 1024px) {
  nav .nav-inner-wrapper .nav-wrapper {
    transform: scale(1.15);
  }
}
@media all and (max-width: 900px) {
  nav .nav-inner-wrapper .nav-wrapper {
    transform: scale(1);
    opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 0.33s ease-in-out;
    background-color: #ffffff;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0 auto;
    padding: 9em 5% 0 5%;
    pointer-events: none;
    height: 100vh;
    transform: translate3d(0, -100px, 0);
  }
  .isAndroid nav .nav-inner-wrapper .nav-wrapper {
    padding: 13em 5% 0 5%;
  }
  .isFacebook nav .nav-inner-wrapper .nav-wrapper {
    padding: 9em 5% 0 5%;
  }
  nav .nav-inner-wrapper .nav-wrapper.is-open {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    pointer-events: auto;
  }
  nav .nav-inner-wrapper .nav-wrapper.is-open .menu-item {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  nav .nav-inner-wrapper .nav-wrapper.is-open .menu-item:nth-child(1) {
    transition: all 0.33s ease-in-out 0.33s;
  }
  nav .nav-inner-wrapper .nav-wrapper.is-open .menu-item:nth-child(2) {
    transition: all 0.33s ease-in-out 0.53s;
  }
  nav .nav-inner-wrapper .nav-wrapper.is-open .menu-item:nth-child(3) {
    transition: all 0.33s ease-in-out 0.73s;
  }
  nav .nav-inner-wrapper .nav-wrapper.is-open .menu-item:nth-child(4) {
    transition: all 0.33s ease-in-out 0.93s;
  }
}
nav .nav-inner-wrapper .nav-wrapper .menu-item {
  font-family: "Kontora", sans-serif;
  font-size: 2em;
  line-height: 1.25em;
  font-weight: 400;
  letter-spacing: 0;
  margin-right: 4em;
  padding-bottom: 22px;
  height: 100%;
  display: flex;
  align-items: flex-end;
}
@media all and (max-width: 900px) {
  nav .nav-inner-wrapper .nav-wrapper .menu-item {
    transform: translate3d(-100px, 0, 0);
    opacity: 0;
    transition: all 0.33s ease-in-out 0.25s;
    padding-bottom: 0;
    margin-top: 19px;
  }
}
@media all and (max-width: 900px) and (max-height: 510px) {
  nav .nav-inner-wrapper .nav-wrapper .menu-item {
    font-size: 2.5em;
  }
}
nav .nav-inner-wrapper .nav-wrapper .menu-item:first-child {
  margin-top: 0;
}
nav .nav-inner-wrapper .nav-wrapper .menu-item:last-of-type {
  margin-right: 0;
}
@media all and (max-width: 900px) {
  nav .nav-inner-wrapper .nav-wrapper .menu-item.is-active {
    display: inline-flex;
    overflow: hidden;
  }
  nav .nav-inner-wrapper .nav-wrapper .menu-item.is-active:before {
    content: "";
    width: 100%;
    height: 0.085em;
    bottom: 0;
    left: 0;
    position: absolute;
    transition: all 0.33s ease-in-out;
    transform: translate3d(0, 0, 0);
    background-color: #222222;
  }
  .is-inverted nav .nav-inner-wrapper .nav-wrapper .menu-item.is-active:before {
    background-color: #ffffff;
  }
  nav .nav-inner-wrapper .nav-wrapper .menu-item.is-active:hover:before {
    transform: translate3d(-105%, 0, 0);
  }
}
.is-inverted nav .nav-inner-wrapper .nav-wrapper .menu-item {
  color: #ffffff;
}
@media all and (max-width: 900px) {
  nav .nav-inner-wrapper .nav-wrapper .menu-item {
    font-size: 5em;
    height: auto;
    overflow: hidden;
  }
}
@media all and (max-width: 900px) and (max-height: 510px) {
  nav .nav-inner-wrapper .nav-wrapper .menu-item {
    font-size: 2.5em;
  }
}
nav .nav-inner-wrapper .burger {
  align-self: center;
  display: none;
}
@media all and (max-width: 900px) {
  nav .nav-inner-wrapper .burger {
    display: block;
    transform: scale(1);
    transform-origin: right center;
    transition: transform 0.5s ease;
    will-change: auto;
  }
}

/*========================================
=            active menu line            =
========================================*/
.active-line-wrapper {
  position: absolute;
  height: auto;
  bottom: 0;
  width: 100%;
  backface-visibility: hidden;
}
.active-line-wrapper .active-line {
  width: 80px;
  height: 2px;
  position: absolute;
  background-color: #222222;
  left: 0;
  width: 0;
  backface-visibility: hidden;
  transition: all 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.section-Work {
  margin-top: 5.5em;
}
.section-Work .inner-wrapper {
  width: 92.5%;
  max-width: 1980px;
}
@media all and (max-width: 900px) {
  .section-Work .inner-wrapper {
    width: 90%;
  }
}
.section-Work .inner-wrapper .project-grid .grid-stamp-text {
  width: 46%;
  height: auto;
  margin-bottom: 5.5em;
  margin-right: 1.5%;
  margin-left: 1.5%;
}
@media all and (max-width: 900px) {
  .section-Work .inner-wrapper .project-grid .grid-stamp-text {
    width: 100%;
    margin-bottom: 2em;
    margin-right: 0;
    margin-left: 0;
  }
}
.section-Work .inner-wrapper .project-grid .grid-stamp-text .headline {
  font-family: "Kontora", sans-serif;
  font-size: 7em;
  line-height: 1.14em;
  font-weight: 800;
  letter-spacing: 0;
}
@media all and (max-width: 900px) {
  .section-Work .inner-wrapper .project-grid .grid-stamp-text .headline {
    font-size: 5em;
  }
}
.section-Work .inner-wrapper .project-grid .grid-stamp-text .text {
  font-family: "Futura", sans-serif;
  font-size: 4em;
  line-height: 1.35em;
  font-weight: 500;
  letter-spacing: 0;
  margin-top: 1em;
}
@media all and (max-width: 900px) {
  .section-Work .inner-wrapper .project-grid .grid-stamp-text .text {
    font-size: 3em;
  }
}
@media all and (max-width: 900px) {
  .section-Work .inner-wrapper .project-grid .grid-stamp-text .text {
    margin-top: 0.3em;
  }
}
.section-Work .inner-wrapper .project-grid .grid-stamp-text .text .divider {
  transform: translate(0, -8px);
}
.section-Work .inner-wrapper .project-grid .grid-stamp-text .text .services-filter {
  opacity: 0;
  position: relative;
  display: none;
  transition: all 0.25s ease-in-out;
}
.section-Work .inner-wrapper .project-grid .grid-stamp-text .text .services-filter.set-height {
  display: inline;
}
.section-Work .inner-wrapper .project-grid .grid-stamp-text .text .services-filter.is-visible {
  opacity: 1;
}
.section-Work .inner-wrapper .project-grid .grid-stamp-text .text .js_showServices {
  display: inline-flex;
}
.section-Work .inner-wrapper .project-grid .grid-stamp-text .text .js_showServices.is_inline {
  display: inline;
}
.section-Work .inner-wrapper .project-grid .grid-stamp-text .text .js_showServices.is_inline:before {
  display: none;
}
.section-Work .inner-wrapper .project-grid .grid-stamp-text .text .js_filterService.is-active {
  border-bottom: 0.1em solid #222222;
}
.section-Work .inner-wrapper .project-grid .grid-sizer,
.section-Work .inner-wrapper .project-grid .work-item {
  width: 47%;
  margin-bottom: 1.2em;
  margin-top: 1.3em;
  margin-right: 1.5%;
  margin-left: 1.5%;
  position: relative;
  display: block;
}
@media all and (max-width: 900px) {
  .section-Work .inner-wrapper .project-grid .grid-sizer,
  .section-Work .inner-wrapper .project-grid .work-item {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}
.section-Work .inner-wrapper .project-grid .grid-sizer .image-wrapper,
.section-Work .inner-wrapper .project-grid .work-item .image-wrapper {
  width: 100%;
}

.section-single-work .inner-wrapper .headline {
  font-family: "Kontora", sans-serif;
  font-size: 7em;
  line-height: 1.14em;
  font-weight: 800;
  letter-spacing: 0;
}
@media all and (max-width: 900px) {
  .section-single-work .inner-wrapper .headline {
    font-size: 5em;
  }
}
.section-single-work .inner-wrapper .services {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3em;
}
.section-single-work .inner-wrapper .services .service-intro,
.section-single-work .inner-wrapper .services .service-wrapper {
  font-family: "Futura", sans-serif;
  font-size: 2em;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 0;
}
.section-single-work .inner-wrapper .services .service-wrapper {
  /* enable Flickity by default */
}
@media all and (max-width: 900px) {
  .section-single-work .inner-wrapper .services .service-wrapper {
    width: 100%;
  }
}
.section-single-work .inner-wrapper .services .service-wrapper:after {
  content: "flickity";
  display: none; /* hide :after */
}
@media screen and (min-width: 901px) {
  .section-single-work .inner-wrapper .services .service-wrapper {
    /* disable Flickity for large devices */
  }
  .section-single-work .inner-wrapper .services .service-wrapper:after {
    content: "";
  }
}
.section-single-work .inner-wrapper .services .service-wrapper .slider-item {
  display: inline-flex;
}
@media all and (max-width: 900px) {
  .section-single-work .inner-wrapper .services .service-wrapper .slider-item {
    white-space: nowrap;
  }
}
.section-single-work .inner-wrapper .work-content {
  margin-top: 4.5em;
}
@media all and (max-width: 900px) {
  .section-single-work .inner-wrapper .work-content {
    margin-top: 4em;
  }
}
.section-single-work .inner-wrapper .section-single-work-item {
  margin-top: 68px;
}
.section-single-work .inner-wrapper .section-single-work-item:first-child {
  margin-top: 0;
}
@media all and (max-width: 900px) {
  .section-single-work .inner-wrapper .section-single-work-item {
    margin-top: 30px;
  }
}
.section-single-work .inner-wrapper .section-single-work-item-img .image-wrapper {
  width: 100%;
}
.section-single-work .inner-wrapper .section-single-work-item-text {
  font-family: "Futura", sans-serif;
  font-size: 4em;
  line-height: 1.35em;
  font-weight: 500;
  letter-spacing: 0;
  margin-top: 60px;
}
@media all and (max-width: 900px) {
  .section-single-work .inner-wrapper .section-single-work-item-text {
    font-size: 3em;
  }
}
@media all and (max-width: 900px) {
  .section-single-work .inner-wrapper .section-single-work-item-text {
    margin-top: 30px;
  }
}
.section-single-work .inner-wrapper .section-single-work-item-text-small {
  font-family: "Futura", sans-serif;
  font-size: 2.5em;
  line-height: 1.4em;
  font-weight: 500;
  letter-spacing: 0;
  margin-top: 60px;
}
@media all and (max-width: 900px) {
  .section-single-work .inner-wrapper .section-single-work-item-text-small {
    margin-top: 30px;
  }
}
.section-single-work .inner-wrapper .section-single-work-item-twocolumns-text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 60px;
}
@media all and (max-width: 900px) {
  .section-single-work .inner-wrapper .section-single-work-item-twocolumns-text {
    margin-top: 30px;
  }
}
.section-single-work .inner-wrapper .section-single-work-item-twocolumns-text .headline {
  font-family: "Kontora", sans-serif;
  font-size: 4em;
  line-height: 1.125em;
  font-weight: 800;
  letter-spacing: 0;
  width: 100%;
  margin-bottom: 30px;
}
.section-single-work .inner-wrapper .section-single-work-item-twocolumns-text .headline h1, .section-single-work .inner-wrapper .section-single-work-item-twocolumns-text .headline h2, .section-single-work .inner-wrapper .section-single-work-item-twocolumns-text .headline h3, .section-single-work .inner-wrapper .section-single-work-item-twocolumns-text .headline h4, .section-single-work .inner-wrapper .section-single-work-item-twocolumns-text .headline h5 {
  margin-top: 0;
}
@media all and (max-width: 900px) {
  .section-single-work .inner-wrapper .section-single-work-item-twocolumns-text .headline {
    margin-bottom: 0.5em;
  }
}
.section-single-work .inner-wrapper .section-single-work-item-twocolumns-text .box {
  margin-top: 0;
}
@media all and (max-width: 900px) {
  .section-single-work .inner-wrapper .section-single-work-item-twocolumns-text .box {
    margin-top: 1em;
  }
}
.section-single-work .inner-wrapper .section-single-work-item.no-margin-top {
  margin-top: 0;
}
.section-single-work .inner-wrapper .section-single-work-item .box {
  font-family: "Futura", sans-serif;
  font-size: 2.5em;
  line-height: 1.4em;
  font-weight: 500;
  letter-spacing: 0;
  width: 47%;
}
.section-single-work .inner-wrapper .section-single-work-item .box-left {
  margin-right: 2.5%;
}
.section-single-work .inner-wrapper .section-single-work-item .box-left.css_column {
  margin-right: 0;
  width: 100%;
  column-count: 2;
  column-gap: 5%;
}
@media all and (max-width: 900px) {
  .section-single-work .inner-wrapper .section-single-work-item .box-left.css_column {
    column-count: unset;
    column-gap: unset;
  }
}
.section-single-work .inner-wrapper .section-single-work-item .box-right {
  margin-left: 2.5%;
}
@media all and (max-width: 900px) {
  .section-single-work .inner-wrapper .section-single-work-item .box {
    width: 100%;
  }
  .section-single-work .inner-wrapper .section-single-work-item .box-left {
    margin-right: 0;
    margin-top: 0;
  }
  .section-single-work .inner-wrapper .section-single-work-item .box-right {
    margin-left: 0;
    margin-top: 25px;
  }
}
.section-single-work .inner-wrapper .section-single-work-item-twocolumns-img {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.section-single-work .inner-wrapper .section-single-work-item-twocolumns-img .image-wrapper {
  width: 100%;
  padding-bottom: 51%;
}
@media all and (max-width: 900px) {
  .section-single-work .inner-wrapper .section-single-work-marquee {
    margin-top: 60px;
  }
}
.section-single-work .inner-wrapper .section-single-work-marquee + .section-single-work-item-text {
  margin-top: 60px;
}

.section-ThreeColumns-single {
  margin-top: 4em;
}
.section-ThreeColumns-single .work-wrapper-row a .image-wrapper img {
  position: absolute;
  object-fit: cover;
  height: 100%;
}
.section-ThreeColumns-single .work-wrapper-row a video {
  position: absolute;
  object-fit: cover;
}

.section-ImgHeadline .inner-wrapper .headline {
  font-family: "Kontora", sans-serif;
  font-size: 7em;
  line-height: 1.14em;
  font-weight: 800;
  letter-spacing: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #222222;
  z-index: 1;
}
@media all and (max-width: 900px) {
  .section-ImgHeadline .inner-wrapper .headline {
    font-size: 5em;
  }
}
.is-inverted .section-ImgHeadline .inner-wrapper .headline {
  color: #ffffff;
}

.section-ImgTwocolumnstextLink {
  max-width: 1920px;
  margin: 0 auto;
  width: 90%;
}
.section-ImgTwocolumnstextLink.has-image {
  width: 100%;
}
@media all and (max-width: 900px) {
  .section-ImgTwocolumnstextLink .inner-wrapper {
    display: flex;
    flex-direction: column;
  }
}
.section-ImgTwocolumnstextLink .inner-wrapper .rows {
  position: relative;
  display: flex;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: unset;
}
.is-inverted .section-ImgTwocolumnstextLink .inner-wrapper .rows {
  color: #ffffff !important;
}
.is-inverted .section-ImgTwocolumnstextLink .inner-wrapper .rows .link.link-hover-line:before, .is-inverted .section-ImgTwocolumnstextLink .inner-wrapper .rows .set-p-with-hover a:before, .set-p-with-hover .is-inverted .section-ImgTwocolumnstextLink .inner-wrapper .rows a:before {
  background-color: #ffffff !important;
}
@media all and (max-width: 900px) {
  .section-ImgTwocolumnstextLink .inner-wrapper .rows {
    justify-content: flex-start;
  }
}
.section-ImgTwocolumnstextLink .inner-wrapper .rows.has-image {
  padding: 10% 0;
  min-height: 550px;
  max-width: 1920px;
  width: 90%;
}
.is-inverted .section-ImgTwocolumnstextLink .inner-wrapper .rows.has-image {
  color: inherit !important;
}
@media all and (max-width: 900px) {
  .section-ImgTwocolumnstextLink .inner-wrapper .rows.has-image {
    margin-top: 0;
    padding: 8em 0;
    order: 2;
    min-height: unset;
  }
}
.section-ImgTwocolumnstextLink .inner-wrapper .rows.has-image .row .link:before, .section-ImgTwocolumnstextLink .inner-wrapper .rows.has-image .row .set-p-with-hover a:before, .set-p-with-hover .section-ImgTwocolumnstextLink .inner-wrapper .rows.has-image .row a:before {
  background-color: #ffffff;
}
@media all and (max-width: 900px) {
  .section-ImgTwocolumnstextLink .inner-wrapper .rows.has-image.image-mobile-small {
    padding: 0;
    margin-top: 5.2em;
    order: 2;
    margin-bottom: 8em;
  }
}
.section-ImgTwocolumnstextLink .inner-wrapper .rows.has-image.image-mobile-small .row .link:before, .section-ImgTwocolumnstextLink .inner-wrapper .rows.has-image.image-mobile-small .row .set-p-with-hover a:before, .set-p-with-hover .section-ImgTwocolumnstextLink .inner-wrapper .rows.has-image.image-mobile-small .row a:before {
  background-color: #222222;
}
.section-ImgTwocolumnstextLink .inner-wrapper .rows .row {
  display: flex;
  justify-content: flex-start;
}
.section-ImgTwocolumnstextLink .inner-wrapper .rows .row-3 {
  margin-top: 5em;
}
@media all and (max-width: 900px) {
  .section-ImgTwocolumnstextLink .inner-wrapper .rows .row-2 {
    flex-direction: column;
  }
}
.section-ImgTwocolumnstextLink .inner-wrapper .rows .row .cell {
  width: 50%;
}
@media all and (max-width: 900px) {
  .section-ImgTwocolumnstextLink .inner-wrapper .rows .row .cell {
    width: 100%;
  }
}
.section-ImgTwocolumnstextLink .inner-wrapper .rows .row .headline {
  margin-bottom: 1.3em;
}
.section-ImgTwocolumnstextLink .inner-wrapper .rows .row .headline.half_margin_headline {
  margin-bottom: 30px;
}
@media all and (max-width: 900px) {
  .section-ImgTwocolumnstextLink .inner-wrapper .rows .row .headline.half_margin_headline {
    margin-bottom: 0.5em;
  }
}
.section-ImgTwocolumnstextLink .inner-wrapper .rows .row .text {
  font-family: "Futura", sans-serif;
  font-size: 2.5em;
  line-height: 1.4em;
  font-weight: 500;
  letter-spacing: 0;
}
.section-ImgTwocolumnstextLink .inner-wrapper .rows .row .text-left {
  margin-right: 2.5%;
}
.section-ImgTwocolumnstextLink .inner-wrapper .rows .row .text-left.css_column {
  column-count: 2;
  column-gap: 5%;
  width: 100%;
  margin-right: 0;
}
@media all and (max-width: 900px) {
  .section-ImgTwocolumnstextLink .inner-wrapper .rows .row .text-left.css_column {
    column-count: unset;
    column-gap: unset;
  }
}
@media all and (max-width: 900px) {
  .section-ImgTwocolumnstextLink .inner-wrapper .rows .row .text-left {
    margin-right: 0;
  }
}
.section-ImgTwocolumnstextLink .inner-wrapper .rows .row .text-right {
  margin-left: 2.5%;
}
.section-ImgTwocolumnstextLink .inner-wrapper .rows .row .text-right.visibily-hidden {
  opacity: 0;
  height: 0;
  position: absolute;
}
@media all and (max-width: 900px) {
  .section-ImgTwocolumnstextLink .inner-wrapper .rows .row .text-right {
    margin-left: 0;
    margin-top: 1em;
  }
}
.section-ImgTwocolumnstextLink .inner-wrapper .rows .row .link, .section-ImgTwocolumnstextLink .inner-wrapper .rows .row .set-p-with-hover a, .set-p-with-hover .section-ImgTwocolumnstextLink .inner-wrapper .rows .row a {
  font-family: "Futura", sans-serif;
  font-size: 2em;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 0;
  color: inherit;
}
.section-ImgTwocolumnstextLink .inner-wrapper .absolute-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media all and (max-width: 900px) {
  .section-ImgTwocolumnstextLink .inner-wrapper .absolute-wrapper.image-mobile-small {
    position: relative;
    order: 1;
    width: 100%;
    right: auto;
    bottom: auto;
  }
}
@media all and (max-width: 900px) {
  .section-ImgTwocolumnstextLink .inner-wrapper .absolute-wrapper.image-mobile-small .image-wrapper {
    height: 0;
    padding-bottom: 50%;
  }
}
.section-ImgTwocolumnstextLink .inner-wrapper .absolute-wrapper.img-half {
  width: 60%;
  left: auto;
}
@media all and (max-width: 900px) {
  .section-ImgTwocolumnstextLink .inner-wrapper .absolute-wrapper.img-half {
    width: 100%;
  }
}
@media all and (max-width: 900px) {
  .section-ImgTwocolumnstextLink .inner-wrapper .absolute-wrapper.img-half .image-wrapper {
    padding-bottom: 60%;
  }
}
.section-ImgTwocolumnstextLink .inner-wrapper .absolute-wrapper.img-half .image-wrapper img {
  object-position: top right;
}
@media all and (max-width: 900px) {
  .section-ImgTwocolumnstextLink .inner-wrapper .absolute-wrapper.img-half .image-wrapper img {
    object-position: bottom right;
  }
}
.section-ImgTwocolumnstextLink .inner-wrapper .absolute-wrapper.whitefont:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #222222;
  opacity: 0.5;
  z-index: 1;
}
.section-ImgTwocolumnstextLink .inner-wrapper .absolute-wrapper .image-wrapper {
  width: 100%;
  height: 100%;
}
/*
.section-ImgTwocolumnstextLink {
	max-width: $max-width;
	margin: 0 auto;
	.inner-wrapper {
		@media all and (max-width: $bp1) {
			display: flex;
		    flex-direction: column;
		}
		.rows {
			position: relative;
			// z-index: 1;
		    // padding: 10% 0;
	     //    min-height: 550px;
		    display: flex;
		    z-index: 2;
		    flex-direction: column;
		    justify-content: center;
			// top: 50%;
		 //    left: 50%;
		 //    transform: translate(-50%, -50%);
		 	.is-inverted & {
				color: $white !important;
				.link.link-hover-line {
					&:before {
						background-color: $white !important;
					}
				}
			}
		 	@media all and (max-width: $bp1) {
				justify-content: flex-start;
			}
		 	&.has-image {
		 		padding: 10% 0;
			 	min-height: 550px;
			 	.is-inverted & {
					color: inherit !important;
				}
			 	@media all and (max-width: $bp1) {
					margin-top: 0;
					padding: 8em 0;
				    order: 2;
				    min-height: unset;
				}
				.row {
					.link {
						&:before {
							background-color: $white;
						}
					}
				}
		 	}
		 	&.has-image.image-mobile-small {
			 	@media all and (max-width: $bp1) {
					padding: 0;
					// margin-top: -6%;
					// margin-top: -15%;
					// margin-top: -7em;
					margin-top: 1em;
				    order: 2;
				    margin-bottom: 8em;
				}
				.row {
					.link {
						&:before {
							background-color: $black;
						}
					}
				}
		 	}
			.row {
			    display: flex;
    			justify-content: flex-start;
				&-2,
				&-3 {
					// margin-top: 2.5em;
					// margin-top: 5em;
					@media all and (max-width: $bp1) {
					    // margin-top: 2.5em;
					}
				}
				&-3 {
					// margin-top: 2.5em;
					margin-top: 5em;
					@media all and (max-width: $bp1) {
					    // margin-top: 2.5em;
					}
				}
				&.image-mobile-small {
					@media all and (max-width: $bp1) {
					    // color: $black;
					}
				}
				&-2 {
					@media all and (max-width: $bp1) {
						flex-direction: column;
					}
				}
				.cell {
					width: 50%;
					@media all and (max-width: $bp1) {
						width: 100%;
					}
				}
				.headline {
					// @include f-kontora-b-40;
					// margin-bottom: 1.25em;
					margin-bottom: 1.3em;
					&.half_margin_headline {
						margin-bottom: 30px;
						@media all and (max-width: $bp1) {
							margin-bottom: 0.5em;
						}
					}
				}
				.text {
					@include f-futura-m-25;
					&-left {
						margin-right: 2.5%;
						&.css_column {
							column-count: 2;
							column-gap: 5%;
							width: 100%;
							margin-right: 0;
							@media all and (max-width: $bp1) {
								column-count:unset;
								column-gap: unset;
							}
						}
						@media all and (max-width: $bp1) {
							margin-right: 0;
						}
					}
					&-right {
						margin-left: 2.5%;
						&.visibily-hidden {
						    opacity: 0;
						    height: 0;
						    position: absolute;
						}
						@media all and (max-width: $bp1) {
							margin-left: 0;
							margin-top: 1em;
						}
					}
				}
				.link {
					@include f-futura-m-20;
					color: inherit;
					@media all and (max-width: $bp1) {
						&:before {

					    	// background-color: $black;
						}
					}
				}
			}
		}
		.absolute-wrapper {
		    position: absolute;
		    top: 0;
		    right: 0;
		    bottom: 0;
		    left: 0;
			&.image-mobile-small {
			    @media all and (max-width: $bp1) {
				    position: relative;
				    order: 1;
				    width: 100%;
				    right: auto;
				    bottom: auto;
				}
				.image-wrapper {
					@media all and (max-width: $bp1) {
				        height: 0;
		    			padding-bottom: 50%;
		    		}
				}
			}
		    &.img-half {
		    	width: 60%;
		    	left: auto;
		    	@media all and (max-width: $bp1) {
					width: 100%;
				}
		    	.image-wrapper {
		    		img {
		    			object-position: top left;
		    			@media all and (max-width: $bp1) {
							object-position: bottom right;
						}
		    		}
		    	}
		    }
		    &.whitefont {
		    	&:before {
		    		content: "";
		    		width: 100%;
		    		height: 100%;
		    		position: absolute;
		    		top: 0;
		    		right: 0;
		    		bottom: 0;
		    		left: 0;
		    		background-color: #222222;
		    		opacity: 0.5;
				    z-index: 1;
		    	}
		    }
			.image-wrapper {
				width: 100%;
				height: 100%;
			}
		}
	}
}
*/
.section-Jobs .inner-wrapper .tool-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: 13em;
}
.section-Jobs .inner-wrapper .tool-item:first-child {
  margin-top: 0;
}
.section-Jobs .inner-wrapper .tool-item:nth-child(2n) .box-1 {
  order: 2;
  margin-right: 0;
  margin-left: 5%;
}
.section-Jobs .inner-wrapper .tool-item:nth-child(2n) .box-2 {
  order: 1;
  margin-left: 0;
  margin-right: 5%;
}
.section-Jobs .inner-wrapper .tool-item .box {
  width: 45%;
}
.section-Jobs .inner-wrapper .tool-item .box .image-wrapper {
  width: 100%;
  padding-bottom: 87%;
}
.section-Jobs .inner-wrapper .tool-item .box .title {
  font-family: "Kontora", sans-serif;
  font-size: 4em;
  line-height: 1.125em;
  font-weight: 800;
  letter-spacing: 0;
}
.section-Jobs .inner-wrapper .tool-item .box .text {
  font-family: "Futura", sans-serif;
  font-size: 2.5em;
  line-height: 1.4em;
  font-weight: 500;
  letter-spacing: 0;
  margin-top: 1em;
}
.section-Jobs .inner-wrapper .tool-item .box-1 {
  margin-right: 5%;
}
.section-Jobs .inner-wrapper .tool-item .box-2 {
  margin-left: 5%;
}

.section-Marquee,
.popup-marquee,
.section-single-work-marquee {
  overflow: hidden;
  z-index: 1;
}
.section-Marquee .inner-wrapper.width-full,
.section-Marquee .marquee-wrapper.width-full,
.popup-marquee .inner-wrapper.width-full,
.popup-marquee .marquee-wrapper.width-full,
.section-single-work-marquee .inner-wrapper.width-full,
.section-single-work-marquee .marquee-wrapper.width-full {
  max-width: unset;
}
.section-Marquee .inner-wrapper .marquee,
.section-Marquee .marquee-wrapper .marquee,
.popup-marquee .inner-wrapper .marquee,
.popup-marquee .marquee-wrapper .marquee,
.section-single-work-marquee .inner-wrapper .marquee,
.section-single-work-marquee .marquee-wrapper .marquee {
  position: relative;
}
.section-Marquee .inner-wrapper .marquee .headline,
.section-Marquee .marquee-wrapper .marquee .headline,
.popup-marquee .inner-wrapper .marquee .headline,
.popup-marquee .marquee-wrapper .marquee .headline,
.section-single-work-marquee .inner-wrapper .marquee .headline,
.section-single-work-marquee .marquee-wrapper .marquee .headline {
  font-family: "Kontora", sans-serif;
  font-size: 6em;
  line-height: 1.25em;
  font-weight: 800;
  letter-spacing: 0;
  width: 100%;
  color: #ffffff;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 49%;
  left: 0;
  transform: translate(0, -90%);
  z-index: 3;
}
@media all and (max-width: 900px) {
  .section-Marquee .inner-wrapper .marquee .headline,
  .section-Marquee .marquee-wrapper .marquee .headline,
  .popup-marquee .inner-wrapper .marquee .headline,
  .popup-marquee .marquee-wrapper .marquee .headline,
  .section-single-work-marquee .inner-wrapper .marquee .headline,
  .section-single-work-marquee .marquee-wrapper .marquee .headline {
    font-size: 5em;
  }
}
@media all and (max-width: 900px) {
  .section-Marquee .inner-wrapper .marquee .headline,
  .section-Marquee .marquee-wrapper .marquee .headline,
  .popup-marquee .inner-wrapper .marquee .headline,
  .popup-marquee .marquee-wrapper .marquee .headline,
  .section-single-work-marquee .inner-wrapper .marquee .headline,
  .section-single-work-marquee .marquee-wrapper .marquee .headline {
    width: 80%;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%, -75%);
  }
}
@media all and (max-width: 700px) {
  .section-Marquee .inner-wrapper .marquee .headline,
  .section-Marquee .marquee-wrapper .marquee .headline,
  .popup-marquee .inner-wrapper .marquee .headline,
  .popup-marquee .marquee-wrapper .marquee .headline,
  .section-single-work-marquee .inner-wrapper .marquee .headline,
  .section-single-work-marquee .marquee-wrapper .marquee .headline {
    transform: translate(-50%, -85%);
  }
}
.section-Marquee .inner-wrapper .marquee .image-wrapper,
.section-Marquee .marquee-wrapper .marquee .image-wrapper,
.popup-marquee .inner-wrapper .marquee .image-wrapper,
.popup-marquee .marquee-wrapper .marquee .image-wrapper,
.section-single-work-marquee .inner-wrapper .marquee .image-wrapper,
.section-single-work-marquee .marquee-wrapper .marquee .image-wrapper {
  height: 0;
  padding-bottom: 37%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  overflow: hidden;
  width: 90%;
  max-width: 1920px;
}
@media all and (max-width: 900px) {
  .section-Marquee .inner-wrapper .marquee .image-wrapper,
  .section-Marquee .marquee-wrapper .marquee .image-wrapper,
  .popup-marquee .inner-wrapper .marquee .image-wrapper,
  .popup-marquee .marquee-wrapper .marquee .image-wrapper,
  .section-single-work-marquee .inner-wrapper .marquee .image-wrapper,
  .section-single-work-marquee .marquee-wrapper .marquee .image-wrapper {
    width: 90%;
    padding-bottom: 86%;
  }
}
.section-Marquee .inner-wrapper .marquee .image-wrapper .banner,
.section-Marquee .marquee-wrapper .marquee .image-wrapper .banner,
.popup-marquee .inner-wrapper .marquee .image-wrapper .banner,
.popup-marquee .marquee-wrapper .marquee .image-wrapper .banner,
.section-single-work-marquee .inner-wrapper .marquee .image-wrapper .banner,
.section-single-work-marquee .marquee-wrapper .marquee .image-wrapper .banner {
  width: 100vw;
  left: 50%;
  transform: translate(-50%, 60%);
}
@media all and (max-width: 900px) {
  .section-Marquee .inner-wrapper .marquee .image-wrapper .banner,
  .section-Marquee .marquee-wrapper .marquee .image-wrapper .banner,
  .popup-marquee .inner-wrapper .marquee .image-wrapper .banner,
  .popup-marquee .marquee-wrapper .marquee .image-wrapper .banner,
  .section-single-work-marquee .inner-wrapper .marquee .image-wrapper .banner,
  .section-single-work-marquee .marquee-wrapper .marquee .image-wrapper .banner {
    transform: translate(-50%, 55%);
  }
}
.section-Marquee .inner-wrapper .marquee .image-wrapper .banner .banner-item:before,
.section-Marquee .marquee-wrapper .marquee .image-wrapper .banner .banner-item:before,
.popup-marquee .inner-wrapper .marquee .image-wrapper .banner .banner-item:before,
.popup-marquee .marquee-wrapper .marquee .image-wrapper .banner .banner-item:before,
.section-single-work-marquee .inner-wrapper .marquee .image-wrapper .banner .banner-item:before,
.section-single-work-marquee .marquee-wrapper .marquee .image-wrapper .banner .banner-item:before {
  background-color: #ffffff;
}
.section-Marquee .inner-wrapper .marquee .banner,
.section-Marquee .marquee-wrapper .marquee .banner,
.popup-marquee .inner-wrapper .marquee .banner,
.popup-marquee .marquee-wrapper .marquee .banner,
.section-single-work-marquee .inner-wrapper .marquee .banner,
.section-single-work-marquee .marquee-wrapper .marquee .banner {
  overflow: hidden;
  width: 100%;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1;
}
.section-Marquee .inner-wrapper .marquee .banner .banner-inner,
.section-Marquee .marquee-wrapper .marquee .banner .banner-inner,
.popup-marquee .inner-wrapper .marquee .banner .banner-inner,
.popup-marquee .marquee-wrapper .marquee .banner .banner-inner,
.section-single-work-marquee .inner-wrapper .marquee .banner .banner-inner,
.section-single-work-marquee .marquee-wrapper .marquee .banner .banner-inner {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  transform: translate3d(100%, 0, 0);
  backface-visibility: hidden;
  position: relative;
  width: auto;
  height: auto;
  animation: bannerAnimation 50s linear infinite;
  animation-delay: 0.2s;
}
.section-Marquee .inner-wrapper .marquee .banner .banner-item,
.section-Marquee .marquee-wrapper .marquee .banner .banner-item,
.popup-marquee .inner-wrapper .marquee .banner .banner-item,
.popup-marquee .marquee-wrapper .marquee .banner .banner-item,
.section-single-work-marquee .inner-wrapper .marquee .banner .banner-item,
.section-single-work-marquee .marquee-wrapper .marquee .banner .banner-item {
  font-family: "Kontora", sans-serif;
  font-size: 4em;
  line-height: 1.125em;
  font-weight: 800;
  letter-spacing: 0;
  white-space: nowrap;
  display: inline-block;
  color: var(--link-color);
  padding-bottom: 0.15em;
}
.is-inverted .section-Marquee .inner-wrapper .marquee .banner .banner-item,
.is-inverted .section-Marquee .marquee-wrapper .marquee .banner .banner-item,
.is-inverted .popup-marquee .inner-wrapper .marquee .banner .banner-item,
.is-inverted .popup-marquee .marquee-wrapper .marquee .banner .banner-item,
.is-inverted .section-single-work-marquee .inner-wrapper .marquee .banner .banner-item,
.is-inverted .section-single-work-marquee .marquee-wrapper .marquee .banner .banner-item {
  color: #ffffff;
}
.section-Marquee .inner-wrapper .marquee .banner .divider,
.section-Marquee .marquee-wrapper .marquee .banner .divider,
.popup-marquee .inner-wrapper .marquee .banner .divider,
.popup-marquee .marquee-wrapper .marquee .banner .divider,
.section-single-work-marquee .inner-wrapper .marquee .banner .divider,
.section-single-work-marquee .marquee-wrapper .marquee .banner .divider {
  width: 3.5em;
  margin: 0 1.5em;
  height: 6px;
  position: relative;
  display: inline-block;
  box-sizing: content-box;
  background-color: #222222;
}
.is-inverted .section-Marquee .inner-wrapper .marquee .banner .divider,
.is-inverted .section-Marquee .marquee-wrapper .marquee .banner .divider,
.is-inverted .popup-marquee .inner-wrapper .marquee .banner .divider,
.is-inverted .popup-marquee .marquee-wrapper .marquee .banner .divider,
.is-inverted .section-single-work-marquee .inner-wrapper .marquee .banner .divider,
.is-inverted .section-single-work-marquee .marquee-wrapper .marquee .banner .divider {
  background-color: #ffffff;
}
.section-Marquee .inner-wrapper .marquee .banner .divider.divider-no-line,
.section-Marquee .marquee-wrapper .marquee .banner .divider.divider-no-line,
.popup-marquee .inner-wrapper .marquee .banner .divider.divider-no-line,
.popup-marquee .marquee-wrapper .marquee .banner .divider.divider-no-line,
.section-single-work-marquee .inner-wrapper .marquee .banner .divider.divider-no-line,
.section-single-work-marquee .marquee-wrapper .marquee .banner .divider.divider-no-line {
  background-color: transparent;
  width: 1em;
  margin: 0;
}
.section-Marquee .inner-wrapper .marquee .banner-one .banner-inner,
.section-Marquee .marquee-wrapper .marquee .banner-one .banner-inner,
.popup-marquee .inner-wrapper .marquee .banner-one .banner-inner,
.popup-marquee .marquee-wrapper .marquee .banner-one .banner-inner,
.section-single-work-marquee .inner-wrapper .marquee .banner-one .banner-inner,
.section-single-work-marquee .marquee-wrapper .marquee .banner-one .banner-inner {
  animation-name: bannerAnimation;
  animation-duration: 200s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
.section-Marquee .inner-wrapper .marquee .banner-more,
.section-Marquee .marquee-wrapper .marquee .banner-more,
.popup-marquee .inner-wrapper .marquee .banner-more,
.popup-marquee .marquee-wrapper .marquee .banner-more,
.section-single-work-marquee .inner-wrapper .marquee .banner-more,
.section-single-work-marquee .marquee-wrapper .marquee .banner-more {
  margin: 0 auto;
}
.section-Marquee .inner-wrapper .marquee .banner-more .banner-inner,
.section-Marquee .marquee-wrapper .marquee .banner-more .banner-inner,
.popup-marquee .inner-wrapper .marquee .banner-more .banner-inner,
.popup-marquee .marquee-wrapper .marquee .banner-more .banner-inner,
.section-single-work-marquee .inner-wrapper .marquee .banner-more .banner-inner,
.section-single-work-marquee .marquee-wrapper .marquee .banner-more .banner-inner {
  animation-name: bannerAnimation;
  animation-duration: 120s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
.section-Marquee .inner-wrapper .marquee-simple,
.section-Marquee .marquee-wrapper .marquee-simple,
.popup-marquee .inner-wrapper .marquee-simple,
.popup-marquee .marquee-wrapper .marquee-simple,
.section-single-work-marquee .inner-wrapper .marquee-simple,
.section-single-work-marquee .marquee-wrapper .marquee-simple {
  height: auto;
  position: relative;
  display: block;
}
.section-Marquee .inner-wrapper .marquee-simple .banner,
.section-Marquee .marquee-wrapper .marquee-simple .banner,
.popup-marquee .inner-wrapper .marquee-simple .banner,
.popup-marquee .marquee-wrapper .marquee-simple .banner,
.section-single-work-marquee .inner-wrapper .marquee-simple .banner,
.section-single-work-marquee .marquee-wrapper .marquee-simple .banner {
  position: relative;
}
.section-Marquee .inner-wrapper .marquee-simple .banner .banner-inner .banner-item,
.section-Marquee .marquee-wrapper .marquee-simple .banner .banner-inner .banner-item,
.popup-marquee .inner-wrapper .marquee-simple .banner .banner-inner .banner-item,
.popup-marquee .marquee-wrapper .marquee-simple .banner .banner-inner .banner-item,
.section-single-work-marquee .inner-wrapper .marquee-simple .banner .banner-inner .banner-item,
.section-single-work-marquee .marquee-wrapper .marquee-simple .banner .banner-inner .banner-item {
  color: #222222;
}
.is-inverted .section-Marquee .inner-wrapper .marquee-simple .banner .banner-inner .banner-item,
.is-inverted .section-Marquee .marquee-wrapper .marquee-simple .banner .banner-inner .banner-item,
.is-inverted .popup-marquee .inner-wrapper .marquee-simple .banner .banner-inner .banner-item,
.is-inverted .popup-marquee .marquee-wrapper .marquee-simple .banner .banner-inner .banner-item,
.is-inverted .section-single-work-marquee .inner-wrapper .marquee-simple .banner .banner-inner .banner-item,
.is-inverted .section-single-work-marquee .marquee-wrapper .marquee-simple .banner .banner-inner .banner-item {
  color: #ffffff;
}
.is-inverted .section-Marquee .inner-wrapper .marquee-simple .banner .banner-inner .banner-item:before,
.is-inverted .section-Marquee .marquee-wrapper .marquee-simple .banner .banner-inner .banner-item:before,
.is-inverted .popup-marquee .inner-wrapper .marquee-simple .banner .banner-inner .banner-item:before,
.is-inverted .popup-marquee .marquee-wrapper .marquee-simple .banner .banner-inner .banner-item:before,
.is-inverted .section-single-work-marquee .inner-wrapper .marquee-simple .banner .banner-inner .banner-item:before,
.is-inverted .section-single-work-marquee .marquee-wrapper .marquee-simple .banner .banner-inner .banner-item:before {
  background-color: #ffffff;
}
.section-Marquee .inner-wrapper .marquee-fancy .headline,
.section-Marquee .marquee-wrapper .marquee-fancy .headline,
.popup-marquee .inner-wrapper .marquee-fancy .headline,
.popup-marquee .marquee-wrapper .marquee-fancy .headline,
.section-single-work-marquee .inner-wrapper .marquee-fancy .headline,
.section-single-work-marquee .marquee-wrapper .marquee-fancy .headline {
  top: 49%;
}
@media all and (max-width: 900px) {
  .section-Marquee .inner-wrapper .marquee-fancy .headline,
  .section-Marquee .marquee-wrapper .marquee-fancy .headline,
  .popup-marquee .inner-wrapper .marquee-fancy .headline,
  .popup-marquee .marquee-wrapper .marquee-fancy .headline,
  .section-single-work-marquee .inner-wrapper .marquee-fancy .headline,
  .section-single-work-marquee .marquee-wrapper .marquee-fancy .headline {
    top: 48%;
  }
}
.section-Marquee .inner-wrapper .marquee-fancy .banner,
.section-Marquee .marquee-wrapper .marquee-fancy .banner,
.popup-marquee .inner-wrapper .marquee-fancy .banner,
.popup-marquee .marquee-wrapper .marquee-fancy .banner,
.section-single-work-marquee .inner-wrapper .marquee-fancy .banner,
.section-single-work-marquee .marquee-wrapper .marquee-fancy .banner {
  transform: translate(0, 60%);
  top: 49%;
}
@media all and (max-width: 900px) {
  .section-Marquee .inner-wrapper .marquee-fancy .banner,
  .section-Marquee .marquee-wrapper .marquee-fancy .banner,
  .popup-marquee .inner-wrapper .marquee-fancy .banner,
  .popup-marquee .marquee-wrapper .marquee-fancy .banner,
  .section-single-work-marquee .inner-wrapper .marquee-fancy .banner,
  .section-single-work-marquee .marquee-wrapper .marquee-fancy .banner {
    transform: translate(0, 55%);
  }
}
@keyframes bannerAnimation {
  0% {
    transform: translate3d(100vw, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
.section-Onlinetools .inner-wrapper .tool-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: 13em;
  width: 100%;
}
.section-Onlinetools .inner-wrapper .tool-item:first-child {
  margin-top: 0;
}
.section-Onlinetools .inner-wrapper .tool-item:nth-child(2n) .box-1 {
  order: 2;
  margin-right: 0;
  margin-left: 5%;
}
.section-Onlinetools .inner-wrapper .tool-item:nth-child(2n) .box-2 {
  order: 1;
  margin-left: 0;
  margin-right: 5%;
}
@media all and (max-width: 900px) {
  .section-Onlinetools .inner-wrapper .tool-item {
    flex-direction: column;
    margin-top: 8em;
  }
  .section-Onlinetools .inner-wrapper .tool-item:nth-child(2n) .box-1 {
    margin: 0;
    order: 1;
  }
  .section-Onlinetools .inner-wrapper .tool-item:nth-child(2n) .box-2 {
    margin: 0;
    order: 2;
    margin-top: 25px;
  }
}
.section-Onlinetools .inner-wrapper .tool-item .box {
  width: 45%;
}
@media all and (max-width: 900px) {
  .section-Onlinetools .inner-wrapper .tool-item .box {
    width: 100%;
  }
}
.section-Onlinetools .inner-wrapper .tool-item .box .image-wrapper {
  width: 100%;
  padding-bottom: 87%;
}
.section-Onlinetools .inner-wrapper .tool-item .box .title {
  font-family: "Kontora", sans-serif;
  font-size: 4em;
  line-height: 1.125em;
  font-weight: 800;
  letter-spacing: 0;
}
.isIE11 .section-Onlinetools .inner-wrapper .tool-item .box .title {
  width: 100%;
}
.section-Onlinetools .inner-wrapper .tool-item .box .text {
  font-family: "Futura", sans-serif;
  font-size: 2.5em;
  line-height: 1.4em;
  font-weight: 500;
  letter-spacing: 0;
  margin-top: 1em;
}
.isIE11 .section-Onlinetools .inner-wrapper .tool-item .box .text {
  width: 100%;
}
@media all and (max-width: 900px) {
  .section-Onlinetools .inner-wrapper .tool-item .box .text {
    margin-top: 0.8em;
  }
}
.section-Onlinetools .inner-wrapper .tool-item .box .call-to-action {
  margin-top: 1em;
}
.section-Onlinetools .inner-wrapper .tool-item .box-1 {
  margin-right: 5%;
}
@media all and (max-width: 900px) {
  .section-Onlinetools .inner-wrapper .tool-item .box-1 {
    margin: 0;
    order: 1;
  }
}
.section-Onlinetools .inner-wrapper .tool-item .box-2 {
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
@media all and (max-width: 900px) {
  .section-Onlinetools .inner-wrapper .tool-item .box-2 {
    margin: 0;
    order: 2;
    margin-top: 25px;
  }
}

.tools-template-default .nav {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease-in-out;
}

.section-single-tools {
  position: absolute;
  top: 0;
}

.section-HeadlineGradient .inner-wrapper {
  position: relative;
}
.section-HeadlineGradient .inner-wrapper .headline {
  font-family: "Kontora", sans-serif;
  font-size: 7em;
  line-height: 1.14em;
  font-weight: 800;
  letter-spacing: 0;
}
@media all and (max-width: 900px) {
  .section-HeadlineGradient .inner-wrapper .headline {
    font-size: 5em;
  }
}
.isIE11 .section-HeadlineGradient .inner-wrapper .headline {
  height: auto;
  position: relative;
  width: 100%;
  color: #ffffff !important;
}

.section-Services {
  z-index: 1;
}
.section-Services .inner-wrapper {
  width: 90%;
  max-width: 1204px;
  max-width: 1980px;
  max-width: 1920px;
}
@media all and (min-width: 900px) {
  .section-Services .inner-wrapper {
    width: 92.7%;
  }
}
@media all and (min-width: 1600px) {
  .section-Services .inner-wrapper {
    max-width: 1980px;
  }
}
.section-Services .inner-wrapper .services-grid {
  width: 100%;
}
.section-Services .inner-wrapper .services-grid .grid-sizer,
.section-Services .inner-wrapper .services-grid .service-item {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  height: auto;
  margin-bottom: 5%;
  min-height: unset;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4em;
}
@media all and (min-width: 900px) {
  .section-Services .inner-wrapper .services-grid .grid-sizer,
  .section-Services .inner-wrapper .services-grid .service-item {
    width: 47%;
    margin-right: 1.5%;
    margin-left: 1.5%;
    margin-bottom: 3%;
    min-height: 450px;
  }
}
@media all and (min-width: 1250px) {
  .section-Services .inner-wrapper .services-grid .grid-sizer,
  .section-Services .inner-wrapper .services-grid .service-item {
    width: 30.33%;
    min-height: 500px;
  }
}
@media all and (min-width: 1600px) {
  .section-Services .inner-wrapper .services-grid .grid-sizer,
  .section-Services .inner-wrapper .services-grid .service-item {
    width: 22%;
    min-height: 600px;
  }
}
@media all and (min-width: 1920px) {
  .section-Services .inner-wrapper .services-grid .grid-sizer,
  .section-Services .inner-wrapper .services-grid .service-item {
    min-height: 500px;
  }
}
.section-Services .inner-wrapper .services-grid .grid-sizer .service-inner,
.section-Services .inner-wrapper .services-grid .service-item .service-inner {
  padding: 3em 3.5em;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}
.section-Services .inner-wrapper .services-grid .grid-sizer .title,
.section-Services .inner-wrapper .services-grid .grid-sizer .subtext,
.section-Services .inner-wrapper .services-grid .grid-sizer .call-to-action,
.section-Services .inner-wrapper .services-grid .service-item .title,
.section-Services .inner-wrapper .services-grid .service-item .subtext,
.section-Services .inner-wrapper .services-grid .service-item .call-to-action {
  color: #222222;
  transition: color 0.5s ease-in-out;
}
.section-Services .inner-wrapper .services-grid .grid-sizer .title,
.section-Services .inner-wrapper .services-grid .service-item .title {
  font-family: "Kontora", sans-serif;
  font-size: 4em;
  line-height: 1.125em;
  font-weight: 800;
  letter-spacing: 0;
}
.isIE11 .section-Services .inner-wrapper .services-grid .grid-sizer .title,
.isIE11 .section-Services .inner-wrapper .services-grid .service-item .title {
  position: relative;
  width: 100%;
}
.section-Services .inner-wrapper .services-grid .grid-sizer .subtext,
.section-Services .inner-wrapper .services-grid .service-item .subtext {
  font-family: "Futura", sans-serif;
  font-size: 2.5em;
  line-height: 1.4em;
  font-weight: 500;
  letter-spacing: 0;
  margin-top: 1em;
}
.isIE11 .section-Services .inner-wrapper .services-grid .grid-sizer .subtext,
.isIE11 .section-Services .inner-wrapper .services-grid .service-item .subtext {
  position: relative;
  width: 100%;
}
.section-Services .inner-wrapper .services-grid .grid-sizer .call-to-action,
.section-Services .inner-wrapper .services-grid .service-item .call-to-action {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  z-index: 1;
}
.section-Services .inner-wrapper .services-grid .grid-sizer:hover .title,
.section-Services .inner-wrapper .services-grid .grid-sizer:hover .subtext,
.section-Services .inner-wrapper .services-grid .grid-sizer:hover .call-to-action,
.section-Services .inner-wrapper .services-grid .service-item:hover .title,
.section-Services .inner-wrapper .services-grid .service-item:hover .subtext,
.section-Services .inner-wrapper .services-grid .service-item:hover .call-to-action {
  color: #ffffff;
}
.section-Services .inner-wrapper .services-grid .grid-sizer:hover .call-to-action:before,
.section-Services .inner-wrapper .services-grid .service-item:hover .call-to-action:before {
  background-color: #ffffff;
}

.section-single-services {
  position: absolute;
  top: 0;
}

.section-single-special-offers {
  position: absolute;
  top: 0;
}

.services-template-default .nav {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease-in-out;
}

.special-offers-template-default .nav {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease-in-out;
}

.section-Team .inner-wrapper {
  width: 92.5%;
  max-width: 1980px;
}
@media all and (max-width: 900px) {
  .section-Team .inner-wrapper {
    width: 90%;
  }
}
.section-Team .inner-wrapper .team-grid .grid-sizer,
.section-Team .inner-wrapper .team-grid .member-item {
  width: 30%;
  margin-right: 1.5%;
  margin-left: 1.5%;
  position: relative;
  display: inline-block;
  margin-bottom: 5.4em;
}
@media all and (max-width: 1600px) {
  .section-Team .inner-wrapper .team-grid .grid-sizer,
  .section-Team .inner-wrapper .team-grid .member-item {
    width: 47%;
    margin-right: 1.5%;
    margin-left: 1.5%;
  }
  .section-Team .inner-wrapper .team-grid .grid-sizer:last-child,
  .section-Team .inner-wrapper .team-grid .member-item:last-child {
    margin-bottom: 0;
  }
}
@media all and (max-width: 900px) {
  .section-Team .inner-wrapper .team-grid .grid-sizer,
  .section-Team .inner-wrapper .team-grid .member-item {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .section-Team .inner-wrapper .team-grid .grid-sizer:last-child,
  .section-Team .inner-wrapper .team-grid .member-item:last-child {
    margin-bottom: 0;
  }
}
.section-Team .inner-wrapper .team-grid .grid-sizer .image-wrapper,
.section-Team .inner-wrapper .team-grid .member-item .image-wrapper {
  width: 100%;
}
.section-Team .inner-wrapper .team-grid .grid-sizer .name,
.section-Team .inner-wrapper .team-grid .member-item .name {
  font-family: "Kontora", sans-serif;
  font-size: 2.5em;
  line-height: 1.2em;
  font-weight: 800;
  letter-spacing: 0;
  margin-top: 0.6em;
}
.section-Text .inner-wrapper .text {
  font-family: "Futura", sans-serif;
  font-size: 4em;
  line-height: 1.35em;
  font-weight: 500;
  letter-spacing: 0;
}
@media all and (max-width: 900px) {
  .section-Text .inner-wrapper .text {
    font-size: 3em;
  }
}

.section-TextSmall .inner-wrapper .text {
  font-family: "Futura", sans-serif;
  font-size: 2.5em;
  line-height: 1.4em;
  font-weight: 500;
  letter-spacing: 0;
}
.section-ThreeColumns .inner-wrapper.width-full-padding {
  width: 89.8%;
}
@media all and (max-width: 900px) {
  .section-ThreeColumns .inner-wrapper.width-full-padding {
    width: 94.5%;
    margin: 0 0 0 auto;
  }
}

.section-TwoImages .inner-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.section-TwoImages .inner-wrapper .box {
  width: 47%;
}
@media all and (max-width: 900px) {
  .section-TwoImages .inner-wrapper .box {
    width: 100%;
  }
}
.section-TwoImages .inner-wrapper .box-1 {
  margin-right: 2.5%;
}
@media all and (max-width: 900px) {
  .section-TwoImages .inner-wrapper .box-1 {
    margin-right: 0;
  }
}
.section-TwoImages .inner-wrapper .box-2 {
  margin-left: 2.5%;
}
@media all and (max-width: 900px) {
  .section-TwoImages .inner-wrapper .box-2 {
    margin-left: 0;
    margin-top: 2.5em;
  }
}
.section-TwoImages .inner-wrapper .box .image-wrapper {
  width: 100%;
  padding-bottom: 51%;
}

.section-Text .inner-wrapper .text {
  font-family: "Futura", sans-serif;
  font-size: 4em;
  line-height: 1.35em;
  font-weight: 500;
  letter-spacing: 0;
}
@media all and (max-width: 900px) {
  .section-Text .inner-wrapper .text {
    font-size: 3em;
  }
}

.site-footer {
  padding: 0 0 11.5em 0;
  transition: all 0.33s ease-in-out;
  overflow: hidden;
  left: 0;
}
@media all and (max-width: 645px) {
  .site-footer {
    padding: 0 0 14.2em 0;
  }
}
.site-footer.is-inverted {
  background-color: #222222;
  color: #ffffff;
}
.site-footer.is-inverted .divider {
  background-color: #ffffff !important;
}
.single-services .site-footer, .single-tools .site-footer {
  display: none;
}
.site-footer.is-hidden {
  opacity: 0 !important;
  display: none;
  visibility: hidden !important;
}
.site-footer .inner-wrapper {
  display: flex;
  align-items: flex-start;
}
@media all and (max-width: 900px) {
  .site-footer .inner-wrapper {
    flex-direction: column;
  }
}
@media all and (max-width: 900px) {
  .isIE11 .site-footer .inner-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
  }
}
.site-footer .inner-wrapper .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  transition: all 0.25s ease-in-out;
}
.site-footer .inner-wrapper .box .info {
  font-family: "Kontora", sans-serif;
  font-size: 6em;
  line-height: 1.25em;
  font-weight: 800;
  letter-spacing: 0;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: auto;
  opacity: 0;
  pointer-events: none;
  transition: all 0.33s ease-in-out;
}
@media all and (max-width: 900px) {
  .site-footer .inner-wrapper .box .info {
    font-size: 5em;
  }
}
@media all and (max-width: 1024px) {
  .site-footer .inner-wrapper .box .info {
    font-size: 5em;
  }
}
.site-footer .inner-wrapper .box .info.is-active {
  opacity: 1;
  pointer-events: auto;
}
.site-footer .inner-wrapper .box .info-headline {
  font-family: "Futura", sans-serif;
  font-size: 4em;
  line-height: 1.35em;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.35em;
  margin-bottom: 0.57em;
}
@media all and (max-width: 900px) {
  .site-footer .inner-wrapper .box .info-headline {
    font-size: 3em;
  }
}
.site-footer .inner-wrapper .box .info-headline.is-active:before {
  transform: translate3d(0, 0, 0);
}
.site-footer .inner-wrapper .box .info-headline:last-child {
  margin-bottom: 0;
  margin-top: 0.1em;
}
@media all and (max-width: 1024px) {
  .site-footer .inner-wrapper .box .info-headline {
    font-size: 3em;
  }
  .site-footer .inner-wrapper .box .info-headline:last-child {
    margin-top: 0;
  }
}
.site-footer .inner-wrapper .box .info-wrapper {
  display: inline;
}
.site-footer .inner-wrapper .box .info-wrapper .headline {
  font-family: "Futura", sans-serif;
  font-size: 2.5em;
  line-height: 1.4em;
  font-weight: 800;
  letter-spacing: 0;
  display: inline-flex;
}
.site-footer .inner-wrapper .box .info-wrapper .headline .link:before, .site-footer .inner-wrapper .box .info-wrapper .headline .set-p-with-hover a:before, .set-p-with-hover .site-footer .inner-wrapper .box .info-wrapper .headline a:before {
  height: 0.15em;
}
.site-footer .inner-wrapper .box .info-wrapper .divider {
  display: inline-block;
  transform: translate(0, -0.6em);
}
.site-footer .inner-wrapper .box .info-wrapper .text {
  font-family: "Futura", sans-serif;
  font-size: 2.5em;
  line-height: 1.4em;
  font-weight: 500;
  letter-spacing: 0;
  display: inline;
}
.site-footer .inner-wrapper .box .info-wrapper .text p {
  display: inline;
}
.site-footer .inner-wrapper .box .nav-wrapper {
  margin-top: 3.2em;
  margin-bottom: 0.4em;
}
.site-footer .inner-wrapper .box .nav-wrapper .menu-item {
  font-family: "Kontora", sans-serif;
  font-size: 2em;
  line-height: 1.25em;
  font-weight: 400;
  letter-spacing: 0;
  display: inline-block;
  margin-right: 3em;
}
.site-footer .inner-wrapper .box .nav-wrapper .menu-item:last-child {
  margin-right: 0;
}
@media all and (max-width: 1024px) {
  .site-footer .inner-wrapper .box .nav-wrapper .menu-item {
    margin-right: 2em;
  }
  .site-footer .inner-wrapper .box .nav-wrapper .menu-item:last-child {
    margin-right: 0;
  }
}
@media all and (max-width: 900px) {
  .site-footer .inner-wrapper .box .nav-wrapper .menu-item {
    margin-top: 2em;
  }
}
@media all and (max-width: 900px) {
  .site-footer .inner-wrapper .box .nav-wrapper {
    margin-top: 0;
  }
}
.site-footer .inner-wrapper .box-1 {
  flex: 1;
  margin-right: 5%;
}
@media all and (max-width: 900px) {
  .isIE11 .site-footer .inner-wrapper .box-1 {
    width: 100%;
    flex: 1 100%;
  }
}
@media all and (max-width: 900px) {
  .site-footer .inner-wrapper .box-1 {
    flex: unset;
    width: 100%;
    position: relative;
    margin-right: 0;
  }
}
.site-footer .inner-wrapper .box-2 {
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1em 2% 0 1em;
}
@media all and (max-width: 900px) {
  .isIE11 .site-footer .inner-wrapper .box-2 {
    width: 100%;
    flex: 1 100%;
  }
}
@media all and (max-width: 900px) {
  .site-footer .inner-wrapper .box-2 {
    flex: unset;
    margin: 4.8em 0 0 0;
    max-width: 50em;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
}
.site-footer .inner-wrapper .box-3 {
  flex: 2.5;
  justify-content: flex-start;
  padding: 1em 0;
  margin-bottom: 0.7em;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 0;
  padding: 1em 0 0 0;
}
@media all and (max-width: 900px) {
  .isIE11 .site-footer .inner-wrapper .box-3 {
    width: 100%;
    flex: 1 100%;
  }
}
@media all and (max-width: 900px) {
  .site-footer .inner-wrapper .box-3 {
    flex: unset;
    width: 100%;
    margin-top: 2em;
    margin-bottom: 0;
  }
}
.site-footer .inner-wrapper .box-2-3 {
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
@media all and (max-width: 900px) {
  .isIE11 .site-footer .inner-wrapper .box-2-3 {
    width: 100%;
    flex: 1 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media all and (max-width: 900px) {
  .site-footer .inner-wrapper .box-2-3 {
    flex-direction: column;
  }
}

.cookiemsg {
  position: fixed;
  bottom: 0;
  padding: 3em 0;
  z-index: 4;
  width: 100%;
  background-color: #ffffff;
  transform: translate3d(0, 105%, 0);
  transition: all 0.33s ease-in-out;
}
.cookiemsg .inner-wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.cookiemsg .text {
  flex: 1;
}
.cookiemsg .text .text-inner {
  font-family: "Futura", sans-serif;
  font-size: 2em;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 0;
  display: inline;
}
.cookiemsg .text .text-inner .link, .cookiemsg .text .text-inner .set-p-with-hover a, .set-p-with-hover .cookiemsg .text .text-inner a {
  display: inline-flex;
}
.cookiemsg .text .text-inner .link:before, .cookiemsg .text .text-inner .set-p-with-hover a:before, .set-p-with-hover .cookiemsg .text .text-inner a:before {
  background-color: #222222;
}
.cookiemsg .text .button_text {
  font-family: "Futura", sans-serif;
  font-size: 2em;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 0;
  display: inline-flex;
}
.cookiemsg .text .button_text:before {
  background-color: #222222;
}
.cookiemsg .cross {
  margin-left: 2em;
}
.cookiemsg.is_visible {
  transform: translate3d(0, 0, 0);
}