/* ============================================================ *\

  #MAIN

  Author:          Timotheus Triebl
  Website:         timotheus-triebl.at
  Scss Creator by: Andrew Brandwood www.Brandwood.com

/* ============================================================ */

/**
* CONTENTS
*
*/




@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Scss/Fonts.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Scss/Normalize.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Scss/PreBase.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Scss/Vars.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Scss/_Abovethefold.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Scss/_Base.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Scss/_Nav.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Scss/__Work.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Templates/Content/ImgHeadline/_ImgHeadline.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Templates/Content/ImgTwocolumnstextLink/_ImgTwocolumnstextLink.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Templates/Content/ImgTwocolumnstextLinkBac/_ImgTwocolumnstextLink.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Templates/Content/Jobs/_Jobs.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Templates/Content/Marquee/_Marquee.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Templates/Content/Onlinetools/_Onlinetools.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Templates/Content/Quote/_Quote.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Templates/Content/HeadlineGradient/_HeadlineGradient.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Templates/Content/Services/_Services.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Templates/Content/SpecialOffers/_SpecialOffers.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Templates/Content/Team/_Team.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Templates/Content/Text_bac/_Text.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Templates/Content/TextSmall/_TextSmall.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Templates/Content/ThreeColumns/_ThreeColumns.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Templates/Content/TwoImages/_TwoImages.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Templates/Content/Text/_Text.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Templates/Html/FullFooter/Scss/_FullFooter.scss";
@import "/Users/timotheus/html_new/conversory/app/public/wp-content/themes/conversory/Assets/Templates/Html/FullHeader/Scss/_FullHeader.scss";