// Modul Element - HeadlineGradient

.section-HeadlineGradient {
	.inner-wrapper {
		position: relative;
		.headline {
			@include f-kontora-b-70;
			.isIE11 & {
				height: auto;
				position: relative;
				width: 100%;
				color: #ffffff !important;
			}
		}
	}
}