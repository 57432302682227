// Variables

// Colors
$white : #ffffff;
$black : #222222;
$black2 : #222222;

// Widths
// $width-standard: 117em;
$width-standard: 1170px;

// mqs
$mq-1024 : 1024px;
$bp1: 900px;
$bp1-min: 901px;

// sonstige
$borderWidth: 4px;
// $borderHoverColor: $white;

$menuHeight: 9em;
// $menuHeight: 7.3em;

$hover-line-height: 2px;

$max-width: 1920px;