// Modul Element - ThreeColumns

.section-ThreeColumns {
	.inner-wrapper {
		&.width-full-padding {
			// width: 91.5%;
		    width: 89.8%;
			@media all and (max-width: $bp1) {
				// width: 95%;
			    width: 94.5%;
	    		margin: 0 0 0 auto;
			}
		}
	}
}