// Normalilze

html {
	box-sizing: border-box;
}

* {

	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
	&:focus {
		outline: none;
	}

}


// ---------------------------------------------------------------------
// NORMALIZE
// ---------------------------------------------------------------------

h1,h2,h3,h4,h5,h6,
p,blockquote,pre,
dl,dd,ol,ul,
form,fieldset,legend,
table,th,td,caption,
hr {
	margin: 0;
	padding: 0;
}

ul{
	list-style-type: none;
}

abbr[title], dfn[title]{
	cursor: help;
}

u,ins {
	text-decoration: none;
}

ins {
	border-bottom: 1px solid;
}

img {
	font-style: italic;
}

label,
input,
textarea,
button,
select,
option{
	cursor: pointer;
}
	.input--text:active,
	.input--text:focus,
	textarea:active,
	textarea:focus{
		cursor: text;
		outline: none;
	}

// ---

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
	display: block;
}

audio,
canvas,
video {
	display: inline-block;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

[hidden] {
	display: none;
}

html {

	font-family: 'Arial, sans-serif';

	font-size: 10px;

	// overflow-y: scroll;
	min-height: 100%;
	-webkit-text-size-adjust: 100%; /* 2 */
	-ms-text-size-adjust: 100%; /* 2 */
}

body {
	margin: 0;
	font-size: 10px;
}

a {
	color: inherit;
	text-decoration: none;

}
a:focus {
	outline: none;
}

a:active,
a:hover {
	outline: 0;
}

abbr[title] {
	border-bottom: 1px dotted;
}

b,
strong {
	font-weight: bold;
}

dfn {
	font-style: italic;
}

hr {
	box-sizing: content-box;
	height: 0;
}

mark {
	background: #ff0;
	color: #000;
}

code,
kbd,
pre,
samp {
	font-family: monospace, serif;
	font-size: 1em;
}

pre {
	white-space: pre-wrap;
}

q {
	quotes: "\201C" "\201D" "\2018" "\2019";
}

small {
	font-size: 80%;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

img {
	border: 0;
}

svg:not(:root) {
	overflow: hidden;
}

figure {
	margin: 0;
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	border: 0; /* 1 */
	padding: 0; /* 2 */
}

button,
input,
select,
textarea {
	font-family: inherit; /* 1 */
	font-size: 100%; /* 2 */
	margin: 0; /* 3 */
}

button,
input {
	line-height: normal;
}

button,
select {
	text-transform: none;
}

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button; /* 2 */
	cursor: pointer; /* 3 */
}

button[disabled],
html input[disabled] {
	cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box; /* 1 */
	padding: 0; /* 2 */
}

input[type="search"] {
	-webkit-appearance: textfield;
	box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}
textarea {
	overflow: auto; /* 1 */
	vertical-align: top; /* 2 */
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
th {
	font-weight: normal;
}

// ---------------------------------------------------------------------
// RESET
// ---------------------------------------------------------------------

h1,h2,h3,h4,h5,h6 {
	// font-size: 1em;
}