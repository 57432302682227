// Modul Element - Jobs

.section-Jobs {
	.inner-wrapper {
		.tool-item {
			display: flex;
		    justify-content: space-between;
		    align-items: center;
		    cursor: pointer;
		    margin-top: 13em;
		    &:first-child {
		    	margin-top: 0;
		    }
		    &:nth-child(2n) {
		    	.box-1 {
		    		order: 2;
		    		margin-right: 0;
		    		margin-left: 5%;
		    	}
		    	.box-2 {
		    		order: 1;
		    		margin-left: 0;
		    		margin-right: 5%;
		    	}
		    }
			.box {
				width: 45%;
				// margin-right: 3%;
				.image-wrapper {
					width: 100%;
				    padding-bottom: 87%;
				}
				.title {
					@include f-kontora-b-40;
				}
				.text {
					@include f-futura-m-25;
					margin-top: 1em;

				}
				&-1 {
					margin-right: 5%;
				}
				&-2 {
					margin-left: 5%;
				}
			}
		}
	}
}