// Modul Element - Onlinetools

.section-Onlinetools {
	.inner-wrapper {
		.tool-item {
			display: flex;
		    justify-content: space-between;
		    align-items: center;
		    cursor: pointer;
		    margin-top: 13em;
		    width: 100%;
		    &:first-child {
		    	margin-top: 0;
		    }
		    &:nth-child(2n) {
		    	.box-1 {
		    		order: 2;
		    		margin-right: 0;
		    		margin-left: 5%;
		    	}
		    	.box-2 {
		    		order: 1;
		    		margin-left: 0;
		    		margin-right: 5%;
		    	}
		    }
		    @media all and (max-width: $bp1) {
			    flex-direction: column;
			    margin-top: 8em;
			    &:nth-child(2n) {
			    	.box-1 {
			    		margin: 0;
					    order: 1;
			    	}
			    	.box-2 {
			    		margin: 0;
					    order: 2;
					    margin-top: 25px;
			    	}
			    }
		    }
			.box {
				width: 45%;
				// margin-right: 3%;
				@media all and (max-width: $bp1) {
				    width: 100%;
			    }
				.image-wrapper {
					width: 100%;
				    padding-bottom: 87%;
				}
				.title {
					@include f-kontora-b-40;
					.isIE11 & {
						width: 100%;
					}
				}
				.text {
					@include f-futura-m-25;
					margin-top: 1em;
					.isIE11 & {
						width: 100%;
					}
					@media all and (max-width:$bp1) {
					    margin-top: 0.8em;
					}
				}
				.call-to-action {
					margin-top: 1em;
				}
				&-1 {
					margin-right: 5%;
					@media all and (max-width: $bp1) {
					    margin: 0;
					    order: 1;
				    }
				}
				&-2 {
					margin-left: 5%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					@media all and (max-width: $bp1) {
					    // margin: 0;
					    // order: 2;
					    // margin-top: 25px;

					    margin: 0;
					   	order: 2;
					   	margin-top: 25px;
				    }
				}
			}
		}
	}
}
.tools-template-default .nav {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;
}
.section-single-tools {
	position: absolute;
	top: 0;
}
.tools-content {
  //   .tool-popup {
	 //    position: fixed;
	 //    top: 0;
	 //    left: 0;
	 //    // transform: translate(-50%, -50%);
	 //    bottom: 0;
	 //    right: 0;
  //   	width: 100%;
  //   	height: 100vh;
  //   	// background-color: $black;
  //   	opacity: 0;
  //   	transform: translate3d(0,-50px,0);
  //   	transition: all 0.33s ease-in-out;
  //   	pointer-events: none;
		// z-index: 4;
		// color: $white;
		// overflow: auto;
		// padding-bottom: 10em;
  //   	&.is-open {
  //   		transform: translate3d(0,0,0);
  //   		opacity: 1;
  //   		pointer-events: auto;
  //   		@extend .fade-bg-moreblack;
  //   	}
  //   	.nav-fake {
		//     height: $menuHeight;
		//     position: relative;
		//     top: 0;
		//     padding-bottom: 1.5em;

		//     display: flex;
		//     justify-content: center;
		//     align-items: flex-end;

		//     .nav-fake-inner {
		// 	    display: flex;
		// 	    justify-content: space-between;
		// 	    align-items: center;
		// 	    width: 100%;
		//     }
		//     .cross {

		//     }
  //   	}
  //   	.inner-wrapper {
  //   		padding-bottom: 10em;
  //   		margin-top: 3em;
  //   		.service-title {
  //   			@include f-kontora-b-70;
  //   		}
  //   		.service-subtext {
  //   			@include f-futura-m-40;
		// 	    margin-top: 0.5em;
  //   		}
  //   		.service-text {
  //   			@include f-kontora-b-60;
		// 	    margin-top: 1.4em;
  //   		}
  //   		.work-wrapper {
		// 	    margin-top: 4em;
		// 	    .work-item {
		// 	    	color: $white;
		// 	    }
  //   		}
  //   	}
  //   }
}