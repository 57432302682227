// Modul Element - ImgHeadline

.section-ImgHeadline {
	.inner-wrapper {
		.image-wrapper {

		}
		.headline {
			@include f-kontora-b-70;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			color: $black;
			z-index: 1;
			.is-inverted & {
				color: $white;
				
			}
		}
	}
}