// Modul Element - Marquee

.section-Marquee,
.popup-marquee,
.section-single-work-marquee {
	overflow: hidden;
	z-index: 1;
	.inner-wrapper,
	.marquee-wrapper {
		&.width-full {
			max-width: unset;
		}
		.marquee {
			position: relative;
			// .text-wrapper {
			//     position: absolute;
			//     width: 100%;
			//     top: 50%;
			//     left: 0;
			//     transform: translate(0,-50%);
			//     z-index: 1;
			// }
			.headline {
				@include f-kontora-b-60;
				width: 100%;
				color: $white;
				text-align: center;

				position: absolute;
			    width: 100%;
			    top: 49%;
			    left: 0;
			    transform: translate(0,-90%);
			    z-index: 3;
			    @media all and (max-width: $bp1) {
			    	width: 80%;
				    margin: 0 auto;
				    left: 50%;
				    transform: translate(-50%,-75%);
				    // top: 25%;
			    }
			    @media all and (max-width: 700px) {
				    transform: translate(-50%,-85%);
				    // top: 48%;
			    }
			}
			// .banner-hider {
			// 	position: absolute;
			//     width: 10%;
			//     height: 100%;
			//     background-color: $white;
			//     z-index: 3;
			//     &-1 {
			//     	left: 0;
			//     }
			//     &-2 {
			//     	right: 0;
			//     }
			// }
			// .image-wrapper-wrapper {
			// 	width: 100%;
			// 	position: relative;
			//     display: flex;
   //  			justify-content: center;
   //  			.banner-hider-big {
   //  				visibility: hidden;
   //  				pointer-events: none;
   //  			}
			// 	.banner {
			// 		// z-index: 1;
			// 	 //    width: 125%;
			// 	 //    left: -12.5%;
			// 		.banner-inner {
			// 			// width: 100%;
			// 			.banner-item {
			// 				color: $white;
			// 			}
			// 		}
			// 	}
			// }
			.image-wrapper {
				// width: 80%;
				height: 0;
				padding-bottom: 37%;
				margin: 0 auto;
				position: relative;
				z-index: 2;
				overflow: hidden;
				width: 90%;
				// max-width: 1170px;
				max-width: $max-width;
				@media all and (max-width: $bp1) {
					width: 90%;
			    	padding-bottom: 86%;
				}
				.banner {
				    // width: 125%;
				    // left: -12.5%;
				    // width: 120%;
    				// left: -9.8%;

				    // width: 122.8%;
    				// left: -11.4%;

				    // width: 100vw;
    				// left: -17.6%;

				    width: 100vw;
    				// left: -15vw;
				    left: 50%;
    				transform: translate(-50%,60%);
					@media all and (max-width: 1315px) {
					 //    width: 111%;
						// left: -5.5%;
					}
					@media all and (max-width: $bp1) {
						// transform: translate(-50%,75%);
						transform: translate(-50%,55%);
					    // width: 118%;
    					// left: -5.8%;
					}
					.banner-item {
						&:before {
							background-color: $white;
						}
					}
				}
			}
			// .banner-wrapper { // second banner for image
			//     position: absolute;
			//     width: 100%;
			//     top: 0;
			//     left: 0;
			//     right: 0;
			//     bottom: 0;
			//     height: 100%;
			//     .banner {
			//     	.banner-inner {
			//     		.banner-item {
			//     			color: $white;
			//     		}
			//     	}
			//     }
			// }
			.banner {
				overflow: hidden;
				width: 100%;

				position: absolute;
			    width: 100%;
			    top: 50%;
			    left: 0;
			    // transform: translate(0,-50%);
				
			    z-index: 1;
				.banner-inner {
					display: inline-flex;
					justify-content: center;
					align-items: center;
					flex-wrap: nowrap;
				    transform: translate3d(100%, 0, 0);
				    // transform: translate3d(0, 0, 0);
				    backface-visibility: hidden;
				    position: relative;
				    width: auto;
				    height: auto;
				    animation: bannerAnimation 50s linear infinite;
				    animation-delay: 0.2s;
				}
				.banner-item {
					@include f-kontora-b-40;
					white-space: nowrap;
				    display: inline-block;
				    color: var(--link-color);
				    padding-bottom: 0.15em;
				    .is-inverted & {
				    	color: $white;
				    }
				    // text-transform: uppercase;
					// flex: 1;
				}
				.divider {
					width: 3.5em;
					margin: 0 1.5em;
					height: 6px;
					// flex: 1;
					position: relative;
					display: inline-block;
					box-sizing: content-box;
					// background-color: var(--link-color);
					background-color: $black;
					.is-inverted & {
						background-color: $white;
					}
					&.divider-no-line {
						background-color: transparent;
						// width: 2.5em;
						width: 1em;
    					margin: 0;
					}
				}
				&-one {
					.banner-inner {
						// animation: bannerAnimation 200s linear infinite;

						animation-name: bannerAnimation;
						animation-duration: 200s;
						animation-iteration-count: infinite;
						animation-timing-function: linear;
						animation-fill-mode: forwards;
					}
				}
				&-more {
					// max-width: $max-width; here change
					margin: 0 auto;
					.banner-inner {
						// animation: bannerAnimation 120s linear infinite;

						animation-name: bannerAnimation;
						animation-duration: 120s;
						animation-iteration-count: infinite;
						animation-timing-function: linear;
						animation-fill-mode: forwards;
					}
				}
			}
			&-simple {
			    height: auto;
			    position: relative;
			    display: block;
				.banner {
					position: relative;
					.banner-inner {
						.banner-item {
							color: $black;
							.is-inverted & {
								color: $white;
								&:before {
									background-color: $white;
								}
							}
						}
					    // animation: bannerAnimation 120s linear infinite;
					}
				}
			}
			&-fancy {
				.headline {
					top: 49%;
					@media all and (max-width: $bp1) {
					    top: 48%;
				    }
				}
				.banner {
					// transform: translate(0,150%);
					transform: translate(0,60%);
					top: 49%;
					@media all and (max-width: $bp1) {
				        // transform: translate(0, 75%);
				        transform: translate(0, 55%);
				    }
					.banner-inner {
					}
				}
			}
		}
	}
}

@keyframes bannerAnimation {
	0%   {
        transform: translate3d(100vw, 0, 0);
	}
	100% {
        transform:translate3d(-100%, 0, 0);
	}
}