// Modul Element - ImgTwocolumnstextLink

// $bp1: 900px;
.section-ImgTwocolumnstextLink {
	max-width: 1920px;
	margin: 0 auto;
	width: 90%;
	&.has-image {
		width: 100%;
	}
	.inner-wrapper {
		@media all and (max-width: $bp1) {
			display: flex;
		    flex-direction: column;
		}
		.rows {
			position: relative;
			// z-index: 1;
		    // padding: 10% 0;
	     //    min-height: 550px;
		    display: flex;
		    z-index: 2;
		    flex-direction: column;
		    justify-content: center;
			// top: 50%;
		 //    left: 50%;
		 //    transform: translate(-50%, -50%);
		 	.is-inverted & {
				color: $white !important;
				.link.link-hover-line {
					&:before {
						background-color: $white !important;
					}
				}
			}
		 	@media all and (max-width: $bp1) {
				justify-content: flex-start;
			}
			width: 100%;
			max-width: unset;
		 	&.has-image {
		 		padding: 10% 0;
			 	min-height: 550px;


				max-width: 1920px;
				width: 90%;
			 	.is-inverted & {
					color: inherit !important;
				}
			 	@media all and (max-width: $bp1) {
					margin-top: 0;
					padding: 8em 0;
				    order: 2;
				    min-height: unset;
				}
				.row {
					.link {
						&:before {
							background-color: $white;
						}
					}
				}
		 	}
		 	&.has-image.image-mobile-small {
			 	@media all and (max-width: $bp1) {
					padding: 0;
					// margin-top: -6%;
					// margin-top: -15%;
					// margin-top: -7em;
					// margin-top: 2em;
					margin-top: 5.2em;
				    order: 2;
				    margin-bottom: 8em;
				}
				.row {
					.link {
						&:before {
							background-color: $black;
						}
					}
				}
		 	}
			.row {
			    display: flex;
    			justify-content: flex-start;
				&-2,
				&-3 {
					// margin-top: 2.5em;
					// margin-top: 5em;
					@media all and (max-width: $bp1) {
					    // margin-top: 2.5em;
					}
				}
				&-3 {
					// margin-top: 2.5em;
					margin-top: 5em;
					@media all and (max-width: $bp1) {
					    // margin-top: 2.5em;
					}
				}
				&.image-mobile-small {
					@media all and (max-width: $bp1) {
					    // color: $black;
					}
				}
				&-2 {
					@media all and (max-width: $bp1) {
						flex-direction: column;
					}
				}
				.cell {
					width: 50%;
					@media all and (max-width: $bp1) {
						width: 100%;
					}
				}
				.headline {
					// @include f-kontora-b-40;
					// margin-bottom: 1.25em;
					margin-bottom: 1.3em;
					&.half_margin_headline {
						margin-bottom: 30px;
						@media all and (max-width: $bp1) {
							margin-bottom: 0.5em;
						}
					}
				}
				.text {
					@include f-futura-m-25;
					&-left {
						margin-right: 2.5%;
						&.css_column {
							column-count: 2;
							column-gap: 5%;
							width: 100%;
							margin-right: 0;
							@media all and (max-width: $bp1) {
								column-count:unset;
								column-gap: unset;
							}
						}
						@media all and (max-width: $bp1) {
							margin-right: 0;
						}
					}
					&-right {
						margin-left: 2.5%;
						&.visibily-hidden {
						    opacity: 0;
						    height: 0;
						    position: absolute;
						}
						@media all and (max-width: $bp1) {
							margin-left: 0;
							margin-top: 1em;
						}
					}
				}
				.link {
					@include f-futura-m-20;
					color: inherit;
					@media all and (max-width: $bp1) {
						&:before {

					    	// background-color: $black;
						}
					}
				}
			}
		}
		.absolute-wrapper {
		    position: absolute;
		    top: 0;
		    right: 0;
		    bottom: 0;
		    left: 0;
			&.image-mobile-small {
			    @media all and (max-width: $bp1) {
				    position: relative;
				    order: 1;
				    width: 100%;
				    right: auto;
				    bottom: auto;
				}
				.image-wrapper {
					@media all and (max-width: $bp1) {
				        height: 0;
		    			padding-bottom: 50%;
		    		}
				}
			}
		    &.img-half {
		    	width: 60%;
		    	left: auto;
		    	@media all and (max-width: $bp1) {
					width: 100%;
				}
		    	.image-wrapper {
					@media all and (max-width: $bp1) {
		    			padding-bottom: 60%;
		    		}
		    		img {
		    			// object-position: top left;
						// object-fit: cover;
		    			object-position: top right;
		    			@media all and (max-width: $bp1) {
							object-position: bottom right;
						}
		    		}
		    	}
		    }
		    &.whitefont {
		    	&:before {
		    		content: "";
		    		width: 100%;
		    		height: 100%;
		    		position: absolute;
		    		top: 0;
		    		right: 0;
		    		bottom: 0;
		    		left: 0;
		    		background-color: #222222;
		    		opacity: 0.5;
				    z-index: 1;
		    	}
		    }
			.image-wrapper {
				width: 100%;
				height: 100%;
				img {
					// object-fit: contain;
				}
			}
		}
	}
}