// PREBASE -- Soon Base and Base will be "newname"

.image-wrapper {
	position: relative;
	.image {
		position: absolute;
		left:0;
		top:0;
		right:0;
		bottom:0;
		width:100%;
		height:100%;
		object-fit:cover;

		z-index: 1;
		font-family: 'object-fit: cover;';
		&-contain {
			object-fit:contain;
			font-family: 'object-fit: contain;';
		}
		&.image-relative,
		&.is-relative {
			position: relative;
			width: 100%;
			height: auto;
			left: auto;
			right: auto;
			top: auto;
			bottom: auto;
		}
	}
}
button {
	border: 0;
	background: transparent;
    padding: 0;
    margin: 0;
}

// #cookie-popup {
//     position: fixed;
//     bottom: 0;
//     height: auto;
//     width: 100%;
//     z-index: 99;
//     display: flex;
//     transform: translate3d(0, 100%, 0);
//     opacity: 0;
//     transition: transform 0.35s ease-in-out;
//     .inner-wrapper {
// 	    display: flex;
// 	    position: relative;
// 	    margin:0 auto;
// 	    justify-content: center;
// 	    align-items: center;
// 	    .text {
// 			a {
// 			    text-decoration: underline;
// 	    		display: inline;
// 	    	}
// 	    }
// 	    .button {
// 		    // margin-left: 2em;
// 	    }
    	
//     }
//     &.is_visible {
//     	transform: translate3d(0, 0, 0);
//     	opacity: 1;
//     }
// }