// BASE -- Navigation and Global Stuff here



body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	// margin-top: 1.3em;
    @media all and (max-width: $bp1 ) {
    	// margin-top: 0;
	}
}
.site {
	height: auto;
	width: 100%;
	position: relative;
	margin-top: $menuHeight;
    background-color: $white;
    z-index: 1;
    // transition: all 0.6s ease-out;

	&.has_animation {
		backface-visibility: hidden;
	    transform: translate3d(0,0,0);
	    &.is-hidden {
	    	transform: translate3d(105%,0,0);
	    }
	}

    &.is-inverted {
    	color: $white;
    	background-color: $black2;
    }
    .single.single-tools &,
    .single.single-special-offers &,
    .single.single-services & {
    	margin-top: 0;
  //   	@media all and (max-width: $bp1 ) {
		// 	padding-top: 0 !important;
		// 	margin-top: 0;
		// }
    }
    @media all and (max-width: $bp1 ) {
		padding-top: 0 !important;
		margin-top: 0;
	}
    // z-index: 1;
}
.section {
	display: block;
	width: 100%;
	position: relative;

	// background-color: var(--var-bg-color);
}
.site .section {
	&:first-child {
		// margin-top: 15em;

		margin-top: 13em;
		@media all and (max-width: $bp1) {
			// margin-top: 7em;
			margin-top: 0;
		}
	}
}

.single.single-services {
	.nav {
		pointer-events: none;
		.burger {
			pointer-events: none;
		}
	}
}
.single.single-tools {
	.nav {
		pointer-events: none;
		.burger {
			pointer-events: none;
		}
	}
}
.single.single-special-offers {
	.nav {
		pointer-events: none;
		.burger {
			pointer-events: none;
		}
	}
}
.page-id-547,
.page-id-561 {
	.site .header-reveal .section {
		&:first-child {
			// margin-top: 15em;

			margin-top: 15em;
			@media all and (max-width: $bp1) {
				// margin-top: 7em;
			    margin-top: 1em;
			}
		}
	}
}
/*==============================
=            common            =
==============================*/
.width {
	margin: 0 auto;
	&-standard {
		// max-width: $width-standard;
		max-width: $max-width;
		width: 90%;
	}
	&-full {
		width: 100%;
		max-width: $max-width;
	}
	&-full-padding {
		width: 90%;
		max-width: $max-width;
	}
}

br {
	&.mobile {
		display: none;
		@media all and (max-width: $bp1) {
			display: block;
		}
	}
}

// h2,
// h3,
// h4 {
// 	margin-top: 1em;
// 	// &:first-of-type {
// 	// 	margin-top: 0;
// 	// }
// }
ul {
	margin-top: 0.5em;
	// &:first-of-type {
	// 	margin-top: 0;
	// }
	li {
		@include f-futura-m-25;
		padding-left: 1em;
		position: relative;
		font-size: 25px;
		&:before {
			content: "";
			width: 6px;
			height: 6px;
		    top: 0.6em;
			border-radius: 100%;
			background-color: $black;
			position: absolute;
			left: 0;
		}
	}
}
p {
	margin-top: 1em;
	&:first-of-type {
		margin-top: 0;
	}
}

.header-reveal {
	z-index: -1;
	width: 100%;
	@media all and (max-width: $bp1 ) {
		position: relative !important;
		padding-top: 8em;
	}
}
.page-id-531 {
	.header-reveal {
		@media all and (max-width: $bp1 ) {

			// padding-top: 0;
		}
	}
}
.content-reveal {
	width: 100%;
	// padding-bottom: 10em;
	padding-bottom: 6em;
	padding-top: 4em;
	margin-top: 0;
	.section {
		&:first-child {
	        // padding-top: 6em;
	        margin-top: 0;
	        .single-services &,
	        .single-special-offers &,
	        .single-tools & {
	        	margin-top: 0;
	        	padding-top: 0;
	        }
					.page-id-599 &,
					.page-id-3 & {
						margin-top: inherit;
					}
	        @media all and (max-width: $bp1) {
	        	padding-top: 0;
	        }
		}
		&.section-Marquee {
			&:first-child {
		        padding-top: 0;
		        margin-top: 15em;
			}	
		}
	}
    @media all and (max-width: $bp1) {
    	// padding-bottom: 7.3em;
    	padding-top: 0;
    	margin-top: 8em;
    	.single.single-tools &,
    	.single.single-special-offers &,
	    .single.single-services & {
	    	margin-top: 0;
	    }
    }
}
.header-reveal+.content-reveal {
    // padding-top: 10em;
    // padding-top: 7em;
    padding-top: 8em;
    margin-top: 0;
    @media all and (max-width: $bp1) {
    	padding-top: 4em;
    	margin-top: 0;
    }
    &.padding-top {
		&-none {
			padding-top: 0;
		}
		&-small {
			padding-top: 3em;
		}
		&-standard {
			padding-top: 6.5em;
			@media all and (max-width: $bp1) {
				padding-top: 6.5em;
			}
		}
		&-own {
			&.padding-top-own-0 {
				padding-top: 0;
			}
			&.padding-top-own-5 {
				padding-top: 1em;
			}
			&.padding-top-own-10 {
				padding-top: 2em;
			}
			&.padding-top-own-15 {
				padding-top: 3em;
			}
			&.padding-top-own-20 {
				padding-top: 4em;
			}
			&.padding-top-own-25 {
				padding-top: 5em;
			}
			&.padding-top-own-30 {
				padding-top: 6em;
			}
			&.padding-top-own-35 {
				padding-top: 7em;
			}
			&.padding-top-own-40 {
				padding-top: 8em;
			}
			&.padding-top-own-45 {
				padding-top: 9em;
			}
			&.padding-top-own-50 {
				padding-top: 10em;
			}
			&.padding-top-own-55 {
				padding-top: 11em;
			}
			&.padding-top-own-60 {
				padding-top: 12em;
			}
			&.padding-top-own-65 {
				padding-top: 13em;
			}
			&.padding-top-own-70 {
				padding-top: 14em;
			}
			&.padding-top-own-75 {
				padding-top: 15em;
			}
			&.padding-top-own-80 {
				padding-top: 16em;
			}
			&.padding-top-own-85 {
				padding-top: 17em;
			}
			&.padding-top-own-90 {
				padding-top: 18em;
			}
			&.padding-top-own-95 {
				padding-top: 19em;
			}
			&.padding-top-own-100 {
				padding-top: 20em;
			}
			@media all and (max-width: $bp1) {
				&.padding-top-own-mob-0 {
					padding-top: 0;
				}
				&.padding-top-own-mob-5 {
					padding-top: 0.5em;
				}
				&.padding-top-own-mob-10 {
					padding-top: 1em;
				}
				&.padding-top-own-mob-15 {
					padding-top: 1.5em;
				}
				&.padding-top-own-mob-20 {
					padding-top: 2em;
				}
				&.padding-top-own-mob-25 {
					padding-top: 2.5em;
				}
				&.padding-top-own-mob-30 {
					padding-top: 3em;
				}
				&.padding-top-own-mob-35 {
					padding-top: 3.5em;
				}
				&.padding-top-own-mob-40 {
					padding-top: 4em;
				}
				&.padding-top-own-mob-45 {
					padding-top: 4.5em;
				}
				&.padding-top-own-mob-50 {
					padding-top: 5em;
				}
				&.padding-top-own-mob-55 {
					padding-top: 5.5em;
				}
				&.padding-top-own-mob-60 {
					padding-top: 6em;
				}
				&.padding-top-own-mob-65 {
					padding-top: 6.5em;
				}
				&.padding-top-own-mob-70 {
					padding-top: 7em;
				}
				&.padding-top-own-mob-75 {
					padding-top: 7.5em;
				}
				&.padding-top-own-mob-80 {
					padding-top: 8em;
				}
				&.padding-top-own-mob-85 {
					padding-top: 8.5em;
				}
				&.padding-top-own-mob-90 {
					padding-top: 9em;
				}
				&.padding-top-own-mob-95 {
					padding-top: 9.5em;
				}
				&.padding-top-own-mob-100 {
					padding-top: 10em;
				}
			}
		}
	}
	.section {
		&:first-child {
	        @media all and (max-width: $bp1) {
	        	// padding-top: 4em;
	        }
		}
	}

}

.call-to-action {
	@include f-futura-m-20;
}

.burger {
    // padding: 1em;
    padding: 1em 0 1em 1em;
    pointer-events: all;
    cursor: pointer;
	.burger-inner {
		position: relative;
		width: 30px;
		height: 15px;
		&:before,
		&:after {
			content: "";
			background-color: $black;
			position: absolute;
			width: 100%;
			height: 2px;
			left: 0;
			transition: all 0.25s ease-in-out;
			.is-inverted & {
				background-color: $white;
			}
		}
		&:before {
			top: 0;
		    transform-origin: 9px 0px;
		}
		&:after {
			bottom: 0;
		    transform-origin: 3px 0px;
		}
	}
	&.is-open {
		.burger-inner {
			&:before {
			    top: 0;
			    transform: rotate(45deg);
			}
			&:after {
			    bottom: 0;
			    transform: rotate(-45deg);
			}
		}
	}
}

// .page-template-Work .content-reveal .section:first-child {
// 	padding-top: 0;
// }

/*=========================
=            x            =
=========================*/

.cross {
	display: block;
	position: relative;
	width: 50px;
	height: 50px;
	cursor: pointer;

	.single & {
		width: 42px;
		height: 42px;
	}
	.single-tools & {
		width: 42px;
		height: 42px;
	}

	&.is-absolute {
		position: absolute;
	}
	&:before,
	&:after {
		content: "";
		background-color: $white;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%) rotate(45deg);
		transition: all 0.25s ease-in-out;
	}

	&:before {
		width: 100%;
		height: 2px;
	}

	&:after {
		width: 2px;
		height: 100%;
	}
	&:hover {
		&:before,
		&:after {
			transform: translate(-50%,-50%) rotate(225deg);
		}
	}
	&.cross-black {
		&:before,
		&:after {
			background-color: $black;
		}
	}
}


/*==================================
=            margin top            =
==================================*/

.set-p {
	--link-color: #222222;
	h1,h2,h3,h4,h5,p {
		margin-top: 1em;
		&:first-child {
			margin-top: 0;
		}
	}
	h3 {
		&:first-of-type {
			@media all and (max-width: $bp1) {
				margin-top:0.5em;
			}
		}
	}
	// old
	// a {
		// @extend .link;
		// @extend .link-hover-line;
		// @extend .link-hover-simple;
		// @extend .link-hover-line-inverted;
		// @extend .link-hover-parent;
		// padding-bottom: 0 !important;
		// display: inline;	
	// }
	// new
	
	a {
		text-decoration: underline;
		border-bottom:0;
		&:hover {
			text-decoration: none;
		}
		&:before {
			display: none;
		}
	}
	// &.section-single-work-item-twocolumns-text {
	// 	.css_column {
	// 		a {
	// 			text-decoration: underline;
	// 			border-bottom:0;
	// 			&:hover {
	// 				text-decoration: none;
	// 			}
	// 			&:before {
	// 				display: none;
	// 			}
	// 		}
	// 	}
	// }
}

.set-p-with-hover {
	--link-color: #222222;
	h1,h2,h3,h4,h5,p {
		margin-top: 1em;
		&:first-child {
			margin-top: 0;
		}
	}
	h3 {
		&:first-of-type {
			@media all and (max-width: $bp1) {
				margin-top:0.5em;
			}
		}
	}
	a {
		@extend .link;
		@extend .link-hover-line;
		// @extend .link-hover-line-small;
		@extend .link-hover-line-inverted;
		@extend .link-hover-parent;
		padding-bottom: 0 !important;
		display: inline-flex;

		// text-decoration: underline;
		// &:hover {
		// 	text-decoration: none;
		// }
	}
}

.margin-top {
	&-none {
		margin-top: 0;
	}
	&-small {
		margin-top: 3em;
	}
	&-standard {
		margin-top: 6.5em;
		@media all and (max-width: $bp1) {
			margin-top: 6.5em;
		}
	}
	&-own {
		&.margin-top-own-0 {
			margin-top: 0;
		}
		&.margin-top-own-5 {
			margin-top: 1em;
		}
		&.margin-top-own-10 {
			margin-top: 2em;
		}
		&.margin-top-own-15 {
			margin-top: 3em;
		}
		&.margin-top-own-20 {
			margin-top: 4em;
		}
		&.margin-top-own-25 {
			margin-top: 5em;
		}
		&.margin-top-own-30 {
			margin-top: 6em;
		}
		&.margin-top-own-35 {
			margin-top: 7em;
		}
		&.margin-top-own-40 {
			margin-top: 8em;
		}
		&.margin-top-own-45 {
			margin-top: 9em;
		}
		&.margin-top-own-50 {
			margin-top: 10em;
		}
		&.margin-top-own-55 {
			margin-top: 11em;
		}
		&.margin-top-own-60 {
			margin-top: 12em;
		}
		&.margin-top-own-65 {
			margin-top: 13em;
		}
		&.margin-top-own-70 {
			margin-top: 14em;
		}
		&.margin-top-own-75 {
			margin-top: 15em;
		}
		&.margin-top-own-80 {
			margin-top: 16em;
		}
		&.margin-top-own-85 {
			margin-top: 17em;
		}
		&.margin-top-own-90 {
			margin-top: 18em;
		}
		&.margin-top-own-95 {
			margin-top: 19em;
		}
		&.margin-top-own-100 {
			margin-top: 20em;
		}
		@media all and (max-width: $bp1) {
			&.margin-top-own-mob-0 {
				margin-top: 0;
			}
			&.margin-top-own-mob-5 {
				margin-top: 0.5em;
			}
			&.margin-top-own-mob-10 {
				margin-top: 1em;
			}
			&.margin-top-own-mob-15 {
				margin-top: 1.5em;
			}
			&.margin-top-own-mob-20 {
				margin-top: 2em;
			}
			&.margin-top-own-mob-25 {
				margin-top: 2.5em;
			}
			&.margin-top-own-mob-30 {
				margin-top: 3em;
			}
			&.margin-top-own-mob-35 {
				margin-top: 3.5em;
			}
			&.margin-top-own-mob-40 {
				margin-top: 4em;
			}
			&.margin-top-own-mob-45 {
				margin-top: 4.5em;
			}
			&.margin-top-own-mob-50 {
				margin-top: 5em;
			}
			&.margin-top-own-mob-55 {
				margin-top: 5.5em;
			}
			&.margin-top-own-mob-60 {
				margin-top: 6em;
			}
			&.margin-top-own-mob-65 {
				margin-top: 6.5em;
			}
			&.margin-top-own-mob-70 {
				margin-top: 7em;
			}
			&.margin-top-own-mob-75 {
				margin-top: 7.5em;
			}
			&.margin-top-own-mob-80 {
				margin-top: 8em;
			}
			&.margin-top-own-mob-85 {
				margin-top: 8.5em;
			}
			&.margin-top-own-mob-90 {
				margin-top: 9em;
			}
			&.margin-top-own-mob-95 {
				margin-top: 9.5em;
			}
			&.margin-top-own-mob-100 {
				margin-top: 10em;
			}
		}
	}
	.page-id-599 &,
	.page-id-3 & {
		&-own {
			&.margin-top-own-0 {
				padding-top: 0 !important;
			}
			&.margin-top-own-5 {
				padding-top: 1em !important;
			}
			&.margin-top-own-10 {
				padding-top: 2em !important;
			}
			&.margin-top-own-15 {
				padding-top: 3em !important;
			}
			&.margin-top-own-20 {
				padding-top: 4em !important;
			}
			&.margin-top-own-25 {
				padding-top: 5em !important;
			}
			&.margin-top-own-30 {
				padding-top: 6em !important;
			}
			&.margin-top-own-35 {
				padding-top: 7em !important;
			}
			&.margin-top-own-40 {
				padding-top: 8em !important;
			}
			&.margin-top-own-45 {
				padding-top: 9em !important;
			}
			&.margin-top-own-50 {
				padding-top: 10em !important;
			}
			&.margin-top-own-55 {
				padding-top: 11em !important;
			}
			&.margin-top-own-60 {
				padding-top: 12em !important;
			}
			&.margin-top-own-65 {
				padding-top: 13em !important;
			}
			&.margin-top-own-70 {
				padding-top: 14em !important;
			}
			&.margin-top-own-75 {
				padding-top: 15em !important;
			}
			&.margin-top-own-80 {
				padding-top: 16em !important;
			}
			&.margin-top-own-85 {
				padding-top: 17em !important;
			}
			&.margin-top-own-90 {
				padding-top: 18em !important;
			}
			&.margin-top-own-95 {
				padding-top: 19em !important;
			}
			&.margin-top-own-100 {
				padding-top: 20em !important;
			}
			@media all and (max-width: $bp1) {
				&.margin-top-own-mob-0 {
					padding-top: 0 !important;
				}
				&.margin-top-own-mob-5 {
					padding-top: 0.5em !important;
				}
				&.margin-top-own-mob-10 {
					padding-top: 1em !important;
				}
				&.margin-top-own-mob-15 {
					padding-top: 1.5em !important;
				}
				&.margin-top-own-mob-20 {
					padding-top: 2em !important;
				}
				&.margin-top-own-mob-25 {
					padding-top: 2.5em !important;
				}
				&.margin-top-own-mob-30 {
					padding-top: 3em !important;
				}
				&.margin-top-own-mob-35 {
					padding-top: 3.5em !important;
				}
				&.margin-top-own-mob-40 {
					padding-top: 4em !important;
				}
				&.margin-top-own-mob-45 {
					padding-top: 4.5em !important;
				}
				&.margin-top-own-mob-50 {
					padding-top: 5em !important;
				}
				&.margin-top-own-mob-55 {
					padding-top: 5.5em !important;
				}
				&.margin-top-own-mob-60 {
					padding-top: 6em !important;
				}
				&.margin-top-own-mob-65 {
					padding-top: 6.5em !important;
				}
				&.margin-top-own-mob-70 {
					padding-top: 7em !important;
				}
				&.margin-top-own-mob-75 {
					padding-top: 7.5em !important;
				}
				&.margin-top-own-mob-80 {
					padding-top: 8em !important;
				}
				&.margin-top-own-mob-85 {
					padding-top: 8.5em !important;
				}
				&.margin-top-own-mob-90 {
					padding-top: 9em !important;
				}
				&.margin-top-own-mob-95 {
					padding-top: 9.5em !important;
				}
				&.margin-top-own-mob-100 {
					padding-top: 10em !important;
				}
			}
		}
	}
}

/*=======================================
=            conversory logo            =
=======================================*/

// .conversory-logo.with-before {
// 	@include f-kontora-b-25;
// 	text-transform: uppercase;
// 	&:before {
// 		text-transform: uppercase;
// 		content: "CONVERSORY"
// 	}
// 	&-white {
// 		&:before {
// 			color: $white;
// 		}
// 	}
// }

// // first animation with outline
// .conversory-logo {
//     display: inherit;
// 	path {
// 	    stroke-dasharray: 415;
// 	    stroke-dashoffset: 833.3668823242188;
// 	    fill: black;
// 	    stroke: black;
// 	    .is-inverted & {
// 			fill: white;
// 	    	stroke: white;
// 	    }
// 	}
// 	&.has-animation {
// 		path {
// 			animation-name: animateStrokeLogo;
// 			animation-duration: 3s;
// 			animation-iteration-count: 1;
// 			animation-timing-function: ease;
// 			animation-fill-mode: forwards;
// 		}
// 	}
// }
// @keyframes animateStrokeLogo {
// 	0% {
// 		fill: black;
// 		stroke-dasharray: 415;
// 	}
// 	30% {
// 		fill: white;
// 	    stroke-dasharray: 467;
// 	}
// 	80% {
// 		fill: white;
// 	}
// 	95% {
// 		stroke-dasharray: 415;
// 	}
// 	100% {
// 		fill: black;
// 	}
// }

//is at above the fold
// .conversory-logo-lotti {
// 	display: inherit;
// 	svg {
// 		width: 19em;
// 		height: auto;
// 	}
// }

/*============================
=            link            =
============================*/
.link-hover-parent:not(.link-hover-line-handleHoverClass) {
	cursor: pointer;
	&:hover {
		&.link-hover-line,
		.link-hover-line {
			&:before {
				transform: translate3d(0, 0, 0);
			}
			&.link-hover-line-inverted {
				&:before {
					transform: translate3d(-105%, 0, 0);
				}
			}
		}
		&.link-hover-simple,
		.link-hover-simple {
		    border-bottom: .1em solid #222222;
		    // &:before {
		    // 	display: block;
		    // }
		    &.link-hover-line-inverted {
		    	border-bottom: 0;
		    }
		    &.link-hover-line-small {
		    	// border-bottom: .1em solid #222222;
		    }
		}
		&.link-hover-simple-very,
		&.link-hover-simple-very {
			text-decoration: underline;
			&.link-hover-line-inverted {
					text-decoration: none;
			}
		}

	}
}
.link-hover-line-handleHoverClass {
	&.is-active {
		&.link-hover-line,
		.link-hover-line {
			&:before {
				transform: translate3d(0, 0, 0);
			}
			&.link-hover-line-inverted {
				&:before {
					transform: translate3d(-105%, 0, 0);
				}
			}
		}
	}
}
.link {
	&.link-hover-line {
		position: relative;
		overflow: hidden !important;
		// padding-bottom: 3px;
		&:before {
			content: "";
			width: 100%;
			// height: $hover-line-height;
			height: .1em;
		    bottom: 0;
		    left: 0;
		    position: absolute;
		    transition: all 0.33s ease-in-out;
		    transform: translate3d(-105%, 0, 0);
			// background-color: $black;
			// background-color: attr(data-color color);
			// background-color: var(--link-color);
			background-color: $black;
			.is-inverted & {
				background-color: $white;
			}

		}
		&.link-hover-line-inverted {
			&:before {
				transform: translate3d(0, 0, 0);
			}
		}
		&.link-hover-line-bold {
			&:before {
				// height: 6px;
				height: .1em;
			}
		}
		&.link-hover-line-medium-bold {
			&:before {
				// height: 4px;
				height: .1em;
			}
		}
	}
	&.link-hover-simple {
		position: relative;
		overflow: hidden;
		padding-bottom: 0;
		&:before {
		    // transition: unset;
		    display: none;
		}
		&.link-hover-line-inverted {
	    	border-bottom: 0.1em solid #222222;
	    }
	}
	&.link-hover-simple-very {
		position: relative;
		overflow: hidden;
		padding-bottom: 0;
		text-decoration: none;
		&.link-hover-line-inverted {
			text-decoration: underline;
		}
	}
}


/*=============================
=            quote            =
=============================*/
.quote-wrapper {
	// padding: 10em 0;
	padding: 7.5% 0;
}
.quote-text-wrapper {
	width: 85%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	color: $white;
	.quote-text {
		@include f-futura-m-40;
	}
	.quote-author-wrapper {
	    display: flex;
	    justify-content: flex-start;
	    // align-items: center;
		align-items: flex-start;
		margin-top: 1.5em;
		.divider {
			// margin: 1.9em 2em 0 0;
			// margin: 1.7em 2em 0 0;
			margin: 1.5em 2em 0 0;
		}
		.quote-author {
			@include f-futura-m-25;
			flex: 1;
		}
	}
}


/*============================
=            work            =
============================*/

.work-wrapper {
	display: flex;
	flex-direction: column;

	/* enable Flickity by default */
	&:after {
	  content: 'flickity';
	  display: none; /* hide :after */
	}

	@media screen and ( min-width: $bp1-min ) {
	  /* disable Flickity for large devices */
	  .carousel:after {
	    content: '';
	  }
	}

	&-row {
		flex-direction: row;
		width: 100%;
		.flickity-viewport {
			width: 100%;
		}
		.work-item {
			// width: 31%;
			// width: 31%;
			position: relative;
			width: 31.1%;
			&:nth-child(3n+1) {
				margin-right: 3.33%;
				.single & {
					// margin-right: 0;
					margin-right: 3.33%;
				}
				.single-tools &,
				.single-services & {
					margin-right: 3.33%;
				}
			}
			&:nth-child(3n+2) {
				margin-right: 3.33%;
				.single & {
					// margin-right: 0;
					margin-right: 3.33%;
				}
				.single-tools &,
				.single-services & {
					margin-right: 3.33%;
				}
			}
			@media all and (max-width: $bp1) {
				width: 80%;
				height: auto;
				display: block;
				position: relative;
				// transform: scale(0.6) translateX(0);
				transform-origin: left center;
				transition: all 0.25s ease-in-out;
				width: 95%;
				.single & {
					// width: 100%;
					width: 95%;
					margin-right: 0;
				}
				.single-tools &,
				.single-services & {
					width: 95%;
				}
				// margin-right: 3% !important;
			}
			.image-wrapper {
				transition: all 0.25s ease-in-out;
				@media all and (max-width: $bp1) {
					transform: scale(0.7);
					transform-origin: left center;
					// transform: scale(1) ;
				}
			}
			&.is-selected {
				.image-wrapper {
					@media all and (max-width: $bp1) {
						transform: scale(1) ;
					}
				}
			}
			// &.is-previous {
			// 	@media all and (max-width: $bp1) {
			// 		width: 65%
			// 		transform-origin: left center;
			// 	}
			// }
			// &.is-next {
			// 	@media all and (max-width: $bp1) {
			// 		width: 65%;
			// 		transform-origin: left center;
			// 	}
			// }
		}
	}
	.work-item {
		overflow: hidden;
		.image-wrapper {
			width: 100%;
			padding-bottom: 57%;
			overflow: hidden;
		}
		.title {
			@include f-kontora-b-25;
			margin-top: .55em;
			text-transform: uppercase;
		}
		.subtitle {
			@include f-futura-m-20;
			// margin-top: 0.1em;
		}
		&.is_img_relative {
			.image-wrapper {
				padding-bottom: 0;
			}
		}
	}
}
// .home {
	.section-ThreeColumns, .work-wrapper {
		.work-item {
			video, .gif {
				position: absolute;
			}
		}
	}
	.section-Work {
		.section-ThreeColumns, .work-wrapper {
			.work-item {
				video, .gif {
					position: relative;
				}
			}
		}
	}
// }

/*====================================
=            border hover            =
====================================*/

.border-hover-parent {
	&:hover {
		.border-hover {
			&.all-after-another {
				.line {
					&-top {
						transition: all 0.5s ease-in;
			    	}
			    	&-right {
						transition: all 0.5s linear 0.5s;
			    	}
			    	&-bottom {
						transition: all 0.5s linear 1s;
			    	}
			    	&-left {
						transition: all 0.5s ease-out 1.5s;
			    	}
				}
			}
			&.two-two {
				.line {
					&-top,
			    	&-left {
						transition: all 0.5s ease-in;
			    	}

			    	&-right,
			    	&-bottom {
						transition: all 0.5s ease-out 0.5s;
			    	}
				}
				&.border-hover-inverted {
		    		.line {
						&-top,
				    	&-bottom {
				    		width: 0;
				    	}
				    	&-left,
				    	&-right {
				    		height: 0;
				    	}
				    	&-top,
				    	&-left {
							transition: all 0.5s ease-out 0.5s;
				    	}

				    	&-right,
				    	&-bottom {
							transition: all 0.5s ease-in;
				    	}
		    		}
		    	}
			}
			.line {
				&-top,
		    	&-bottom {
		    		width: 100%;
		    	}
		    	&-left,
		    	&-right {
		    		height: 100%;
		    	}

		    	
			}
		}
	}
}
.border-hover {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    &.two-two {
    	.line {
    		&-left {
    			top: 0;
    			bottom: auto;
    		}
    		&-bottom {
    			left: 0;
    			right: auto;
    		}

    		&-left,
    		&-top {
    			transition: all 0.5s ease-out 0.5s;
    		}
    		&-bottom,
    		&-right {
    			transition: all 0.5s ease-in;
    		}
    	}
    	&.border-hover-inverted {
    		.line {
    			&-left,
	    		&-top {
	    			transition: all 0.5s ease-in;
	    		}
	    		&-bottom,
	    		&-right {
	    			transition: all 0.5s ease-out 0.5s;
	    		}
    			&-top,
		    	&-bottom {
		    		width: 100%;
		    	}
		    	&-left,
		    	&-right {
		    		height: 100%;
		    	}
    		}
    	}
    }
    .line {
		// background-color: $borderHoverColor;
		// background-color: var(--var-border-bordercolor);
		background-color: $black;
		position: absolute;
		transition: all 1s ease-in-out;

		z-index: 1;
		.is-inverted & {
			background-color: $white;
		}
    	&-top,
    	&-bottom {
    		width: 0;
    		height: $borderWidth;
    	}
    	&-left,
    	&-right {
    		width: $borderWidth;
    		height: 0;
    	}

		&-top {
			top: 0;
			left: 0;
			// transition: all 0.5s ease-in;
    	}
    	&-right {
			top: 0;
			right: 0;
			// transition: all 0.5s linear 0.45s;
    	}
    	&-bottom {
			right: 0;
			bottom: 0;
			// transition: all 0.5s linear 0.90s;
    	}
    	&-left {
			left: 0;
			bottom: 0;
			// transition: all 0.5s ease-outs 1.35s;
    	}
    }
}

/*===================================
=            image hover            =
===================================*/

// .image-hover-parent {
// 	&:hover {
// 		.image-hover {
// 			&-zoom {
// 				.image {
// 					transform: scale(1.2);
// 				}
// 			}
// 		}
// 	}
// }
// .image-hover {
// 	overflow: hidden;
// 	&-zoom{
// 		.image {
// 			transition: all 1.5s ease-in-out;
// 			transform: scale(1);
// 		}
// 	}
// }

/*===============================
=            divider            =
===============================*/

.divider {
	width: 3.5em;
	margin: 0 1.5em;
	height: 4px;
	// flex: 1;
	position: relative;
	display: inline-block;
	box-sizing: content-box;
	background-color: var(--link-color);
	&.divider-line-small {
		height: 2px;
		width: 4em;
	}
	&.divider-line-bold {
		height: 6px;
	}
	&.didiver-line-small-width {
	    width: 1.4em;
	    @media all and (max-width: $bp1) {
	    	height: 3px;
	    }
	}
	&.didiver-small-margin {
		margin: 0 0.5em;
	}
	&.divider-no-margin-left {
		margin: 0 2em 0 0;
	}
}
/*==========================
=            js            =
==========================*/

.js_showServices {
	cursor: pointer;
}

/*=============================
=            popup            =
=============================*/

.popup {
    position: absolute;
    top: 0;
    left: 0;
    // transform: translate(-50%, -50%);
    bottom: 0;
    right: 0;
	width: 100%;
	height: 100vh;
	// background-color: $black;
	opacity: 0;
	// transform: translate3d(0,-50px,0);
	transition: all 0.33s ease-in-out;
	pointer-events: none;
	z-index: 4;
	color: $white;
	overflow: auto;
	padding-bottom: 10em;
	&.is-open {
		// transform: translate3d(0,0,0);
		opacity: 1;
		pointer-events: auto;
		@extend .fade-bg;
	}
	&.bg-no-fade {
		// filter: grayscale(100%);
		// filter: grayscale(100%) brightness(50%) contrast(2);
		&.is-open {
			animation: unset;
		}
		// .nav-fake,
		// .inner-wrapper {
		// 	filter: unset;
		// }
	}

	&.tool {
		.inner-wrapper {
			// padding-bottom: 20em;
			padding-bottom: 17em;
		}
	}
	// .isSafari &,
	.isIos & {
		transform: translate3d(0,0,0);
		opacity: 1;
		pointer-events: auto;
		@extend .fade-bg;
	}
	.popup-inner-wrapper {
	    position: absolute;
	    width: 100%;
	    height: auto;
	}
	.bg-img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		filter: grayscale(100%) brightness(50%) contrast(2);
		// .isSafari &,
		.isIos & {
			// display: none;
			background-attachment: scroll !important;
		}
	}
	// &.image-bg {

	// }
	.nav-fake {
	    // height: $menuHeight;
	    height: auto;
			margin-top: 34px;
	    position: relative;
	    top: 0;
	    padding-bottom: 1.5em;

	    display: flex;
	    justify-content: center;
	    align-items: flex-end;

		width: 100%;
		position: fixed;

		max-width: unset;
		z-index: 1;

		@media all and (max-width: $bp1) {
			margin-top: 34px;
		}
		&.is-scrolled {
			.nav-fake-inner {
				// transform: scale(1);
				width: 100%;
				// padding: 0 34px;
				// padding: 0 43px 0 34px;
				padding: 0 34px;
				@media all and (max-width: $bp1) {
					padding: 0 34px 0 4%;
				}
			}
			.cross {
				transform: scale(1);
			}
		}
	    .nav-fake-inner {
			// transform: scale(1.2);
			transition: all 0.5s ease;
			width: 90%;
			padding: 0 0;
			
			margin: 0 auto;

		    display: flex;
		    justify-content: space-between;
		    align-items: center;
			@media all and (max-width: $bp1) {
    	        width: 100%;
				// padding: 0 34px;
				padding: 0 34px 0 4%;
		    }
		    // width: 100%;
	    }
	    .cross {
			transform: scale(1.2);
			transition: all 0.5s ease;
			// top: 1em;
			@media all and (max-width: $bp1) {
    	        transform: scale(1);
		    }
	    }
	}
	.inner-wrapper {
		padding-bottom: 10em;
		// margin-top: 3em;
		margin-top: 12em;
		position: relative;
		.image-wrapper {
			margin-top: 5em;
			max-width: 1920px;
	        width: 90%;
			margin: 5.5em auto 0 auto;
			@media all and (max-width: $bp1) {
    	        margin: 2.7em auto 0 auto;
		    }
		}
		.work-wrapper {
		    margin-top: 4em;
	        max-width: 1920px;
	        width: 90%;
			margin: 4em auto 0 auto;
		    @media all and (max-width: $bp1) {
	    	    // width: 106%;
    	        width: 95%;
				margin: 4em 0 0 auto;
		    }
		    .work-item {
		    	color: $white;
		    	.image-wrapper {
					max-width: unset;
			        width: 100%;
					margin: 0;
					// @media all and (max-width: $bp1) {
			  //   	    // width: 106%;
		   //  	        width: 95%;
					// 	margin: 4em 0 0 auto;
				 //    }
				}
		    }
		}
	}
	&.is-popup-color-white {
	    background: #fff !important;
    	animation: unset !important;
		.cross {
			&:before,
			&:after {
			    background-color: #222;
			}
		}
		.inner-wrapper {
			color: #222;
			.work-wrapper .work-item {
				color: #222;
				.border-hover .line {
					    background-color: #222;
				}
			}
		}
		.popup-marquee .marquee-wrapper .marquee-simple .banner .banner-inner .banner-item {
			color: #222;
			&:before {
			    background-color: #222;
			}
		}
	}
}
		.popup-title {
			@include f-kontora-b-70;
			width: 90%;
			max-width: 1920px;
			margin: 0 auto;
		}
		.popup-subtext {
			@include f-futura-m-40;
		    // margin-top: 0.5em;
		    width: 90%;
			max-width: 1920px;
			margin: 0.5em auto 0 auto;
		}
		.popup-text {
			@include f-kontora-b-60;
		    // margin-top: 1.4em;
		    width: 90%;
			max-width: 1920px;
			// margin: 1.4em auto 0 auto;
			margin: 1em auto 0 auto;
			@media all and (max-width: $bp1) {
				margin: 1.4em auto 0 auto;
		    }

		}
		.popup-text_small {
			@include f-futura-m-25;
		    // margin-top: 1.4em;
		    width: 90%;
			max-width: 1920px;
			margin: 1.4em auto 0 auto;
		}

		.popup-two_columns {

			// font-family: "Futura",sans-serif;
		 //    font-size: 2.5em;
		 //    line-height: 1.4em;
		 //    font-weight: 500;
		 //    letter-spacing: 0;
			@include f-futura-m-25;
			// @include f-futura-m-20;
		    // margin-top: 1.4em;
		    width: 90%;
			max-width: 1920px;
			margin: 2.6em auto 0 auto;
			display: flex;
			.twoColumns-text {
				flex: 1;
				&:nth-child(1) {
			        margin-right: 5%;
				}
				@media all and (max-width: $bp1) {
					&:nth-child(1) {
				        margin-right: 0;
					}
					&:nth-child(2) {
					    // margin-top: 0.5em;
				        margin: 1.4em auto 0 auto;
				        width: 100%;
					}
			    }
			}
			@media all and (max-width: $bp1) {
				flex-direction: column;
				margin: 1.3em auto 0 auto;
				// .twoColumns-text-right {
				//     margin-top: 0.5em;
				// }
				// &:nth-child(2) {
				//     margin-top: 0.5em;
				// }
		    }
		}
		.popup-marquee {
			margin-top: 7em;
			width: 100%;
		}



/*====================================
=            font fade bg            =
====================================*/

.font-fade-bg {
    // background-clip: text;
    color: transparent !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    display: block;


	// animation: fadeBg 6s ease infinite;
	// background: linear-gradient(139deg, #000000, #b7b7b7);
	// background-size: 400% 400%;

    // @extend .fade-bg;
    // .isIos & {
    // 	color: ;
    // 	background: unset;
    // 	-webkit-background-clip: unset !important;
	   //  background-clip: unset !important;
	   //  -webkit-text-fill-color: unset !important;
    // }


	// background: linear-gradient(144deg, #222222, #222222, #ffffff);
	// background-size: 600% 600%;
	// animation: fadeBgNew 7s ease infinite;

background: linear-gradient(145deg, #222222, #b5b5b5, #222222);
background-size: 600% 600%;
animation: fadeBgNewNew 7s ease infinite;
}



/*=============================
=            shame            =
=============================*/

.page-id-2 {
	.content-reveal {
		.section-ImgTwocolumnstextLink {
			&:first-child {
				@media all and (max-width: $bp1) {
					padding-top: 4em;
			    }
			}
		}
	}
}


/*===============================
=            blendin            =
===============================*/
$blending-bp1: 645px;

.blendin {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 4;
    height: 4.5em;
    left: 0;
    transform: translate3d(0,0,0);
    transition: 0.25s ease-in-out;
    pointer-events: none;
    &.is-hidden {
    	transform: translate3d(0,105%,0);
    }
    @media all and (max-width: $blending-bp1) {
    	height: 10em;
    }
    .blendin-inner {
    	position: sticky;
    	bottom:0;
    	height: 100%;
    	width: 100%;
    	pointer-events: none;
    	a {
    		padding-top: 5px;
    	}
    }
    .blendin-item {
    	@include f-futura-m-20;
	    position: absolute;
    	width: 100%;
    	height: 100%;
    	display: flex;
    	justify-content: center;
    	align-items: center;
    	transform: translate3d(0, 0, 0);
		transition: all 0.25s ease-in-out 0.5s;
		pointer-events: all;
    	&.is-hidden {
			transition: all 0.25s ease-in-out;
    		transform: translate3d(0, 105%, 0);
    		pointer-events: none;
    	}
    	@media all and (max-width: $blending-bp1) {
	    	padding: 0 10%;
	    }
    } 
}

/*========================================
=            black background            =
========================================*/
.fade-bg {
	animation: fadeBg 18s ease infinite;
	background: linear-gradient(139deg, #222222, #b7b7b7);
	background-size: 400% 400%;
}

.fade-bg-moreblack {
	animation: fadeBgMoreblack 20s ease infinite;
	background: linear-gradient(139deg, #222222, #b7b7b7);
	background-size: 400% 400%;
}

/*========================================
=            white background            =
========================================*/

.fade-bg-white {
	// background: linear-gradient(140deg, #ffffff, #ffffff, #000000);
	background: linear-gradient(120deg, #222222, #ffffff, #ffffff);
	background-size: 400% 400%;

	cursor: pointer;
	transition: all 0.5s ease-in-out;
	animation-direction: reverse;
	background-position: bottom right;
	&:hover {
		animation-name: fadeBgWhiteToBlackOnce;
		// animation-duration: 1s;
		// animation-iteration-count: 1;

		animation-duration: 20s;
		animation-iteration-count: infinite;

		animation-direction: normal;
		animation-fill-mode: both;
	}
}

// .fade-bg-white {
// 	// background: linear-gradient(139deg, #fff, #fff);
// 	// transition: all 1s ease-in-out;
// 	position: relative;

// 	background-size: 400% 400%;
// 	background: linear-gradient(139deg, #000000, #b7b7b7);
// 	animation: fadeBg 10s ease infinite;
// 	// &:hover {
// 	// }
// }


/*==================================
=            animations            =
==================================*/

@keyframes fadeBg {
    0%{background-position:0% 4%}
    50%{background-position:100% 97%}
    100%{background-position:0% 4%}
}

@keyframes fadeBgNew {
    0%{background-position:0% 26%}
	50%{background-position:100% 75%}
	100%{background-position:0% 26%}
}

@keyframes fadeBgNewNew {
	0%{background-position:0% 7%}
	50%{background-position:100% 94%}
	100%{background-position:0% 7%}
}

@keyframes fadeBgMoreblack {
    0%{background-position:0% 4%}
    50%{background-position:20% 17%}
    100%{background-position:0% 4%}
}

// @keyframes fadeBgWhiteToBlackOnce {
//     from {background-position:0% 7%}
//     to {background-position:100% 94%}
// }

@keyframes fadeBgWhiteToBlackOnce {
    from {background-position:100% 94%}
    to {background-position:0% 7%}
}
@keyframes fadeBgWhiteToBlackOnce {
    0% {background-position:100% 94%}
    6% {background-position:0% 7%}
    40% {background-position:31% 19%}
    60% {background-position:12% 1%}
    86% {background-position:45% 35%}
    96% {background-position:12% 18%}
    100% {background-position:100% 94%}
}

// @keyframes fadeBgWhiteToBlackOnce {
//     0% {background-position:0% 7%}
//     6% {background-position:100% 94%}
//     60% {background-position:68% 85%}
//     96% {background-position:86% 94%}
//     100% {background-position:0% 7%}
// }


.js_siteHider {
	background-color: $white;
	&.is-inverted {
		background-color: #222222;
	}
}

.section-ThreeColumns .singl-work-inner-wrapper {
	width: 89.8%; 
	margin: 0 auto;
	max-width: 1920px;
	@media all and (max-width: $bp1) {
		width: 94.5%; 
		margin: 0 0 0 auto;
	}
}