// Modul Element - Services

$services-bp1: 1250px;
$services-bp-new: 1600px;
.section-Services {
	z-index: 1;
	.inner-wrapper {
		// max-width: $max-width;
        
        width: 90%;
        max-width: 1204px;
		max-width: 1980px;
		max-width: 1920px;
		// max-width: 1660px;
		@media all and (min-width: $bp1) {
			width: 92.7%;
		}
		@media all and (min-width: $services-bp-new) {
			// max-width: 1660px;
			max-width: 1980px;
		}
		.services-grid {
			width: 100%;
			.grid-sizer,
			.service-item {

				width: 100%;
				margin-right: 0;
				margin-left: 0;
				height: auto;






				// width: 30.33%;
				// height: auto;
				// margin-bottom: 3%;
				margin-bottom: 5%;
				min-height: unset;


				// margin-bottom: 2.6em;
				// margin-bottom: 1.8em;

				// margin-bottom: 3.6em;

				// margin-right: 18px;
                // margin-left: 18px;



			    // padding: 3em 3.5em;
			    position: relative;
			    // border: $borderWidth solid $black;
    			// display: inline-block;

			    display: inline-flex;
			    flex-direction: column;
			    align-items: flex-start;

				padding: 4em;


				@media all and (min-width: $bp1) {
					width: 47%;
					margin-right: 1.5%;
                	margin-left: 1.5%;
					margin-bottom: 3%;

					min-height: 450px;
				}
				@media all and (min-width: $services-bp1) {
					width: 30.33%;

					min-height: 500px;
				}
				@media all and (min-width: $services-bp-new) {
					width: 22%;
					min-height: 600px;
				}
				@media all and (min-width: 1920px) {
					// width: 22%;
					min-height: 500px;
				}

				// @media all and (max-width: $bp1) {
				// 	width: 100%;
				// 	margin-right: 0;
				// 	margin-left: 0;
				// 	height: auto;
				// }
				
				// @media all and (min-width: $bp1) and (max-width: $services-bp1) {
				// 	width: 47%;
				// 	// margin-right: 0;
				// 	// margin-left: 0;
				// 	// height: auto;
				// }
                // @media all and (max-width: $services-bp1) {
                //     width: 33.33%;
                //     width: 30.33%;
				// 	margin-right: 1.5%;
				// 	margin-left: 1.5%;
				// 	margin-bottom: 3%;
				// 	// height: 400px;
                // }
				// @media all and (min-width: $services-bp1) {
				// @media all and (min-width: 1025px) {
                //     width: 47%;
				// 	height: 400px;
                // }
				// @media all and (min-width: $services-bp-new) {
                //     // width: 47%;
				// 	width: 22%;
				// 	height: 600px;
                // }
                .service-inner {
                    padding: 3em 3.5em;
                    width: 100%;
                    height: 100%;
                    backface-visibility: hidden;
                }
    			.title,
    			.subtext,
    			.call-to-action {
					color: $black;
					transition: color 0.5s ease-in-out;
    			}
    			.title {
    				@include f-kontora-b-40;
                    .isIE11 & {
                        position: relative;
                        width: 100%;
                    }
    			}
    			.subtext {
					@include f-futura-m-25;
					margin-top: 1em;
                    .isIE11 & {
                        position: relative;
                        width: 100%;
                    }
    			}
    			.call-to-action {
    				margin-top: 1.5em;
    				margin-bottom: 1.5em;
    				z-index: 1;
    			}
    			&:hover {
    				// border: 0 solid $black;
    				.title,
    				.subtext,
    				.call-to-action {
    					color: $white;
    				}
    				.call-to-action {
    					&:before {
							background-color: $white;
    					}
    				}
    			}
			}
		}
	}
}
.section-single-services {
    position: absolute;
    top: 0;
}
.section-single-special-offers {
    position: absolute;
    top: 0;
}
.services-template-default .nav {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;
}
.special-offers-template-default .nav {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;
}
.services-content {
  //   .service-popup {
	 //    position: fixed;
	 //    top: 0;
	 //    left: 0;
	 //    // transform: translate(-50%, -50%);
	 //    bottom: 0;
	 //    right: 0;
  //   	width: 100%;
  //   	height: 100vh;
  //   	// background-color: $black;
  //   	opacity: 0;
  //   	transform: translate3d(0,-50px,0);
  //   	transition: all 0.33s ease-in-out;
  //   	pointer-events: none;
		// z-index: 4;
		// color: $white;
		// overflow: auto;
		// padding-bottom: 10em;
  //   	&.is-open {
  //   		transform: translate3d(0,0,0);
  //   		opacity: 1;
  //   		pointer-events: auto;
  //   		@extend .fade-bg-moreblack;
  //   	}
  //   	.nav-fake {
		//     height: $menuHeight;
		//     position: relative;
		//     top: 0;
		//     padding-bottom: 1.5em;

		//     display: flex;
		//     justify-content: center;
		//     align-items: flex-end;

		//     .nav-fake-inner {
		// 	    display: flex;
		// 	    justify-content: space-between;
		// 	    align-items: center;
		// 	    width: 100%;
		//     }
		//     .cross {

		//     }
  //   	}
  //   	.inner-wrapper {
  //   		padding-bottom: 10em;
  //   		margin-top: 3em;
  //   		.service-title {
  //   			@include f-kontora-b-70;
  //   		}
  //   		.service-subtext {
  //   			@include f-futura-m-40;
		// 	    margin-top: 0.5em;
  //   		}
  //   		.service-text {
  //   			@include f-kontora-b-60;
		// 	    margin-top: 1.4em;
  //   		}
  //   		.work-wrapper {
		// 	    margin-top: 4em;
		// 	    .work-item {
		// 	    	color: $white;
		// 	    }
  //   		}
  //   	}
  //   }
}