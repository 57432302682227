@import url("//hello.myfonts.net/count/3ad33e");
// @font-face {
// 	font-family: 'Futura';
// 	src: url('../Fonts/FuturaLT_Medium.eot?#iefix') format('embedded-opentype'),
//         url('../Fonts/FuturaLT_Medium.woff2') format('woff2'),
//         url('../Fonts/FuturaLT_Medium.woff') format('woff'),
//         url('../Fonts/FuturaLT_Medium.ttf') format('truetype'),
//         url('../Fonts/FuturaLT_Medium.svg#Futura') format('svg');
// 	font-weight:500;
//     font-style: normal;
//     font-display: swap;
// }

@font-face {
	font-family: 'Futura';
	src: url('../Fonts/3AD33E_0_0.eot?#iefix') format('embedded-opentype'),
        url('../Fonts/3AD33E_0_0.woff2') format('woff2'),
        url('../Fonts/3AD33E_0_0.woff') format('woff'),
        url('../Fonts/3AD33E_0_0.ttf') format('truetype');
	font-weight:500;
    font-style: normal;
    // font-display: swap;
}

// @font-face {
//     font-family: 'Futura';
//     src: url('../Fonts/FuturaLT-Bold.eot');
//     src: url('../Fonts/FuturaLT-Bold.eot?#iefix') format('embedded-opentype'),
//         url('../Fonts/FuturaLT-Bold.woff2') format('woff2'),
//         url('../Fonts/FuturaLT-Bold.woff') format('woff'),
//         url('../Fonts/FuturaLT-Bold.ttf') format('truetype'),
//         url('../Fonts/FuturaLT-Bold.svg#Futura') format('svg');
//     font-weight: 800;
//     font-style: normal;
//     font-display: swap;
// }

@font-face {
    font-family: 'Futura';
    src: url('../Fonts/3AD33E_1_0.eot');
    src: url('../Fonts/3AD33E_1_0.eot?#iefix') format('embedded-opentype'),
        url('../Fonts/3AD33E_1_0.woff2') format('woff2'),
        url('../Fonts/3AD33E_1_0.woff') format('woff'),
        url('../Fonts/3AD33E_1_0.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    // font-display: swap;
}

@font-face {
	font-family: 'Kontora';
	font-weight:800;
	src: url('../Fonts/Kontora_bold.woff') format('woff'); /* Pretty Modern Browsers */
	// font-display: swap;
}
@font-face {
	font-family: 'Kontora';
	font-weight:400;
	src: url('../Fonts/Kontora.woff') format('woff'); /* Pretty Modern Browsers */
	// font-display: swap;
}
@font-face {
	font-family: 'Kontora';
	font-weight: 100;
	src: url('../Fonts/Kontora_thin.woff') format('woff'); /* Pretty Modern Browsers */
	// font-display: swap;
}



$futura : 'Futura', sans-serif;
$kontora : 'Kontora', sans-serif;

$font-bp1: 900px;
// $font-bp2: 700px;


/*====================================
=            Kontora Bold            =
====================================*/

@mixin f-kontora-b-25 {
	font-family: $kontora;
	font-size: 2.5em;
	line-height: 1.2em;
	font-weight: 800;
	letter-spacing: 0;
	// @media all and (max-width:$mq-1024) {
	// // font-size:1.9em;
	// }
}
.h4 {
	@include f-kontora-b-25;
}
h4 {
	@include f-kontora-b-25;
	font-size: 25px;
}
@mixin f-kontora-b-40 {
	font-family: $kontora;
	font-size: 4em;
	line-height: 1.125em;
	font-weight: 800;
	letter-spacing: 0;
	// @media all and (max-width:$mq-1024) {
	// // font-size:1.9em;
	// }
}
.h3 {
	@include f-kontora-b-40;
}
h3 {
	@include f-kontora-b-40;
	font-size: 40px;
}

@mixin f-kontora-b-60 {
	font-family: $kontora;
	font-size: 6em;
	line-height: 1.25em;
	font-weight: 800;
	letter-spacing: 0;
	@media all and (max-width:$font-bp1) {
		font-size: 5em;
	}
}

.h2 {
	@include f-kontora-b-60;
}
h2 {
	@include f-kontora-b-60;
	font-size: 60px;
	@media all and (max-width:$font-bp1) {
		font-size:50px;
	}
}

@mixin f-kontora-b-70 {
	font-family: $kontora;
	font-size: 7em;
	line-height: 1.14em;
	font-weight: 800;
	letter-spacing: 0;
	@media all and (max-width:$font-bp1) {
		font-size:5em;
	}
}
.h1 {
	@include f-kontora-b-70;
}
h1 {
	@include f-kontora-b-70;
	font-size: 70px;
	@media all and (max-width:$font-bp1) {
		font-size:50px;
	}
}

/*=======================================
=            Kontora Regular            =
=======================================*/

@mixin f-kontora-r-20 {
	font-family: $kontora;
	font-size: 2em;
	line-height: 1.25em;
	font-weight: 400;
	letter-spacing: 0;
	@media all and (max-width:$mq-1024) {
	// font-size:1.9em;
	}
}


/*=====================================
=            Futura Medium            =
=====================================*/

@mixin f-futura-m-20 {
	font-family: $futura;
	font-size: 2em;
	line-height: 1.5em;
	font-weight: 500;
	letter-spacing: 0;
	@media all and (max-width:$mq-1024) {
	// font-size:1.9em;
	}
}

@mixin f-futura-m-25 {
	font-family: $futura;
	font-size: 2.5em;
	line-height: 1.4em;
	font-weight: 500;
	letter-spacing: 0;
	@media all and (max-width:$mq-1024) {
	// font-size:1.9em;
	}
}

@mixin f-futura-m-40 {
	font-family: $futura;
	font-size: 4em;
	// line-height: 1.25em;
	line-height: 1.35em;
	font-weight: 500;
	letter-spacing: 0;
	@media all and (max-width:$font-bp1) {
		font-size:3em;
	}
}

/*====================================
=            futurua bold            =
====================================*/

@mixin f-futura-b-25 {
	font-family: $futura;
	font-size: 2.5em;
	line-height: 1.4em;
	font-weight: 800;
	letter-spacing: 0;
	@media all and (max-width:$mq-1024) {
	// font-size:1.9em;
	}
}
