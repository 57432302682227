// Nav -- All about nav

nav {
    width: 100%;
    height: $menuHeight;
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: flex-end;
    // padding-bottom: 1.5em;
    background-color: $white;
    z-index: 3;
    // padding-bottom: 3px;
    padding-bottom: 2px;
    transition: all 0.5s ease;
    opacity: 1;

	// position: sticky;
 //    height: 7.3em;
 //    max-height: 0;
 //    overflow: visible;
    &.is-locked {
		pointer-events: none !important;
	}
	
	.single-services &,
	.single-tools & {
		display: none;
	}
	&.is-hidden {
		opacity: 0;
		pointer-events: none;
	}
	&.is-inverted {
		background-color: $black2;
		color: $white;
		height: 8em;
		.nav-inner-wrapper {
			// width: 100%;
			// padding: 0 34px;
			position: relative;
			width: 1920px;
			padding: 0;
			@media all and (max-width: 2130px) {
				width: 90%;
				padding: 0;
			}
			path,
			polygon {
				.isIE11 & {
					fill: $white;
				}
			}
			.active-line-wrapper {
				.active-line {
					background-color: $white;
				}
			}
			.nav-wrapper {
				background-color: $black2;
			}
		}
	}
	&.is-scrolled {
		height: 8em;
		.nav-inner-wrapper {
			// max-width: 100%;
			width: 100%;
			padding: 0 34px;
			@media all and (max-width: 2130px) {
				width: 100%;
				padding: 0 34px;
			}
			// @media all and (max-width: 1024px) {
				
			// }
			@media all and (max-width: $bp1) {
				width: 90%;
				padding: 0;
			}
		}
		.conversory-logo {
			// transform: translate3d(0,0,0) scale(1) !important;
			transform: scale(1) !important;
		}
		.nav-wrapper {
			// transform: translate3d(0,0,0) scale(1) !important;
			transform: scale(1) !important;
		}
		.burger {
		// 	transform: translate3d(0,0,0) scale(1) !important;
			transform: scale(1) !important;
		}
	}
	&.is-open {
		.conversory-logo {
			transform: scale(1) !important;
		}
		.nav-wrapper {
			transform: scale(1) !important;
		}
		.burger {
			transform: scale(1) !important;
		}
	}
	@media all and ( max-width: $bp1) {
		height: 8em;
		position: fixed;
		padding-bottom: 0;
		
		// max-height: unset;
		// overflow: auto;
	}
	.isIE11 & {
		justify-content: flex-start;
		// @media all and ( max-width: $bp1) {
		// }
	}
	// s
	.nav-inner-wrapper {
	    display: flex;
	    justify-content: space-between;
	    align-items: flex-end;
	    position: relative;


	    height: 100%;

	    transition: all 0.33s ease-in-out;
    	opacity: 1;
		// max-width: $max-width;
		max-width: unset;
		// width: 95%;
		width: 100%;
		padding: 0 35px;




	    &.is-hidden {
	    	opacity: 0;
	    }

		width: 1920px;
		padding: 0;
		@media all and (max-width: 2130px) {
			width: 90%;
			padding: 0;
		}
		@media all and (max-width: 1024px) {
	    	
	    }
	    @media all and (max-width: $bp1) {
	    	align-items: center;
			// width: 90%;
			// padding: 0;
	    }
	    .conversory-logo {
    	    // padding-bottom: 22px;
    	    padding-bottom: 26px;
    	    z-index: 1;

			transform-origin: left center;
			transition: transform 0.5s ease;
			will-change: auto;
			
			// transform: translate3d(0px, 0px, 0px) scale(1.15);
			transform: scale(1.15);
			@media all and (max-width: 1920px) {
				// transform: translate3d(22%, 0px, 0px) scale(1.15);
				transform: scale(1.15);
		    }
			@media all and (max-width: 1024px) {
				// transform: translate3d(10px, 0px, 0px) scale(1.15);
				transform: scale(1.15);
		    }
    	    @media all and (max-width: $bp1) {
		    	// padding-bottom: 15px;
		    	padding-bottom: 0;
				// transform: translate3d(20px, 0px, 0px) scale(1.2);
				transform: scale(1);
		    }
	    }
	    .nav-wrapper {
	    	position: relative;
		    height: auto;
		    display: flex;

		    height: 100%;
    		align-items: flex-end;

			transform-origin: right center;
			transition: transform 0.5s ease;
			will-change: auto;
			
			// transform: translate3d(0px, 0px, 0px) scale(1.15);
			transform: scale(1.15);
			@media all and (max-width: 1920px) {
				// transform: translate3d(-22%, 0px, 0px) scale(1.15);
				transform: scale(1.15);
		    }
			@media all and (max-width: 1024px) {
				// transform: translate3d(-10px, 0px, 0px) scale(1.15);
				transform: scale(1.15);
		    }
		    @media all and (max-width: $bp1) {
				// transform: translate3d(-20px, 0px, 0px) scale(1.2);
				// transform: scale(1.2);
				transform: scale(1);
		    	opacity: 0;
	    	    position: fixed;
			    // top: auto;
			    top: 0;
			    right: 0;
			    bottom: 0;
			    left: 0;
				transition: all 0.33s ease-in-out;

		    	background-color: $white;
			    flex-direction: column;
	    		align-items: flex-start;
			    width: 100%;
			    margin: 0 auto;
			    // padding: 2em 5% 0 5%;
			    padding: 9em 5% 0 5%;
			    pointer-events: none;
			    // height: calc(100vh - 7em);
			    height: 100vh;
			    transform: translate3d(0,-100px,0);
			    .isIos & {
			    	// height: calc(100vh - 14em);
			    	// padding: 6em 5% 0 5%;
			    }
			    .isAndroid & {
			    	// height: calc(100vh - 14em);
			    	// height: 100vh;
			    	padding: 13em 5% 0 5%;
			    }
			    .isFacebook & {
			    	// height: calc(100vh - 14em);
			    	// top: 0;
			    	// height: 100vh;
			    	// margin-top: -20em;
			    	padding: 9em 5% 0 5%;
			    }
			    // .is-inverted & {
			    // 	background-color: $black2;
			    // }
			    &.is-open {
					transform: translate3d(0,0,0);
			    	opacity: 1;
			    	pointer-events: auto;

			    	.menu-item {
			    		transform: translate3d(0,0,0);
			    		opacity: 1;
			    		
			    		&:nth-child(1){
							transition: all 0.33s ease-in-out 0.33s;
			    		}
			    		&:nth-child(2){
							transition: all 0.33s ease-in-out 0.53s;
			    		}
			    		&:nth-child(3){
							transition: all 0.33s ease-in-out 0.73s;
			    		}
			    		&:nth-child(4){
							transition: all 0.33s ease-in-out 0.93s;
			    		}
			    	}
					// & + .burger {
					// 	transform: translate3d(0,0,0) scale(1) !important;
					// }
			    }
		    }
		    // .is-inverted & {
		    // 	background-color: $black2;
		    // 	@media all and (max-width: $bp1) {
		    // 		background-color: $black2;
		    // 	}
		    // }
	    	.menu-item {
	    		@include f-kontora-r-20;
	    		margin-right: 4em;
			    // padding-bottom: 18px;
			    padding-bottom: 22px;
	    		// &.is-active {
	    		// 	&:before {
	    		// 		--link-color:#000000;
	    		// 	}
	    		// 	@extend .link;
	    		// 	@extend .link-hover-line;
	    		// 	@extend .link-hover-line-inverted;
			    // 	padding-bottom: 19px;
	    		// }

    		    height: 100%;
			    display: flex;
			    align-items: flex-end;

			    @media all and (max-width: $bp1) {
					transform: translate3d(-100px,0,0);
		    		opacity: 0;
		    		transition: all 0.33s ease-in-out 0.25s;
		    		padding-bottom: 0;
	    			margin-top: 19px;
		    	}
				@media all and (max-width: $bp1) and (max-height: 510px) {
					font-size: 2.5em;
				}
	    		&:first-child {
	    			margin-top: 0;
	    		}
	    		&:last-of-type {
	    			margin-right: 0;
	    		}
	    		&.is-active {
	    			@media all and (max-width: $bp1) {
	    	// 			@extend .link;
	    	// 			@extend .link-hover-line;
	    	// 			@extend .link-hover-inverted;
						// @extend .link-hover-parent;
						display: inline-flex;
						overflow: hidden;
						&:before {
							content: "";
							width: 100%;
							// height: $hover-line-height;
							height: .085em;
						    bottom: 0;
						    left: 0;
						    position: absolute;
						    transition: all 0.33s ease-in-out;
						    transform: translate3d(0, 0, 0);
							// background-color: $black;
							// background-color: attr(data-color color);
							// background-color: var(--link-color);
							background-color: $black;
							.is-inverted & {
								background-color: $white;
							}
							
						}
						&:hover {
							&:before {
						    	transform: translate3d(-105%, 0, 0);

							}
						}
	    			}
	    		}
	    		.is-inverted & {
			    	color: $white;
			    }
	    		@media all and (max-width: $bp1) {
			    	font-size: 5em;
			    	height: auto;
			    	overflow: hidden;
			    	// display: inline-flex;
			    }
				@media all and (max-width: $bp1) and (max-height: 510px) {
					font-size: 2.5em;
				}
	    	}
	    }
	    .burger {
    	    align-self: center;
    	    // margin-bottom: 17px;
    	    display: none;
    	    @media all and (max-width: $bp1) {
    	    	display: block;
				// transform: translate3d(-20px, 0px, 0px) scale(1.2);
				transform: scale(1);
				transform-origin: right center;
				transition: transform 0.5s ease;
				will-change: auto;
	    	    // margin-bottom: 4px;
    	        // margin-bottom: 7.5px;
    	    }
	    }
	}
}

/*========================================
=            active menu line            =
========================================*/

.active-line-wrapper {
	position: absolute;
	height: auto;
	bottom: 0;
	width: 100%;
	backface-visibility: hidden;
	.active-line {
		width: 80px;
		height: $hover-line-height;
		position: absolute;
		background-color: $black;
		left: 0;
		width: 0;
		backface-visibility: hidden;
		// transition: all 0.25s ease-in-out;
		transition: all 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	}
}