// Modul Footer

$footer-bp1: 1024px;
.site-footer {
	// padding: 0 0 10em 0;
	// padding: 0 0 13em 0;

	// padding: 0 0 8.2em 0;

	padding: 0 0 11.5em 0;
	// z-index: -1;
	transition: all 0.33s ease-in-out;
	overflow: hidden;
	left: 0;
	@media all and (max-width: $blending-bp1) {
		padding: 0 0 14.2em 0;
    }
	&.is-inverted {
		background-color: $black2;
		color: $white;
		.divider {
			background-color: $white !important;
		}
	}
	.single-services &,
	.single-tools & {
		display: none;
	}
	&.is-hidden {
		opacity: 0 !important;
		display: none;
		visibility: hidden !important;
	}
	.inner-wrapper {
		display: flex;
		align-items: flex-start;

		// .isIE11 & {
			// height: 100%;
			// display: block;
		// }
		@media all and (max-width: $bp1) {
			flex-direction: column;
		}
		.isIE11 & {
			@media all and (max-width: $bp1) {
				flex-direction: row;
				flex-wrap: wrap;

				position: relative;
			}
		}
		.box {
		    display: flex;
		    flex-direction: column;
		    justify-content: center;
		    position: relative;
	        transition: all 0.25s ease-in-out;

			.info {
				@include f-kontora-b-60;
				position: absolute;
				top: 0;
				left: 0;
				pointer-events: auto;
				opacity: 0;
				pointer-events: none;
				transition: all 0.33s ease-in-out;
				@media all and (max-width: $footer-bp1) {
					font-size: 5em;
				}
				&.is-active {
					opacity: 1;
					pointer-events: auto;
				}
			}
			.info-headline {
				@include f-futura-m-40;
				line-height: 1.35em;
			    margin-bottom: 0.57em;
			    &.is-active {
			    	&:before {
		    	    	transform: translate3d(0, 0, 0);
			    	}
			    }
			    &:last-child {
			    	margin-bottom: 0;
			    	margin-top: 0.1em
			    }
			    @media all and (max-width: $footer-bp1) {
					font-size: 3em;
					&:last-child {
				    	margin-top: 0;
				    }
				}
			}
			.info-wrapper {
			    // display: inline-flex;
			    // flex-direction: row;
			    // flex-wrap: wrap;
			    // justify-content: flex-start;
			    // align-items: center;
			    display: inline;
				.headline {
					// @include f-futura-m-25;
					@include f-futura-b-25;
					display: inline-flex;
					.link {
						&:before {
							height: 0.15em;
						}
					}
				}
				.divider {
				    display: inline-block;
			        transform: translate(0, -0.6em);
				}
				.text {
					@include f-futura-m-25;
					display: inline;
					p {
						display: inline;
					}
				}
			}
			.nav-wrapper {
			    margin-top: 3.2em;
		        margin-bottom: 0.4em;
				.menu-item {
					@include f-kontora-r-20;
				    display: inline-block;
					&:last-child {
						margin-right: 0;
					}
					margin-right: 3em;
				    @media all and (max-width: $footer-bp1) {
						margin-right: 2em;
						&:last-child {
							margin-right: 0;
						}
					}
					@media all and (max-width: $bp1) {
						margin-top: 2em;
					}
				}
				@media all and (max-width: $bp1) {
					margin-top: 0;
				}
			}

			&-1 {
				// flex: 2;
				flex: 1;
				margin-right: 5%;
				.isIE11 & {
					@media all and (max-width: $bp1) {
						width: 100%;
						flex: 1 100%;
					}
				}
				@media all and (max-width: $bp1) {
					flex: unset;
					width: 100%;
					position: relative;
					margin-right: 0;
				}
			}
			&-2 {
				flex:1;
				align-items: flex-start;
				justify-content: flex-start;
				margin: 1em 2% 0 1em;
				.isIE11 & {
					@media all and (max-width: $bp1) {
						width: 100%;
						flex: 1 100%;
					}
				}
				@media all and (max-width: $bp1) {
					flex: unset;
				    // margin: 3.8em 0 0 0;
					margin: 4.8em 0 0 0;
					// margin: 0 0 0 0;
				    max-width: 50em;
				    width: 100%;
				    flex-direction: row;
				    justify-content: space-between;
				}
			}
			&-3 {
				flex: 2.5;
				// justify-content: space-between;
			    justify-content: flex-start;
			    padding: 1em 0;
		        margin-bottom: 0.7em;

	            justify-content: space-between;
			    align-items: stretch;
			    margin-bottom: 0;
			    padding: 1em 0 0 0;
			    .isIE11 & {
					@media all and (max-width: $bp1) {
						width: 100%;
						flex: 1 100%;
					}
				}
			    @media all and (max-width: $bp1) {
					flex: unset;
					width: 100%;
					margin-top: 2em;
					margin-bottom: 0;


				}
			}
			&-2-3 {
			    flex: 2;
			    display: flex;
			    flex-direction: row;
			    align-items: stretch;
			    .isIE11 & {
					@media all and (max-width: $bp1) {
						width: 100%;
						flex: 1 100%;
						flex-direction: row;
						flex-wrap: wrap;
					}
				}
			    @media all and (max-width: $bp1) {
					flex-direction: column;
				}
			}
		}
	}
}

.cookiemsg {
    position: fixed;
    bottom: 0;
    padding: 3em 0;
    z-index: 4;
    width: 100%;
    background-color: $white;
    transform: translate3d(0,105%,0);
    transition: all 0.33s ease-in-out;
    .inner-wrapper {
    	width: 90%;
    	margin: 0 auto;
    	display: flex;
    	align-items: center;
    }
    .isIos & {
    	// position: sticky;
    }
	.text {
		flex: 1;
		.text-inner {
			@include f-futura-m-20;
			display: inline;
			.link {
				display: inline-flex;
				&:before {
					background-color: $black;
				}
			}
		}
		.button_text {
			@include f-futura-m-20;
			display: inline-flex;
			&:before {
				background-color: $black;
			}
		}
	}
	.cross {
		margin-left: 2em;
	}
	&.is_visible {
		transform: translate3d(0,0,0);
	}
}