// Modul Element - TwoImages

.section-TwoImages {
	.inner-wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		// width: 90%;
		// max-width: 1195px;
		@media all and (max-width: $bp1) {
			// width: 90%;
		}
		.box {
			width: 47%;
			@media all and (max-width: $bp1) {
				width: 100%;
			}
			&-1 {
				margin-right: 2.5%;
				@media all and (max-width: $bp1) {
					margin-right: 0;
				}
			}
			&-2 {
				margin-left: 2.5%;
				@media all and (max-width: $bp1) {
					margin-left: 0;
					margin-top: 2.5em;
				}
			}

			.image-wrapper{
			    width: 100%;
			    padding-bottom: 51%;
			} 
		}
	}
}