// Modul Element - Text

.section-Text {
	.inner-wrapper {
		.text {
			@include f-futura-m-40;
			// line-height: 1.35em;
			// ul {
			// 	margin-top: 1em;
			// 	&:first-of-type {
			// 		margin-top: 0;
			// 	}
			// 	li {
			// 		padding-left: 1em;
			// 		position: relative;
			// 		&:before {
			// 			content: "";
			// 			width: 10px;
			// 			height: 10px;
			// 		    top: 0.5em;
			// 			border-radius: 100%;
			// 			background-color: $black;
			// 			position: absolute;
			// 			left: 0;
			// 		}
			// 	}
			// }
			// p {
			// 	margin-top: 1em;
			// 	&:first-of-type {
			// 		margin-top: 0;
			// 	}
			// }
		}
	}
}