// Modul Element - Team

.section-Team {
	.inner-wrapper {
		// width: 95%;
		width: 92.5%;
		// max-width: $max-width;
		max-width: 1980px;
		// max-width: 1205px;
		@media all and (max-width: $bp1) {
			width: 90%;
		}
		.team-grid {
			.grid-sizer {

			}
			.grid-sizer,
			.member-item {
				
				
				width: 30%;
				margin-right: 1.5%;
				margin-left: 1.5%;
			    position: relative;
    			display: inline-block;
				margin-bottom: 5.4em;


				@media all and (max-width: 1600px) {
					width: 47%;
					margin-right: 1.5%;
					margin-left: 1.5%;
					&:last-child {
						margin-bottom: 0;
					}
				}


    			@media all and (max-width: $bp1) {
    				width: 100%;
    				margin-right: 0;
    				margin-left: 0;
    				&:last-child {
    					margin-bottom: 0;
    				}
    			}
				.image-wrapper {
					width: 100%;
				}
				.name {
					@include f-kontora-b-25;
				    margin-top: 0.6em;
				}
			}
			.grid-sizer {
				// margin-right: 0 !important;
				// width: 50% !important;
			}
		}
	}
}