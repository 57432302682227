// WORK -- All about Work

.section-Work {
    margin-top: 5.5em;
	.inner-wrapper {
		width: 92.5%;
		// max-width: 1205px;
		max-width: 1980px;
		@media all and (max-width: $bp1) {
			width: 90%;
		}
		.project-grid {
			.grid-stamp-text {
				// width: 47%;
				width: 46%;
				height: auto;
			    margin-bottom: 5.5em;
			    margin-right: 1.5%;
				margin-left: 1.5%;
			    @media all and (max-width: $bp1) {
			    	width: 100%;
			    	margin-bottom: 2em;
			    	margin-right: 0;
					margin-left: 0;
			    }
				.headline {
					@include f-kontora-b-70;
				}
				.text {
					@include f-futura-m-40;
			    	margin-top: 1em;
					@media all and (max-width: $bp1) {
						margin-top: 0.3em;
					}
					.divider {
					    transform: translate(0,-8px);
					}
					.services-filter {
					    opacity: 0;
					    // max-height: 0;
					    // height: 0;
					    position: relative;
					    display: none;
					    transition: all 0.25s ease-in-out;
					    &.set-height {
					    	display: inline;
					    }
					    &.is-visible {
					    	opacity: 1;
					    }
					}
					.js_showServices {
						display: inline-flex;
						&.is_inline {
							display: inline;
							&:before {
								display: none;
							}
						}
					}
					.js_filterService {
						&.is-active {
							border-bottom: .1em solid #222222;
						}
					}
				}
			}
			.grid-sizer {

			}
			.grid-sizer,
			.work-item {
				width: 47%;
				// margin-bottom: 2.5em;
				margin-bottom: 1.2em;
				margin-top: 1.3em;
				margin-right: 1.5%;
				margin-left: 1.5%;
			    position: relative;
    			// display: inline-block;
    			display: block;
    			@media all and (max-width: $bp1) {
			    	width: 100%;
			    	margin-right: 0;
			    	margin-left: 0;
			    }
				.image-wrapper {
					width: 100%;
				}
			}
		}
	}
}

.section-single-work {
	.inner-wrapper {
		.headline {
			@include f-kontora-b-70;
		}
		.services {
		    display: flex;
		    justify-content: flex-start;
		    align-items: center;
		    margin-top: 3em;
		    .service-intro,
		    .service-wrapper {
				@include f-futura-m-20;
		    }
		    .service-wrapper {
				@media all and (max-width: $bp1) {
					width: 100%;
				}

				/* enable Flickity by default */
				&:after {
				  content: 'flickity';
				  display: none; /* hide :after */
				}

				@media screen and ( min-width: $bp1-min ) {
				  /* disable Flickity for large devices */
				  &:after {
				    content: '';
				  }
				}
				.slider-item {
					display: inline-flex;
					@media all and (max-width: $bp1) {
					    white-space: nowrap;
					    // margin-right: 0.5em;
					}
				}
		    }
		}
		.work-content {
			margin-top: 4.5em;
			@media all and (max-width: $bp1) {
				margin-top: 4em;
			}
		}
		.section-single-work-item {
			margin-top: 68px;
			&:first-child {
				margin-top: 0;
			}
			@media all and (max-width: $bp1) {
				margin-top: 30px;
			}
			&-img {
				// width: 100%;
				.image-wrapper{
				    width: 100%;
				    // padding-bottom: 38%;
				} 
			}
			&-text {
				@include f-futura-m-40;
				margin-top: 60px;
				@media all and (max-width: $bp1) {
					// margin-top: 70px;
					margin-top: 30px;
				}
				// width: 100%;
			} 
			&-text-small {
				@include f-futura-m-25;
				margin-top: 60px;
				@media all and (max-width: $bp1) {
					margin-top: 30px;
				}
				// width: 100%;
			} 
			&-twocolumns-text {
				// width: 100%;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin-top: 60px;
				@media all and (max-width: $bp1) {
					margin-top: 30px;
				}
				.headline {
					@include f-kontora-b-40;
					width: 100%;
				    // margin-bottom: 45px;
				    margin-bottom: 30px;
					h1,h2,h3,h4,h5 {
						margin-top: 0
					}
				    @media all and (max-width: $bp1) {
						margin-bottom: 0.5em;
					}
					// margin-bottom: 25px;
				}
				.box {
					margin-top: 0;
					@media all and (max-width: $bp1) {
						margin-top: 1em;
					}

				}
			}

			&.no-margin-top {
				margin-top: 0;
			}
			.box {
				@include f-futura-m-25;
				width: 47%;
				&-left {
					margin-right: 2.5%;
					&.css_column {
						margin-right: 0;
						width: 100%;
						column-count: 2;
						column-gap: 5%;
						@media all and (max-width: $bp1) {
							column-count:unset;
							column-gap: unset;
						}
					}
				}
				&-right {
					margin-left: 2.5%;
				}
				@media all and (max-width: $bp1) {
					width: 100%;
					&-left {
						margin-right: 0;
						margin-top: 0;
					}
					&-right {
						margin-left: 0;
						margin-top: 25px;
					}
				}
			}
			&-twocolumns-img {
				// width: 100%;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				.box {
				}
				.image-wrapper{
				    width: 100%;
				    padding-bottom: 51%;
				} 
			}
		}
		.section-single-work-marquee {
			@media all and (max-width: $bp1) {
				margin-top: 60px;
			}
			& + .section-single-work-item-text {
				margin-top: 60px
			}
		}
	}
}
.section-ThreeColumns-single {
	margin-top: 4em;
	.work-wrapper-row {
		a {
			.image-wrapper {
				img {
					position: absolute; 
					object-fit: cover; 
					height: 100%;
				}
			}
			video {
				position: absolute;
    			object-fit: cover;
			}
		}
	}
}